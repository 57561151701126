import { Footer } from "antd/es/layout/layout";
import React, { useState } from "react";
import { Content } from "antd/es/layout/layout";

import logo from "../Image/logo.png";
import {

  Button,
  Input,
  Row,
  Col,
  Card,
  Form,
  Checkbox,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

import "./style.css";
const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [newshowPassword, setNewShowPassword] = useState(false);
  const [confirmshowPassword, setConfirmShowPassword] = useState(false);
  const [form] = Form.useForm();
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { Text } = Typography;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setNewShowPassword(!newshowPassword);
  };
  const toggleConfPasswordVisibility = () => {
    setConfirmShowPassword(!confirmshowPassword);
  };

  return (
    <Content
      style={{
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          justifyContent: "center",
          minHeight: "100vh", // Set a minimum height for centering vertically
          padding: 20,
        }}
      >
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          <h1 style={{ color: "#3D49AB", textAlign: "center" }}>
            Change My Password
          </h1>
          <hr style={{ width: "70%" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <div style={{ width: "100%", maxWidth: "400px", padding: "20px" }}>
              <Card>
                <div
                  style={{
                    justifyContent: "center",
                    alignSelf: "center",
                    alignContent: "center",
                  }}
                >
                  <div className="form">
                  <Form
                    form={form}
                    onValuesChange={(changedValues, allValues) => {
                      // Handle changes in the form values
                      setEmail(allValues.email);
                    }}>
                    <label className="formlabel" style={{ fontSize: "16px" }}>
                      Email
                    </label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Email",
                        },
                      ]}
                    >
                      <Input className="forminput" placeholder="Email" />
                    </Form.Item>
                    {isError && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <Text style={{ color: "red" }}>{errMsg}</Text>
                      </div>
                    )}
                    <Button
                      className="signupbtn"
                    
                    >
                      ForgetPassword
                    </Button>
                  </Form>
                    {/* <Form
                      name="dynamic_form_item"
                      layout="vertical"
                      style={{ width: "100%" }}
                    >

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label htmlFor="name" style={{ fontWeight: "bold" }}>
                            Current Password
                          </label>
                          <div>
                            <Form.Item
                              name="firstname"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter Current Password",
                                },
                              ]}
                            >
                              <Input
                                className="forminput"
                                placeholder="Current Password"
                                type={showPassword ? "text" : "password"}
                                suffix={
                                  <Button
                                    type="text"
                                    onClick={togglePasswordVisibility}
                                    icon={
                                      showPassword ? (
                                        <EyeOutlined />
                                      ) : (
                                        <EyeInvisibleOutlined />
                                      )
                                    }
                                  />
                                }
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          paddingTop: 10,
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="password"
                            style={{ fontWeight: "bold" }}
                          >
                            New Password
                          </label>
                          <div style={{ flex: 1 }}>
                            <Form.Item
                              name="lastname"
                              rules={[
                                {
                                  required: true,
                                  message: "Create a new Password",
                                },
                              ]}
                            >
                              <Input
                                className="forminput"
                                placeholder="Current Password"
                                type={newshowPassword ? "text" : "password"}
                                suffix={
                                  <Button
                                    type="text"
                                    onClick={toggleNewPasswordVisibility}
                                    icon={
                                      newshowPassword ? (
                                        <EyeOutlined />
                                      ) : (
                                        <EyeInvisibleOutlined />
                                      )
                                    }
                                  />
                                }
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          paddingTop: 10,
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <label
                            htmlFor="password"
                            style={{ fontWeight: "bold" }}
                          >
                            Confirm New Password
                          </label>
                          <div style={{ flex: 1 }}>
                            <Form.Item
                              name="confirmpassword"
                              rules={[
                                {
                                  required: true,
                                  message: "Confirm New Password",
                                },
                              ]}
                            >
                              <Input
                                className="forminput"
                                placeholder="Current Password"
                                type={confirmshowPassword ? "text" : "password"}
                                suffix={
                                  <Button
                                    type="text"
                                    onClick={toggleConfPasswordVisibility}
                                    icon={
                                      confirmshowPassword ? (
                                        <EyeOutlined />
                                      ) : (
                                        <EyeInvisibleOutlined />
                                      )
                                    }
                                  />
                                }
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          className="blueButton"
                          style={{
                            width: "100%",
                            opacity: 1,
                            transition: "opacity 0.3s",
                            backgroundColor: "#3085C3",
                          }}
                          onMouseEnter={(e) => (e.target.style.opacity = 0.8)}
                          onMouseLeave={(e) => (e.target.style.opacity = 1)}
                        >
                          Update
                        </button>
                      </div>
                    </Form> */}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};
export default ChangePassword;
