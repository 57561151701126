import { Content } from "antd/es/layout/layout";
import React, { useState, useEffect } from "react";
import { SearchOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { Input, List, Skeleton, Button, Avatar } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import * as queries from "../graphql/queries";
import { getTableID } from "../Services/authServices";
import { useTranslation } from "react-i18next";

import "./style.css";
import { colors } from "../styles/colors";
const SelectRecipient = () => {
  const API = generateClient();
  const [recipients, setRecipients] = useState([]);
  const navigate = useNavigate();
  const [viewportWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOrganization, setIsOrganization] = useState(true); // Assuming isOrganization is a boolean state
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchRecipients = async () => {
      try {
        const MyQuery = `
          query GetRecipientsByUser($id: ID!) {
            getUser(id: $id) {
              Recipients {
                items {
                  addressState
                  email
                  firstName
                  lastName
                  id
                  phoneNumber
                  reasonSending
                  recipientDeliveryMethod
                  userID
                  addressStreet
                  addressCity              
                  isRecipientVerified
                  addressCountry
                  typeOfRecipient
                  addressZipCode
                }
              }
            }
          }
        `;
        const tableID = await getTableID(); // Make sure to handle this asynchronously

        if (!tableID) {
          console.error("Table ID is not available.");
          return;
        }
        const userData = await API.graphql({
          query: MyQuery,
          variables: { id: tableID },
        });
        let recipientList = userData.data.getUser.Recipients.items;

        // Sort recipients alphabetically by firstName, then by lastName
        recipientList.sort((a, b) => {
          const nameA = `${a.firstName || ""} ${
            a.lastName || ""
          }`.toLowerCase();
          const nameB = `${b.firstName || ""} ${
            b.lastName || ""
          }`.toLowerCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

        setRecipients(recipientList);
      } catch (err) {
        console.error("Error fetching recipients:", err);
      }
    };

    fetchRecipients();
  }, []);
  const handleEditRecipient = (recipient) => {
    // Use navigate to go to the edit page and pass the recipient data as state
    navigate("/editRecipient", { state: { recipient } });
  };
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredRecipients = recipients.filter(
    (recipient) =>
      recipient.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      recipient.lastName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    // <Content
    //   style={
    //     {
    //       // display: "flex",
    //       // justifyContent: "center",
    //       // alignItems: "center",
    //     }
    //   }
    // >
    //   <div>
    //     <div
    //       style={{ display: "flex", justifyContent: "space-between" }}
    //       className="recHeadingDiv"
    //     >
    //       <h1 style={{ color: "#3D49AB" }}>Select The Recipient</h1>
    //       <div style={{ display: "flex", gap: "2rem" }}>
    //         <Link to="/addRecipient">
    //           <Button
    //             icon={<PlusOutlined style={{ color: "#3D49AB" }} />}
    //             style={{
    //               marginTop: "2rem",
    //               width: "100%",
    //               color: "#3D49AB",
    //               border: "1px solid #3D49AB",
    //             }}
    //             size="large"
    //           >
    //             Add New Recipient
    //           </Button>
    //         </Link>

    //         <Input
    //           allowClear={true}
    //           style={{ marginTop: "2rem" }}
    //           prefix={<SearchOutlined style={{ color: "#3D49AB" }} />}
    //         />
    //       </div>
    //     </div>

    //     <div
    //       style={{
    //         height: "70vh",
    //         overflowY: "auto",
    //         marginTop: "2rem",
    //         width: "100%",
    //       }}
    //     >
    //       <List
    //         size="large"
    //         bordered={false}
    //         itemLayout="horizontal"
    //         dataSource={data}
    //         renderItem={(item, index) => (
    //           <Link to="/editRecipient">
    //             <div
    //               style={{}}
    //               onMouseEnter={(e) => {
    //                 e.currentTarget.style.backgroundColor = "#e6f2ff";
    //               }}
    //               onMouseLeave={(e) =>
    //                 (e.currentTarget.style.backgroundColor = "inherit")
    //               }
    //             >
    //               <List.Item>
    //                 <List.Item.Meta
    //                   avatar={
    //                     <Avatar
    //                       src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`}
    //                     />
    //                   }
    //                   title={<a href="https://ant.design">{item.title}</a>}
    //                   description="Ant Design, a design language for background applications, is refined by Ant UED Team"
    //                 />
    //               </List.Item>
    //             </div>
    //           </Link>
    //         )}
    //       />
    //     </div>
    //   </div>
    // </Content>
    <Content>
      <div className="recHeadingDiv" style={{ padding: "1rem" }}>
        <h1 style={{ color: colors.primary,fontFamily:'Gopher'}}>
          {t("dashboard.SelectTheRecipient")}
        </h1>
        <div
          className="recHeaderActions"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <div
            style={{
              marginBottom: "1rem",
              display: "flex",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <Link to="/addRecipient">
              <Button
                icon={<PlusOutlined style={{ color: colors.primary }} />}
                style={{
                  width: "auto",
                  color: "#00515c",
                  border: "1px solid #00515c",
                  fontFamily:'Gopher'
                }}
                size="large"
              >
                {t("dashboard.AddNewRecipient")}
              </Button>
            </Link>
            <Input
              allowClear={true}
              style={{ flex: 1 }}
              prefix={<SearchOutlined style={{ color: "#00515c" }} />}
              placeholder={t("dashboard.SearchRecipient")}
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>

        <div
          style={{
            height: "70vh",
            overflowY: "auto",
            marginTop: "2rem",
            width: "100%",
          }}
        >
          <List
            size="large"
            bordered={false}
            itemLayout="horizontal"
            dataSource={filteredRecipients}
            renderItem={(item, index) => (
              <div
                style={{
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                  marginBottom: "1rem",
                  padding: "5px",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = colors.secondary;
                }}
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "inherit")
                }
                onClick={() => handleEditRecipient(item)}
              >
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={`https://api.dicebear.com/7.x/initials/svg?seed=${item.firstName} ${item.lastName}`}
                        style={{ marginTop: "0.3rem" }}
                      />
                    }
                    title={
                      <a href="">
                        {item.firstName} {item.lastName}
                      </a>
                    }
                    description={`${item.addressCity}, ${item.addressState}`}
                  />
                </List.Item>
              </div>
            )}
            style={{
              backgroundColor: "white",
              overflow: "auto",
              padding: "1rem",
              width: "auto",
              borderRadius: "10px",
            }}
          />
        </div>
      </div>
    </Content>
  );
};
export default SelectRecipient;
