import React, { useEffect, useState } from "react";
import { Row, Col, Card, Select, Input, Space, Button } from "antd";
import { Typography } from "antd";
import "../style.css";
import { Link } from "react-router-dom";
import cad_flag from "../../Image/flag/cad_flag.png";
import xof_flag from "../../Image/flag/xof_flag.png";
import { generateClient } from "aws-amplify/api";
import { useTranslation } from "react-i18next";
const client = generateClient();
const { Title, Text } = Typography;
const { Option } = Select;

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const [currencyNow, setCurrencyNow] = useState("cad");
  const [currencyNow2, setCurrencyNow2] = useState("xof");
  const [cadToXOFRate, setcadToXOFRate] = useState(0.1);
  const [xOFToCADRate, setXOFToCadRate] = useState(0.1);
  const [sendRate, setSendRate] = useState(0.0);
  const [getRate, setGetRate] = useState(0.0);
  const [changeField1, setChangedField1] = useState(false);
  const [changeField2, setChangedField2] = useState(false);

  // currency list
  const currency_list = [
    {
      value: "cad",
      image: cad_flag,
      code: "CAD",
    },
    {
      value: "xof",
      image: xof_flag,
      code: "XOF",
    },
  ];

  useEffect(() => {
    fetchCurrentRate();
  }, []);

  // fetch rate
  async function fetchCurrentRate() {
    const query = `query MyQuery {
      listAdmins {
        items {
          finalCADToXOF
          finalXOFToCAD
        }
      }
    }
    `;
    const response = await client.graphql({
      query: query,
    });

    console.log("Response", response.data.listAdmins.items[0]);
    setcadToXOFRate(response.data.listAdmins.items[0].finalCADToXOF);
    setXOFToCadRate(response.data.listAdmins.items[0].finalXOFToCAD);

    console.log(
      "current rate",
      sendRate * cadToXOFRate,
      getRate,
      sendRate,
      cadToXOFRate
    );
  }

  const handleChange1 = (value) => {
    console.log(`selected ${value}`);
    setCurrencyNow(value);
    setCurrencyNow2(currency_list.find((item) => item.value !== value).value);
  };
  const handleChange2 = (value) => {
    console.log(`selected ${value}`);
    setCurrencyNow2(value);
    setCurrencyNow(currency_list.find((item) => item.value !== value).value);
  };

  return (
    <div style={{ padding: 20, backgroundColor: "#77B0AA" }}>
      <Row>
        <Col className="homePartFirstLeft" xs={{ span: 24 }} lg={{ span: 12 }}>
          <Title level={2} style={{ color: "white", fontFamily: "Gopher" }}>
            {t("calculator.title")}
          </Title>
          <Text>{/* {t("calculator.content")} */}</Text>
          <div style={{ marginTop: "3rem" }}>
            <Link to="signup">
              <Button
                shape="round"
                size="large"
                className="signup-btn"
                style={{ fontFamily: "Gopher" }}
              >
                {t("login.SignUp")}
              </Button>
            </Link>
          </div>
        </Col>
        <Col className="homePartFirstRight" xs={{ span: 24 }} lg={{ span: 12 }}>
          <Card className="converter-card">
            <div className="converter-title-container">
              <Text className="converter-title">
                {t("SendMoney.ExchangeRate")}
              </Text>
              <Text className="converter-rate">
                {currencyNow === "cad"
                  ? `1 CAD = ${cadToXOFRate.toFixed(2)} XOF`
                  : `1 XOF = ${xOFToCADRate} CAD `}
              </Text>
            </div>
            <div>
              <div className="converter-input-container">
                <Text strong className="converter-input-title">
                  {t("SendMoney.Yousendexactly")}
                </Text>
                <Space.Compact>
                  <Input
                    placeholder="0"
                    value={
                      currencyNow === "cad"
                        ? changeField2
                          ? new Intl.NumberFormat().format(
                              getRate / cadToXOFRate
                            )
                          : new Intl.NumberFormat().format(sendRate)
                        : changeField2
                        ? new Intl.NumberFormat().format(getRate / xOFToCADRate)
                        : new Intl.NumberFormat().format(sendRate)
                    }
                    style={{ borderRadius: "1rem 0rem 0rem 1rem" }}
                    onChange={(e) => {
                      const value = e.target.value.replace(/,/g, ""); // Remove commas for calculation
                      if (/^[0-9]*\.?[0-9]*$/.test(value)) {
                        setSendRate(value); // Keep this as a string to preserve input format (e.g., "1,000")
                        setChangedField1(true);
                        setChangedField2(false);
                        if (currencyNow === currencyNow2) {
                          setGetRate(value); // You might need to convert this back for calculations elsewhere
                        }
                      }
                    }}
                  />
                  <Select
                    className="select-border"
                    defaultValue="cad"
                    disabled={true}
                    onChange={handleChange1}
                    value={currencyNow}
                  >
                    {currency_list.map((item) => (
                      <Option value={item.value} key={item.value}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.2rem",
                          }}
                        >
                          <img
                            src={item.image}
                            width={30}
                            height={30}
                            alt={item.code}
                          />
                          <Text>{item.code}</Text>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Space.Compact>
              </div>
              <div className="converter-input-container">
                <Text strong className="converter-input-title">
                  {t("SendMoney.Recipientgets")}
                </Text>
                <Space.Compact>
                  <Input
                    placeholder="0"
                    value={
                      currencyNow === "cad"
                        ? changeField1
                          ? new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(sendRate * cadToXOFRate)
                          : new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(getRate)
                        : changeField1
                        ? new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(sendRate * xOFToCADRate)
                        : new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(getRate)
                    }
                    onChange={(e) => {
                      const value = e.target.value.replace(/,/g, ""); // Remove commas for calculation
                      if (/^[0-9]*\.?[0-9]*$/.test(value)) {
                        setGetRate(value); // Keep this as a string to preserve input format
                        setChangedField1(false);
                        setChangedField2(true);
                        if (currencyNow === currencyNow2) {
                          setSendRate(value); // You might need to convert this back for calculations elsewhere
                        }
                      }
                    }}
                    style={{ borderRadius: "1rem 0rem 0rem 1rem" }}
                  />
                  <Select
                    className="select-border"
                    defaultValue="xof"
                    disabled={true}
                    onChange={handleChange2}
                    value={currencyNow2}
                  >
                    {currency_list.map((item) => (
                      <Option value={item.value} key={item.value}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.2rem",
                          }}
                        >
                          <img
                            src={item.image}
                            width={30}
                            height={30}
                            alt={item.code}
                          />
                          <Text>{item.code}</Text>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Space.Compact>
              </div>
              <div className="converter-btn-container">
                <Link to="/signIn">
                  <Button size="large" shape="round" className="converter-btn">
                    {t("SendMoney.sendNow")}
                  </Button>
                </Link>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default HomePage;
