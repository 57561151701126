import { Content } from "antd/es/layout/layout";
import React, { useState } from "react";
import { Input, Radio, Row, Col, Form, Select, Button, Space } from "antd";
import "./style.css";
import FormItem from "antd/es/form/FormItem";
import { Link } from "react-router-dom";
const { Option } = Select;

const options = [
  { label: "Person", value: "Person" },
  { label: "Business", value: "Business" },
];

const MyFormItemContext = React.createContext([]);
function toArr(str) {
  return Array.isArray(str) ? str : [str];
}

const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select style={{ width: 100 }}>
      <Option value="+221">+221</Option>
      <Option value="+1">+1</Option>
    </Select>
  </Form.Item>
);

const InterectPaymentDetails = () => {
  const [selectOption, setSelectOption] = useState("");
  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <div className="recMainDiv" style={{ padding: "1rem" }}>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="recHeadingDiv"
        >
          <h1 style={{ color: "#3D49AB",  fontFamily: 'Gopher' }}>Recipient Account details</h1>
        </div>
        <hr style={{ marginTop: "-0.5rem", marginBottom: "1rem" }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Form layout="vertical">
            <div style={{ display: "flex", gap: "1.5rem", marginTop: "1rem" }}>
              <MyFormItem
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: "Please input First Name" }]}
              >
                <Input size="large" />
              </MyFormItem>
              <MyFormItem
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: "Please input Last Name" }]}
              >
                <Input size="large" />
              </MyFormItem>
            </div>

            <MyFormItem
              name="email"
              label="Recipient email address"
              rules={[
                { required: true, message: "Please input E-mail" },
                { type: "email", message: "The input is not valid E-mail" },
              ]}
            >
              <Input size="large" />
            </MyFormItem>

            <MyFormItem
              name="bankDetail"
              label="Transit Num"
              rules={[
                {
                  required: true,
                  message: "Please enter bank name",
                },
              ]}
            >
              <Input size="large" />
            </MyFormItem>
            <MyFormItem
              name="bankDetail"
              label="Recipient Account Number"
              rules={[
                {
                  required: true,
                  message: "Please enter recipient's account number",
                },
              ]}
            >
              <Input size="large" />
            </MyFormItem>
            <MyFormItem
              name="bankDetail"
              label="How will your recipient receive their money?"
              rules={[
                {
                  required: true,
                  message: "Please enter recipient's account number",
                },
              ]}
            >
              <Radio.Group name="radioGroup" defaultValue={"mobileMoney"}>
                <Radio value="mobileMoney" style={{fontFamily:'Gopher'}}>Online Banking</Radio>
                <Radio value="cash"  style={{fontFamily:'Gopher'}}>Cash</Radio>
              </Radio.Group>
            </MyFormItem>
            <Link to="/selectEditRecipient">
              <Button
                style={{
                  width: "100%",
                  color: "white",
                  backgroundColor: "#325FD5",
                  fontFamily:'Gopher'
                }}
                size="large"
              >
                <span style={{ fontSize: "18px" }}>Continue</span>
              </Button>
            </Link>
          </Form>
        </div>
      </div>
    </Content>
  );
};
export default InterectPaymentDetails;
