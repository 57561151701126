import React from "react";
import { Col, Row, Typography, Skeleton, Image } from "antd";
import { useTranslation } from "react-i18next";
import fastImage from "../../Image/fast.png";
import lock from "../../Image/lock.png";
import exchnage from "../../Image/exchange.png";
import customer from "../../Image/customer.png";
import easy from "../../Image/easy.png";
const { Text } = Typography;
const HomePagePart2 = () => {
  const { t, i18n } = useTranslation();
  const features = [
    {
      title: "homeP2.content1Title",
      description: "homeP2.content1",
      imageName: "fast",
    },
    {
      title: "homeP2.content2Title",
      description: "homeP2.content2",
      imageName: "lock",
    },
    {
      title: "homeP2.content3Title",
      description: "homeP2.content3",
      imageName: "exchnage",
    },
    {
      title: "homeP2.content4Title",
      description: "homeP2.content4",
      imageName: "easy",
    },
    {
      title: "homeP2.content5Title",
      description: "homeP2.content5",
      imageName: "customer",
    },
  ];
  const getImageSources = (imageName) => {
    const images = {
      fast: fastImage,
      lock: lock,
      exchnage: exchnage,

      easy: easy,
      customer: customer,
    };

    return images[imageName];
  };
  return (
    <div style={{ padding: "2rem 0rem", }}>
      <div
        style={{
          textAlign: "center",
          marginTop: "2rem",
          marginBottom: "3rem",
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <h2
          style={{
            fontWeight: "normal",
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
           fontFamily: 'Gopher'
          }}
        >
          {t("homeP2.title")}
        </h2>
        <h3
          style={{
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            fontFamily: "Gopher",
          }}
        >
          {t("homeP2.subtitle")}
        </h3>
      </div>

      <div>
        <Row
          style={{
            padding: "0rem 1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {features.map((feature) => (
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 4 }}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "2rem",
                fontFamily: "Gopher",
              }}
              key={feature.title} // Assuming each title is unique
            >
              <div style={{ width: "70%", textAlign: "center" }}>
                <Image
                  active
                  preview={false} // Disable preview on hover
                  width={"70px"}
                  src={getImageSources(feature.imageName)}
                />
                <h4>{t(feature.title)}</h4>
                <Text style={{fontFamily: "Gopher" }}>
                  {t(feature.description)}
                </Text>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
export default HomePagePart2;
