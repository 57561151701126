import React from "react";
import { Layout, theme, FloatButton } from "antd";
import HeaderComponent from "../Components/HeaderComponent";
import HomePagePart1 from "../Components/HomepageContent/HomePagePart1";
import HomePagePart2 from "../Components/HomepageContent/HomePagePart2";
import HomePagePart3 from "../Components/HomepageContent/HomePagePart3";
import FooterComponent from "../Components/FooterComponent";
import { MessageFilled } from "@ant-design/icons";

const { Content } = Layout;

const App = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      {/* <FloatButton
        shape="circle"
        icon={<MessageFilled style={{ color: "white" }} />}
      /> */}
      <HeaderComponent />
      <Content className="site-layout">
        <div
          style={{
            minHeight: 380,
            background: colorBgContainer,
          }}
        >
          <HomePagePart1 />
          <HomePagePart2 />
          {/* <HomePagePart3 /> */}
        </div>
      </Content>
      <FooterComponent />
    </Layout>
  );
};
export default App;
