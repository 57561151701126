import React, { useEffect, useState } from "react";
import {
  Divider,
  Button,
  Input,
  Space,
  Dropdown,
  DatePicker,
  Flex,
  Row,
  Col,
  Table,
  Tag,
  Modal,
} from "antd";
import { Content } from "antd/es/layout/layout";
import * as queries from "../graphql/queries";
import {
  SearchOutlined,
  FunnelPlotOutlined,
  DownOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { getTableID } from "../Services/authServices";
import { generateClient } from "aws-amplify/api";
import { useTranslation } from "react-i18next";

const Transactions = () => {
  const API = generateClient();
  const [txt_Search, setTxt_Search] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  // -- Filter
  // const filter_statuses = [
  //   { key: 0, label: "In Progress", value: "InProgress" },
  //   { key: 1, label: "Completed", value: "Completed" },
  //   { key: 2, label: "Cancelled", value: "Cancelled" },
  //   { key: 3, label: "Payment Issue", value: "PaymentIssue" },
  //   { key: 4, label: "Remittance Issue", value: "RemittanceIssue" },
  // ];
  // const handleFilter = (recipient, status) => {
  //   setSelectedRecipient(recipient);
  //   setSelectedStatus(status);
  // };
  function formatNumber(number, decimalPlaces = 3) {
    return number.toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  }
  useEffect(() => {
    const fetchTransactionHistory = async () => {
      try {
        const tableID = await getTableID(); // Your logic to get the table ID

        const MyQuery = `
          query GetTransactionsHistory($id: ID!) {
            getUser(id: $id) {
              Transactions(sortDirection: ASC) {
                items {
                  ReceivedAmountCAD
                  RecievedAmountMethod
                  SendingAmountCFA
                  recipientFirstName
                  recipientLastName
                  transactionStatus
                  transactionDate
                  trackingID
                  deliveryMethod
                  completionDate
                  ConversionRateCADtoCFA
                }
              }
            }
          }
        `;

        const userData = await API.graphql({
          query: MyQuery,
          variables: { id: tableID },
        });

        let transactionItems = userData.data.getUser.Transactions.items;

        // Sort transactions by the latest to the oldest date
        transactionItems.sort(
          (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
        );

        setTransactionData(transactionItems); // Update your state with the sorted transactions
      } catch (err) {
        console.error("Error fetching transaction history:", err);
      }
    };

    fetchTransactionHistory();
  }, []);
  // Implement the filtering logic
  useEffect(() => {
    if (!txt_Search.trim()) {
      setFilteredTransactions(transactionData); // No search term, show all transactions
    } else {
      const lowercasedSearchTerm = txt_Search.toLowerCase();
      const filtered = transactionData.filter((transaction) => {
        // Use a default empty string if the property might be null or undefined
        const firstName = transaction.recipientFirstName || "";
        const lastName = transaction.recipientLastName || "";

        return (
          firstName.toLowerCase().includes(lowercasedSearchTerm) ||
          lastName.toLowerCase().includes(lowercasedSearchTerm)
        );
      });
      setFilteredTransactions(filtered);
    }
  }, [txt_Search, transactionData]);

  const handleDetailsClick = (record) => {
    setSelectedTransaction(record); // Store the selected transaction
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const columns = [
    {
      title: t("Transcation.recipient"),
      key: "recipient",
      render: (_, record) =>
        `${record.recipientFirstName} ${record.recipientLastName}`,
    },
    {
      title: t("Transcation.txDate"),
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (text) =>
        text
          ? new Date(text).toLocaleDateString(undefined, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : "N/A",
    },
    {
      title: t("Transcation.completionDate"),
      dataIndex: "completionDate",
      key: "completionDate",
      render: (text) =>
        text
          ? new Date(text).toLocaleDateString(undefined, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : "N/A",
    },
    {
      title: t("Transcation.trackingID"),
      dataIndex: "trackingID",
      key: "trackingID",
    },
    {
      title: t("Transcation.amount"),
      dataIndex: "ReceivedAmountCAD",
      key: "ReceivedAmountCAD",
      render: (text) => `${formatNumber(Number(text), 2)} CAD`,
    },
    {
      title: t("Transcation.sendingAmount"),
      dataIndex: "SendingAmountCFA",
      key: "SendingAmountCFA",
      render: (text) => `${formatNumber(Number(text), 2)} XOF`,
    },
    {
      title: t("Transcation.paymentMethod"),
      dataIndex: "RecievedAmountMethod",
      key: "RecievedAmountMethod",
      render: (method) => {
        let displayText = "";
        switch (method) {
          case "wallet":
            displayText = t("SendMoney.Wallet"); // Assuming you have a translation key for "Wallet"
            break;
          case "interac":
            displayText = t("SendMoney.Interac"); // Assuming you have a translation key for "Interac"
            break;
          default:
            displayText = method; // Fallback to the raw method value if it's not one of the expected ones
        }
        return displayText;
      },
    },
    {
      title: t("Transcation.deliveryMethod"),
      dataIndex: "deliveryMethod",
      key: "deliveryMethod",
      render: (method) => {
        let displayText = "";
        switch (method) {
          case "cash":
            displayText = t("EditRecipient.CashPickup"); // Assuming you have a translation key for "Cash Pickup"
            break;
          case "mobileMoney":
            displayText = t("EditRecipient.OnlineBanking"); // Assuming you have a translation key for "Mobile Money"
            break;
          default:
            displayText = method; // Fallback to the raw method value if it's not one of the expected ones
        }
        return displayText;
      },
    },
    {
      title: t("Transcation.txStatus"),
      dataIndex: "transactionStatus",
      key: "transactionStatus",
      render: (status) => {
        let color = "default";
        let displayText = "";

        if (status) {
          switch (status.toLowerCase()) {
            case "completed":
              color = "green";
              displayText = t("Transcation.completed");
              break;
            case "pending":
              color = "gold";
              displayText = t("Transcation.Pending");
              break;
            case "failed":
              color = "red";
              displayText = t("Transcation.failed");
              break;
            case "rejected":
              color = "volcano";
              displayText = t("Transcation.Rejected");
              break;
            case "inprogress":
              color = "blue";
              displayText = t("Transcation.InProgress");
              break;
            default:
              displayText = status; // Fallback to the raw status value
          }
        } else {
          displayText = "NA"; // Use translated text for "N/A"
        }

        return <Tag color={color}>{displayText}</Tag>;
      },
    },
    // case t("Transcation.Completed"):
    //   color = "green";
    //   break;
    // case t("Transcation.Pending"):
    //   color = "gold";
    //   break;
    // case t("Transcation.failed"):
    //   color = "red";
    //   break;
    // case t("Transcation.Rejected"):
    //   color = "red";
    //   break;
    // default:
    //   color = "default";
    //   break;
    {
      title: t("Transcation.Details"),
      key: "details",
      render: (_, record) => (
        <Button onClick={() => handleDetailsClick(record)}>
          {t("Transcation.ViewDetails")}
        </Button>
      ),
    },
  ];
  // useEffect(() => {
  //   // This will run when the component mounts
  //   console.log("fetch Transaction Record");
  //   setTransactionData(data);
  //   setFilter_Recipients(
  //     [...new Set(data.map((transaction) => transaction.recipient))].map(
  //       (recipientName, index) => ({
  //         key: index,
  //         label: recipientName,
  //         value: recipientName,
  //       })
  //     )
  //   );
  //   setFilter_Status(
  //     [
  //       ...new Set(data.map((transaction) => transaction.transactionstatus)),
  //     ].map((transactionstatus, index) => ({
  //       key: index,
  //       label: transactionstatus,
  //       value: transactionstatus,
  //     }))
  //   );
  // }, []);
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      //setVisible(false);
    }, 3000);
  };

  const handleCancel = () => {
    // setVisible(false);
  };

  // const closeModal = () => {
  //   setModalVisible(false);
  // };
  // // Filter the transaction data based on the selected recipient
  // const filteredData = transactionData.filter((transaction) => {
  //   if (selectedRecipient && selectedStatus) {
  //     return (
  //       transaction.recipient === selectedRecipient &&
  //       transaction.status === selectedStatus
  //     );
  //   } else if (selectedRecipient) {
  //     return transaction.recipient === selectedRecipient;
  //   } else if (selectedStatus) {
  //     return transaction.status === selectedStatus;
  //   }
  //   return true;
  // });

  return (
    <Content>
      <Divider orientation="left">
      <span style={{ fontSize: "22px" ,fontFamily:'Gopher'}}>   {t("dashboard.YourTransactions")}({transactionData.length})</span>

        {/* {t("dashboard.YourTransactions")}({transactionData.length}) */}
      </Divider>
      <Space style={{ marginBottom: 16, justifyContent: "flex-end", width: "100%" }}>
        <Input
          value={txt_Search}
          onChange={(e) => setTxt_Search(e.target.value.toLowerCase())}
          placeholder={t("Transcation.search")}
          prefix={<SearchOutlined />}
        />
        {/* <Button
          onClick={() => setIsShowFilter(!isShowFilter)}
          type="primary"
          icon={<FunnelPlotOutlined />}
        >
          Filter
        </Button> */}
      </Space>
      {/* Add your filter UI here */}
      <div className="transactionTableContainer">
        <Table columns={columns} dataSource={filteredTransactions} />
      </div>
      <Modal
      style={{ fontSize: "17px", fontFamily:'Gopher' }}
        title={t("Transcation.TransactionDetails")}
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
      >
        <div style={{ width: "100%" }}>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <div style={{ fontSize: "17px", fontFamily:'Gopher' }}>{t("Transcation.trackingID")}</div>
            <div style={{ marginLeft: "10px" }}>
              {selectedTransaction ? selectedTransaction.trackingID : "N/A"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <div style={{ fontSize: "17px", fontFamily:'Gopher' }}>{t("Transcation.recipient")}</div>
            <div style={{ marginLeft: "10px" }}>
              {selectedTransaction
                ? `${selectedTransaction.recipientFirstName} ${selectedTransaction.recipientLastName}`
                : "N/A"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <div style={{ fontSize: "17px", fontFamily:'Gopher'}}>{t("Transcation.txStatus")}</div>
            <div style={{ marginLeft: "10px" }}>
              {selectedTransaction
                ? selectedTransaction.transactionStatus
                : "N/A"}
            </div>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <div style={{ fontSize: "17px", fontFamily:'Gopher' }}>{t("Transcation.txDate")}</div>
            <div style={{ marginLeft: "17px",fontFamily:'Gopher' }}>
              {selectedTransaction
                ? new Date(selectedTransaction.transactionDate).toLocaleString()
                : "N/A"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <div style={{ fontSize: "17px", fontFamily:'Gopher' }}>{t("Transcation.completionDate")}</div>
            <div style={{ marginLeft: "17px" ,fontFamily:'Gopher' }}>
              {selectedTransaction && selectedTransaction.completionDate
                ? new Date(selectedTransaction.completionDate).toLocaleString()
                : "Not Yet Completed"}
            </div>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <div style={{fontFamily:'Gopher', fontSize: "17px" }}>{t("Transcation.RecievedAmountMethod")}</div>
            <div style={{ marginLeft: "10px" }}>
              {selectedTransaction
                ? selectedTransaction.RecievedAmountMethod
                : "N/A"}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <div style={{fontFamily:'Gopher', fontSize: "17px" }}>{t("Transcation.deliveryMethod")}</div>
            <div style={{ marginLeft: "10px" }}>
              {selectedTransaction ? selectedTransaction.deliveryMethod : "N/A"}
            </div>
          </div>

          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <div style={{fontFamily:'Gopher', fontSize: "17px" }}>{t("SendMoney.Yousend")}</div>
            <div style={{ marginLeft: "10px" }}>
              {selectedTransaction
                ? `${formatNumber(selectedTransaction.ReceivedAmountCAD)} CAD`
                : "N/A"}
            </div>
          </div>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <div>Fees</div>
            <div style={{ marginLeft: "10px" }}>N/A</div>{" "}
          
          </div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <div style={{fontFamily:'Gopher', fontSize: "17px" }}>{t("SendMoney.ExchangeRate")}</div>
            <div style={{ontFamily:'Gopher', fontSize: "17px"}}>
              {selectedTransaction
                ? `${formatNumber(
                    Number(selectedTransaction.ConversionRateCADtoCFA),
                    2
                  )} CFA`
                : "N/A"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <div style={{ fontFamily:'Gopher', fontSize: "17px"}}>{t("SendMoney.Recipientgets")}</div>
            <div style={{ marginLeft: "10px" }}>
              {selectedTransaction
                ? `${formatNumber(
                    Number(selectedTransaction.SendingAmountCFA),
                    2
                  )} CFA`
                : "N/A"}
            </div>
          </div>

          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          ></div>
          {/* <div style={{ paddingLeft: 30 }}>
            <Button
              key="back"
              style={{ margin: 5 }}
              size="large"
              onClick={handleCancel}
            >
              Share with Receipt
            </Button>
            <Button
              style={{ margin: 5 }}
              key="submit"
              type="primary"
              size="large"
              loading={loading}
              onClick={handleOk}
            >
              Download Receipt
            </Button>
          </div> */}
        </div>
      </Modal>
    </Content>
  );
};

export default Transactions;

/* -------------------------------------------------- Filter Dropdown Button ----------------------------------------------------- */
const FilterBtn = ({ defaultMsg, items, onFilter }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const handleMenuClick = (e) => {
    setSelectedItem(e.key);
    e.key && onFilter(items[e.key].value);
  };
  const handleClear = () => {
    setSelectedItem(null);
    onFilter(null); // Pass null or any value to clear the filter
  };

  return (
    <Dropdown
      menu={{ onClick: handleMenuClick, items: items }}
      onClick={selectedItem && (() => handleMenuClick({ key: null }))}
    >
      <Button style={{ width: "100%", minWidth: 150 }}>
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          {selectedItem ? items[selectedItem].label : defaultMsg}
          {selectedItem ? (
            <Button
              type="text"
              onClick={(e) => {
                e.stopPropagation(); // Prevent dropdown from closing
                handleClear();
              }}
            >
              <CloseOutlined />
            </Button>
          ) : (
            <DownOutlined />
          )}
        </Space>
      </Button>
    </Dropdown>
  );
};
