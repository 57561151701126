/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContactForm = /* GraphQL */ `
  mutation CreateContactForm(
    $input: CreateContactFormInput!
    $condition: ModelContactFormConditionInput
  ) {
    createContactForm(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      message
      phone
      status
      adminRef
      adminNotes
      adminUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContactForm = /* GraphQL */ `
  mutation UpdateContactForm(
    $input: UpdateContactFormInput!
    $condition: ModelContactFormConditionInput
  ) {
    updateContactForm(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      message
      phone
      status
      adminRef
      adminNotes
      adminUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContactForm = /* GraphQL */ `
  mutation DeleteContactForm(
    $input: DeleteContactFormInput!
    $condition: ModelContactFormConditionInput
  ) {
    deleteContactForm(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      message
      phone
      status
      adminRef
      adminNotes
      adminUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComplianceComplains = /* GraphQL */ `
  mutation CreateComplianceComplains(
    $input: CreateComplianceComplainsInput!
    $condition: ModelComplianceComplainsConditionInput
  ) {
    createComplianceComplains(input: $input, condition: $condition) {
      id
      userEmail
      fullName
      name
      compCreatedDate
      compUpdatedDate
      dateOfBirth
      isReviewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateComplianceComplains = /* GraphQL */ `
  mutation UpdateComplianceComplains(
    $input: UpdateComplianceComplainsInput!
    $condition: ModelComplianceComplainsConditionInput
  ) {
    updateComplianceComplains(input: $input, condition: $condition) {
      id
      userEmail
      fullName
      name
      compCreatedDate
      compUpdatedDate
      dateOfBirth
      isReviewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteComplianceComplains = /* GraphQL */ `
  mutation DeleteComplianceComplains(
    $input: DeleteComplianceComplainsInput!
    $condition: ModelComplianceComplainsConditionInput
  ) {
    deleteComplianceComplains(input: $input, condition: $condition) {
      id
      userEmail
      fullName
      name
      compCreatedDate
      compUpdatedDate
      dateOfBirth
      isReviewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAddFundWallet = /* GraphQL */ `
  mutation CreateAddFundWallet(
    $input: CreateAddFundWalletInput!
    $condition: ModelAddFundWalletConditionInput
  ) {
    createAddFundWallet(input: $input, condition: $condition) {
      id
      paymentMethod
      amountCAD
      transactionID
      apayloReferenceNumber
      status
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      walletID
      Wallet {
        id
        availableBalance
        userID
        onHoldBalance
        createdAt
        updatedAt
        __typename
      }
      requestDate
      completionDate
      adminName
      reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAddFundWallet = /* GraphQL */ `
  mutation UpdateAddFundWallet(
    $input: UpdateAddFundWalletInput!
    $condition: ModelAddFundWalletConditionInput
  ) {
    updateAddFundWallet(input: $input, condition: $condition) {
      id
      paymentMethod
      amountCAD
      transactionID
      apayloReferenceNumber
      status
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      walletID
      Wallet {
        id
        availableBalance
        userID
        onHoldBalance
        createdAt
        updatedAt
        __typename
      }
      requestDate
      completionDate
      adminName
      reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAddFundWallet = /* GraphQL */ `
  mutation DeleteAddFundWallet(
    $input: DeleteAddFundWalletInput!
    $condition: ModelAddFundWalletConditionInput
  ) {
    deleteAddFundWallet(input: $input, condition: $condition) {
      id
      paymentMethod
      amountCAD
      transactionID
      apayloReferenceNumber
      status
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      walletID
      Wallet {
        id
        availableBalance
        userID
        onHoldBalance
        createdAt
        updatedAt
        __typename
      }
      requestDate
      completionDate
      adminName
      reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEFTBankAccount = /* GraphQL */ `
  mutation CreateEFTBankAccount(
    $input: CreateEFTBankAccountInput!
    $condition: ModelEFTBankAccountConditionInput
  ) {
    createEFTBankAccount(input: $input, condition: $condition) {
      id
      customerNumber
      customerName
      customerTypeCode
      email
      customerStatus
      customerAccountId
      accountName
      accountNumber
      transitNumber
      institutionNumber
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      Transactions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEFTBankAccount = /* GraphQL */ `
  mutation UpdateEFTBankAccount(
    $input: UpdateEFTBankAccountInput!
    $condition: ModelEFTBankAccountConditionInput
  ) {
    updateEFTBankAccount(input: $input, condition: $condition) {
      id
      customerNumber
      customerName
      customerTypeCode
      email
      customerStatus
      customerAccountId
      accountName
      accountNumber
      transitNumber
      institutionNumber
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      Transactions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEFTBankAccount = /* GraphQL */ `
  mutation DeleteEFTBankAccount(
    $input: DeleteEFTBankAccountInput!
    $condition: ModelEFTBankAccountConditionInput
  ) {
    deleteEFTBankAccount(input: $input, condition: $condition) {
      id
      customerNumber
      customerName
      customerTypeCode
      email
      customerStatus
      customerAccountId
      accountName
      accountNumber
      transitNumber
      institutionNumber
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      Transactions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRecipient = /* GraphQL */ `
  mutation CreateRecipient(
    $input: CreateRecipientInput!
    $condition: ModelRecipientConditionInput
  ) {
    createRecipient(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phoneNumber
      email
      recipientDeliveryMethod
      addressState
      addressCity
      addressStreet
      reasonSending
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      Transactions {
        nextToken
        __typename
      }
      isRecipientVerified
      addressCountry
      typeOfRecipient
      addressZipCode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRecipient = /* GraphQL */ `
  mutation UpdateRecipient(
    $input: UpdateRecipientInput!
    $condition: ModelRecipientConditionInput
  ) {
    updateRecipient(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phoneNumber
      email
      recipientDeliveryMethod
      addressState
      addressCity
      addressStreet
      reasonSending
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      Transactions {
        nextToken
        __typename
      }
      isRecipientVerified
      addressCountry
      typeOfRecipient
      addressZipCode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRecipient = /* GraphQL */ `
  mutation DeleteRecipient(
    $input: DeleteRecipientInput!
    $condition: ModelRecipientConditionInput
  ) {
    deleteRecipient(input: $input, condition: $condition) {
      id
      firstName
      lastName
      phoneNumber
      email
      recipientDeliveryMethod
      addressState
      addressCity
      addressStreet
      reasonSending
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      Transactions {
        nextToken
        __typename
      }
      isRecipientVerified
      addressCountry
      typeOfRecipient
      addressZipCode
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createKYBiComply = /* GraphQL */ `
  mutation CreateKYBiComply(
    $input: CreateKYBiComplyInput!
    $condition: ModelKYBiComplyConditionInput
  ) {
    createKYBiComply(input: $input, condition: $condition) {
      id
      entityId
      entityName
      entityApprovalStatus
      companyName
      registrationNumber
      jurisdictionOfIncorporation
      businessType
      documentStatus
      amlStatus
      reviewStatus
      verifiedByUserName
      verificationTimestamp
      contactEmail
      contactPhoneNumber
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateKYBiComply = /* GraphQL */ `
  mutation UpdateKYBiComply(
    $input: UpdateKYBiComplyInput!
    $condition: ModelKYBiComplyConditionInput
  ) {
    updateKYBiComply(input: $input, condition: $condition) {
      id
      entityId
      entityName
      entityApprovalStatus
      companyName
      registrationNumber
      jurisdictionOfIncorporation
      businessType
      documentStatus
      amlStatus
      reviewStatus
      verifiedByUserName
      verificationTimestamp
      contactEmail
      contactPhoneNumber
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteKYBiComply = /* GraphQL */ `
  mutation DeleteKYBiComply(
    $input: DeleteKYBiComplyInput!
    $condition: ModelKYBiComplyConditionInput
  ) {
    deleteKYBiComply(input: $input, condition: $condition) {
      id
      entityId
      entityName
      entityApprovalStatus
      companyName
      registrationNumber
      jurisdictionOfIncorporation
      businessType
      documentStatus
      amlStatus
      reviewStatus
      verifiedByUserName
      verificationTimestamp
      contactEmail
      contactPhoneNumber
      address
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createIcomplyVerificationLogs = /* GraphQL */ `
  mutation CreateIcomplyVerificationLogs(
    $input: CreateIcomplyVerificationLogsInput!
    $condition: ModelIcomplyVerificationLogsConditionInput
  ) {
    createIcomplyVerificationLogs(input: $input, condition: $condition) {
      id
      entityId
      entity
      entityApprovalStatus
      givenName
      middleName
      surname
      scannedDocumentType
      scanDocumentNumber
      phoneNubmber
      email
      approvedByuserName
      approvalTimestamp
      dateOfBirth
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIcomplyVerificationLogs = /* GraphQL */ `
  mutation UpdateIcomplyVerificationLogs(
    $input: UpdateIcomplyVerificationLogsInput!
    $condition: ModelIcomplyVerificationLogsConditionInput
  ) {
    updateIcomplyVerificationLogs(input: $input, condition: $condition) {
      id
      entityId
      entity
      entityApprovalStatus
      givenName
      middleName
      surname
      scannedDocumentType
      scanDocumentNumber
      phoneNubmber
      email
      approvedByuserName
      approvalTimestamp
      dateOfBirth
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIcomplyVerificationLogs = /* GraphQL */ `
  mutation DeleteIcomplyVerificationLogs(
    $input: DeleteIcomplyVerificationLogsInput!
    $condition: ModelIcomplyVerificationLogsConditionInput
  ) {
    deleteIcomplyVerificationLogs(input: $input, condition: $condition) {
      id
      entityId
      entity
      entityApprovalStatus
      givenName
      middleName
      surname
      scannedDocumentType
      scanDocumentNumber
      phoneNubmber
      email
      approvedByuserName
      approvalTimestamp
      dateOfBirth
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWallet = /* GraphQL */ `
  mutation CreateWallet(
    $input: CreateWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    createWallet(input: $input, condition: $condition) {
      id
      availableBalance
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      onHoldBalance
      AddFundWallets {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWallet = /* GraphQL */ `
  mutation UpdateWallet(
    $input: UpdateWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    updateWallet(input: $input, condition: $condition) {
      id
      availableBalance
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      onHoldBalance
      AddFundWallets {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWallet = /* GraphQL */ `
  mutation DeleteWallet(
    $input: DeleteWalletInput!
    $condition: ModelWalletConditionInput
  ) {
    deleteWallet(input: $input, condition: $condition) {
      id
      availableBalance
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      onHoldBalance
      AddFundWallets {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCashTransactionRecord = /* GraphQL */ `
  mutation CreateCashTransactionRecord(
    $input: CreateCashTransactionRecordInput!
    $condition: ModelCashTransactionRecordConditionInput
  ) {
    createCashTransactionRecord(input: $input, condition: $condition) {
      id
      adminId
      amount
      cashTransactionType
      walletID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCashTransactionRecord = /* GraphQL */ `
  mutation UpdateCashTransactionRecord(
    $input: UpdateCashTransactionRecordInput!
    $condition: ModelCashTransactionRecordConditionInput
  ) {
    updateCashTransactionRecord(input: $input, condition: $condition) {
      id
      adminId
      amount
      cashTransactionType
      walletID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCashTransactionRecord = /* GraphQL */ `
  mutation DeleteCashTransactionRecord(
    $input: DeleteCashTransactionRecordInput!
    $condition: ModelCashTransactionRecordConditionInput
  ) {
    deleteCashTransactionRecord(input: $input, condition: $condition) {
      id
      adminId
      amount
      cashTransactionType
      walletID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createIdentification = /* GraphQL */ `
  mutation CreateIdentification(
    $input: CreateIdentificationInput!
    $condition: ModelIdentificationConditionInput
  ) {
    createIdentification(input: $input, condition: $condition) {
      id
      varificationResult
      varificationTimeStamp
      userVerificationType
      documentLink
      idNumber
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIdentification = /* GraphQL */ `
  mutation UpdateIdentification(
    $input: UpdateIdentificationInput!
    $condition: ModelIdentificationConditionInput
  ) {
    updateIdentification(input: $input, condition: $condition) {
      id
      varificationResult
      varificationTimeStamp
      userVerificationType
      documentLink
      idNumber
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIdentification = /* GraphQL */ `
  mutation DeleteIdentification(
    $input: DeleteIdentificationInput!
    $condition: ModelIdentificationConditionInput
  ) {
    deleteIdentification(input: $input, condition: $condition) {
      id
      varificationResult
      varificationTimeStamp
      userVerificationType
      documentLink
      idNumber
      userID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      walletID
      ReceivedAmountCAD
      SendingAmountCFA
      ConversionRateCADtoCFA
      apayloTransactionID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      apayloReferenceNumber
      completionDate
      Recipient {
        id
        firstName
        lastName
        phoneNumber
        email
        recipientDeliveryMethod
        addressState
        addressCity
        addressStreet
        reasonSending
        userID
        isRecipientVerified
        addressCountry
        typeOfRecipient
        addressZipCode
        createdAt
        updatedAt
        __typename
      }
      transactionDate
      fees
      message
      RecievedAmountMethod
      deliveryMethod
      userID
      recipientID
      recipientFirstName
      recipientLastName
      apayloStatus
      orangeTransactionID
      orangeRequestID
      orangeReferenceID
      currencySender
      currencyRecipient
      trackingID
      eftbankaccountID
      EFTBankAccount {
        id
        customerNumber
        customerName
        customerTypeCode
        email
        customerStatus
        customerAccountId
        accountName
        accountNumber
        transitNumber
        institutionNumber
        userID
        createdAt
        updatedAt
        __typename
      }
      transactionStatus
      isRecipientVerified
      adminReferance
      adminNotes
      sourceOfFund
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      walletID
      ReceivedAmountCAD
      SendingAmountCFA
      ConversionRateCADtoCFA
      apayloTransactionID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      apayloReferenceNumber
      completionDate
      Recipient {
        id
        firstName
        lastName
        phoneNumber
        email
        recipientDeliveryMethod
        addressState
        addressCity
        addressStreet
        reasonSending
        userID
        isRecipientVerified
        addressCountry
        typeOfRecipient
        addressZipCode
        createdAt
        updatedAt
        __typename
      }
      transactionDate
      fees
      message
      RecievedAmountMethod
      deliveryMethod
      userID
      recipientID
      recipientFirstName
      recipientLastName
      apayloStatus
      orangeTransactionID
      orangeRequestID
      orangeReferenceID
      currencySender
      currencyRecipient
      trackingID
      eftbankaccountID
      EFTBankAccount {
        id
        customerNumber
        customerName
        customerTypeCode
        email
        customerStatus
        customerAccountId
        accountName
        accountNumber
        transitNumber
        institutionNumber
        userID
        createdAt
        updatedAt
        __typename
      }
      transactionStatus
      isRecipientVerified
      adminReferance
      adminNotes
      sourceOfFund
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      walletID
      ReceivedAmountCAD
      SendingAmountCFA
      ConversionRateCADtoCFA
      apayloTransactionID
      User {
        id
        firstName
        lastName
        dateOfBirth
        email
        phoneNumber
        street
        termsAndConditionsAccepted
        privacyPolicyAcknowledged
        picture
        emailVerified
        indentificationVerified
        userStatus
        preferredLanguage
        city
        province
        pincode
        name
        isOrganization
        identificationVerified
        rejectionReason
        isRejected
        remitanceLimit
        verificationStatus
        businessName
        businessReferenceNumber
        remainingTransferLimit
        adminLastUpdatedAt
        createdAt
        updatedAt
        __typename
      }
      apayloReferenceNumber
      completionDate
      Recipient {
        id
        firstName
        lastName
        phoneNumber
        email
        recipientDeliveryMethod
        addressState
        addressCity
        addressStreet
        reasonSending
        userID
        isRecipientVerified
        addressCountry
        typeOfRecipient
        addressZipCode
        createdAt
        updatedAt
        __typename
      }
      transactionDate
      fees
      message
      RecievedAmountMethod
      deliveryMethod
      userID
      recipientID
      recipientFirstName
      recipientLastName
      apayloStatus
      orangeTransactionID
      orangeRequestID
      orangeReferenceID
      currencySender
      currencyRecipient
      trackingID
      eftbankaccountID
      EFTBankAccount {
        id
        customerNumber
        customerName
        customerTypeCode
        email
        customerStatus
        customerAccountId
        accountName
        accountNumber
        transitNumber
        institutionNumber
        userID
        createdAt
        updatedAt
        __typename
      }
      transactionStatus
      isRecipientVerified
      adminReferance
      adminNotes
      sourceOfFund
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      id
      cADToXOFSpotRate
      xOFToCADSpotRate
      adminMarkUpOnCAD
      adminMarkUpOnXOF
      finalCADToXOF
      finalXOFToCAD
      monthlyLimit
      thresholdlimit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      id
      cADToXOFSpotRate
      xOFToCADSpotRate
      adminMarkUpOnCAD
      adminMarkUpOnXOF
      finalCADToXOF
      finalXOFToCAD
      monthlyLimit
      thresholdlimit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      cADToXOFSpotRate
      xOFToCADSpotRate
      adminMarkUpOnCAD
      adminMarkUpOnXOF
      finalCADToXOF
      finalXOFToCAD
      monthlyLimit
      thresholdlimit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      dateOfBirth
      email
      phoneNumber
      street
      termsAndConditionsAccepted
      privacyPolicyAcknowledged
      picture
      emailVerified
      indentificationVerified
      userStatus
      Transactions {
        nextToken
        __typename
      }
      Identifications {
        nextToken
        __typename
      }
      Wallets {
        nextToken
        __typename
      }
      CashTransactionRecords {
        nextToken
        __typename
      }
      Recipients {
        nextToken
        __typename
      }
      preferredLanguage
      EFTBankAccounts {
        nextToken
        __typename
      }
      AddFundWallets {
        nextToken
        __typename
      }
      city
      province
      pincode
      name
      isOrganization
      identificationVerified
      rejectionReason
      isRejected
      remitanceLimit
      verificationStatus
      businessName
      businessReferenceNumber
      remainingTransferLimit
      adminLastUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      dateOfBirth
      email
      phoneNumber
      street
      termsAndConditionsAccepted
      privacyPolicyAcknowledged
      picture
      emailVerified
      indentificationVerified
      userStatus
      Transactions {
        nextToken
        __typename
      }
      Identifications {
        nextToken
        __typename
      }
      Wallets {
        nextToken
        __typename
      }
      CashTransactionRecords {
        nextToken
        __typename
      }
      Recipients {
        nextToken
        __typename
      }
      preferredLanguage
      EFTBankAccounts {
        nextToken
        __typename
      }
      AddFundWallets {
        nextToken
        __typename
      }
      city
      province
      pincode
      name
      isOrganization
      identificationVerified
      rejectionReason
      isRejected
      remitanceLimit
      verificationStatus
      businessName
      businessReferenceNumber
      remainingTransferLimit
      adminLastUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      dateOfBirth
      email
      phoneNumber
      street
      termsAndConditionsAccepted
      privacyPolicyAcknowledged
      picture
      emailVerified
      indentificationVerified
      userStatus
      Transactions {
        nextToken
        __typename
      }
      Identifications {
        nextToken
        __typename
      }
      Wallets {
        nextToken
        __typename
      }
      CashTransactionRecords {
        nextToken
        __typename
      }
      Recipients {
        nextToken
        __typename
      }
      preferredLanguage
      EFTBankAccounts {
        nextToken
        __typename
      }
      AddFundWallets {
        nextToken
        __typename
      }
      city
      province
      pincode
      name
      isOrganization
      identificationVerified
      rejectionReason
      isRejected
      remitanceLimit
      verificationStatus
      businessName
      businessReferenceNumber
      remainingTransferLimit
      adminLastUpdatedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
