import React, { useState, useEffect } from "react";
import { Content } from "antd/es/layout/layout";

import {
  Card,
  Radio,
  Modal,
  Form,
  Input,
  Typography,
  Flex,
  Button,
  message,
} from "antd";
import { CopyOutlined, WalletOutlined } from "@ant-design/icons";
import {
  getTableID,
  getUserVerificationStatus,
} from "../Services/authServices";
import "./style.css";
import { generateClient } from "aws-amplify/api";
import { useTranslation } from "react-i18next";
import InteracLogo from "../Image/Interac-Logo.wine.svg";

import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../styles/colors";
const PaymentMethod = () => {
  const { t } = useTranslation();
  const API = generateClient();
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  console.log("location on payment method", location);
  const navigate = useNavigate();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [radioError, setRadioError] = useState("");
  const [radioErrors, setRadioErrors] = useState(false);

  const [selectTime, setSelectTime] = useState();
  const [seconds, setSeconds] = useState(location.state?.selectTime || 300);
  const [isActive, setIsActive] = useState(false);

  const { Title, Text } = Typography;
  const [iscoming, IsComing] = useState(true); // Convert minutes to seconds

  const [wallet, setWallet] = useState({ availableBalance: 0 });
  const [error, setError] = useState("");
  const handleCardSelect = (value) => {
    setSelectedPaymentMethod(value);
    setRadioErrors(false);
    setRadioError("");
  };
  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const MyQuery = `
          query GetWalletByUser($id: ID!) {
            getUser(id: $id) {
              Wallets {
                items {
                  availableBalance
                  id
                  userID
                }
              }
            }
          }
        `;
        const tableID = await getTableID();

        if (!tableID) {
          console.error("Table ID is not available.");
          return;
        }

        const userData = await API.graphql({
          query: MyQuery,
          variables: { id: tableID },
        });

        const walletDetails = userData.data.getUser.Wallets.items[0] || {
          availableBalance: 0,
        };
        setWallet(walletDetails);
      } catch (err) {
        console.error("Error fetching wallet:", err);
        setError("Failed to fetch wallet details.");
      }
    };

    fetchWallet();
  }, []);

  useEffect(() => {
    if (location.state.iscoming === true) {
      IsComing(true);
    }
    setSelectedPaymentMethod(location.state.selectedPaymentMethod);
  }, [location.state]);

  // timer

  const formattedTime = () => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    let interval: any;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      setSelectTime(seconds);
    } else if (seconds === 0) {
      message.info("Timer reached 0 seconds!");
      navigate("/dashboard");
      setIsActive(false);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  const handleCardClick = (value) => {
    setSelectedPaymentMethod(value);
    setRadioErrors(false);
    setRadioError("");
  };
  const startTimer = () => {
    setIsActive(true);
  };
  useEffect(() => {
    startTimer(); // Start the timer when the component mounts
  }, [formattedTime]);

  // const handleContinue = () => {
  //   if (selectedPaymentMethod !== null && selectedPaymentMethod !== undefined) {
  //     // if(iscoming){
  //     const nextState = {
  //       ...location.state, // Spread the state from the previous page
  //       selectedPaymentMethod, // Add the selectedPaymentMethod
  //       selectTime,
  //     };
  //     setRadioErrors(false);
  //     setRadioError("");

  //     navigate("/overview", { state: nextState });

  //     // }else{
  //     //   const nextState = {
  //     //     ...location.state, // Spread the state from the previous page
  //     //     selectedPaymentMethod, // Add the selectedPaymentMethod
  //     //     selectTime,
  //     //   };
  //     //   setRadioErrors(false)
  //     //   setRadioError("");

  //     //   navigate("/overview", { state: nextState });
  //     // }
  //   } else {
  //     setRadioErrors(true);

  //     setRadioError("Please select a payment method.");
  //     // message.error("Please select a payment method."); // Optionally show a message using Ant Design message

  //     console.error("Please select a payment method.");
  //   }
  // };
  const handleContinue = () => {
    if (!selectedPaymentMethod) {
      setError("Please select a payment method.");
      return;
    }

    if (
      selectedPaymentMethod === "wallet" &&
      wallet.availableBalance < location.state.formattedSendRate
    ) {
      setError("Insufficient wallet balance to proceed.");
      return;
    }
    const nextState = {
      ...location.state, // Spread the state from the previous page
      selectedPaymentMethod, // Add the selectedPaymentMethod
      selectTime,
    };
    navigate("/overview", { state: nextState });
  };

  const cardsData = [
    {
      title: "Wallet",
      totalFees: "$0.00 CAD",
      // processingTime: "Your payment will be processed within 15 minutes",
      value: "wallet",
      logo: (
        <WalletOutlined
          style={{
            fontSize: "60px",
            color: "#1890ff",
            padding: "0.7rem",
          }}
        />
      ),
    },
    {
      title: "Interac",

      totalFees: "$0.00 CAD",
      // processingTime: "Your payment will be processed within 15 minutes",
      value: "interac",
      logo: (
        <img
          src={InteracLogo}
          alt="Interac Logo"
          style={{ width: "80px", height: "80px" }}
        />
      ),
    },
  ];

  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center", // Centers horizontally
        alignItems: "center", // Centers vertically
        height: "60vh", // Use the full height of the viewport
        backgroundColor: "white",
      }}
    >
      <div style={{ padding: "1rem", maxWidth: "800px", width: "100%" }}>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="recHeadingDiv"
        >
          <h1 style={{ color: colors.primary,fontFamily:'Gopher' }}>{t("SendMoney.Payment_Method")}</h1>
        </div>
        <hr />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "2rem",
            paddingLeft: 10,
            gap: "1rem",
            alignItems: "stretch",
            justifyContent: "space-between",
          }}
        >
          {cardsData.map((card, index) => (
            <div
              key={index}
              onClick={() => handleCardClick(card.value)}
              className={`card-container-paymentmethod ${
                selectedPaymentMethod === card.value ? "selected" : ""
              }`}
            >
              <Card>
                <div className="card-content">
                  <div>
                    <h3 style={{fontFamily:'Gopher'}}>{card.title}</h3>
                    <p style={{fontFamily:'Gopher'}}>Total fees - {card.totalFees}</p>

                    <p style={{fontFamily:'Gopher'}}>{card.processingTime}</p>
                  </div>
                  {card.logo && (
                    <div className="logo-container">{card.logo}</div>
                  )}
                </div>
              </Card>
            </div>
          ))}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            marginTop: "1rem", // Add some margin at the top if needed
          }}
        >
          <Text strong className="converter-input-title">
            Time Left {formattedTime()}
          </Text>
        </div>
        {radioErrors && (
          <div style={{ color: "red", marginTop: "0.5rem" }}>{radioError}</div>
        )}
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          {error && <Text style={{ color: "red" }}>{error}</Text>}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleContinue}
            style={{
              marginTop: "1rem",
              width: "60%",
              color: "white",
              backgroundColor: colors.primary,
            }}
            size="large"
          >
            <span style={{ fontSize: "18px",fontFamily:'Gopher' }}>
              {t("EditRecipient.Continue")}
            </span>
          </Button>
        </div>

        <Modal
          open={openModal}
          onOk={() => setOpenModal(false)}
          title={
            <span style={{ fontSize: "20px" }}>E-Transfer Instruction</span>
          }
          style={{ fontSize: "20px" }}
        >
          <h3 style={{fontFamily:'Gopher'}}>Step 1</h3>
          <p style={{fontFamily:'Gopher'}}>
            Log in to your online banking and send a new interac e-Transfer
            using the recipient data is displayed below.
          </p>
          {/* <Form layout="verticle">
            <Form.Item label="Recipient name">
              <Input placeholder="RemitClick" />
            </Form.Item>
            <Form.Item label="Recipient Email">
              <Input placeholder="auto@remitclick.com" />
            </Form.Item>
          </Form> */}

          <Flex vertical gap={16}>
            <div>
              <Typography.Title style={{ fontSize: "14px", fontWeight: "400" }}>
                Recipient name
              </Typography.Title>
              <Input defaultValue="RemitClick" suffix={<CopyOutlined />} />
            </div>
            <div>
              <Typography.Title style={{ fontSize: "14px", fontWeight: "400" }}>
                Recipient address
              </Typography.Title>
              <Input
                defaultValue="auto@remitclick.com"
                suffix={<CopyOutlined />}
              />
            </div>
          </Flex>

          <h3 style={{fontFamily:'Gopher'}}>Step 2 (IMPORTANT)</h3>
          <p>
            In the "message" field, you must enter your email address associated
            with Remitbee account. Please note that it's very important to enter
            your email in the "message" field to prevent delays.
          </p>
          {/* <Form layout="verticle">
            <Form.Item label="Message to Recipient">
              <Input placeholder="Enter your email address" />
            </Form.Item>
          </Form> */}
          <Flex vertical gap={16}>
            <div>
              <Typography.Title style={{ fontSize: "14px", fontWeight: "400" }}>
                Message to Recipient (Your email address)
              </Typography.Title>
              <Input defaultValue="yourEmail@gmail.com" />
            </div>
          </Flex>
        </Modal>
      </div>
    </Content>
  );
};

export default PaymentMethod;
