import { Content } from "antd/es/layout/layout";
import React, { useState, useEffect } from "react";
import { Input, Radio, Row, Col, Form, Select, Button, Space } from "antd";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import * as mutations from "../graphql/mutations";
import { getTableID } from "../Services/authServices";
import { useTranslation } from "react-i18next";
import { CookiesProvider, useCookies } from "react-cookie";
import { colors } from "../styles/colors";
const { Option } = Select;

const options = [
  { label: "Person", value: "Person" },
  { label: "Business", value: "Business" },
];

const MyFormItemContext = React.createContext([]);
function toArr(str) {
  return Array.isArray(str) ? str : [str];
}

const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item  style={{fontFamily:'Gopher'}} name={concatName} {...props} />;
};

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select style={{ width: 100 }}>
      <Option value="+221">+221</Option>
    </Select>
  </Form.Item>
);

const AddRecipient = () => {
  const { t, i18n } = useTranslation();

  const [form] = Form.useForm();
  const navigate = useNavigate(); // Use the useNavigate hook for navigation
  const API = generateClient();
  const onFinish = async (values) => {
    const tableID = await getTableID();
    if (!tableID) {
      console.error("Table ID is not available.");
      return;
    }
    const phoneNumberWithPrefix = `${values.prefix}${values.phoneNumber.replace(
      /\s+/g,
      ""
    )}`;

    const input = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: phoneNumberWithPrefix,
      email: values.email,
      recipientDeliveryMethod: values.bankDetail,
      addressState: values.state,
      addressCity: values.city,
      addressStreet: values.address,
      userID: tableID,
      addressCountry: values.country,
      addressZipCode: values.addressZipCode,
      typeOfRecipient: values.typeofRecipient,
      isRecipientVerified: false,
    };
    console.log("input", input);
    try {
      const createRecipient = await API.graphql({
        query: mutations.createRecipient,
        variables: { input },
      });
      console.log("Recipient created:", createRecipient);
      navigate("/selectRecipient"); // Navigate upon successful creation
    } catch (err) {
      console.error("Error creating recipient:", err);
    }
  };
  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <div className="recMainDiv" style={{ padding: "1rem" }}>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="recHeadingDiv"
        >
          <h1 style={{ color: colors.primary  ,fontFamily:'Gopher'}}>
            {t("SendMoney.Recipient_details")}
          </h1>
        </div>
        <hr style={{ marginTop: "-0.5rem", marginBottom: "1rem" }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            initialValues={{
              prefix: "+221", // Default prefix
              typeofRecipient: "Personal", // Default type of recipient
              bankDetail: "mobileMoney",
            }}
          >
            <MyFormItem
              name="typeofRecipient"
              label={t("EditRecipient.TypeofRecipient")}
              style={{ marginTop: "1rem",fontFamily:'Gopher' }}
            >
              <Radio.Group name="radioGroup" defaultValue={"Personal"}>
                <Radio value="Personal">
                  <span>{t("EditRecipient.Person")}</span>
                </Radio>
                {/* <Radio value="cash">Business</Radio> */}
                <Radio value="Business">
                  <span>{t("EditRecipient.Business")}</span>
                </Radio>
              </Radio.Group>
            </MyFormItem>

            <div style={{ display: "flex", gap: "1.5rem", marginTop: "1rem" }}>
              <MyFormItem
              style={{fontFamily:'Gopher'}}
                name="firstName"
                label={t("EditRecipient.FirstName")}
                rules={[{ required: true, message: "Please input First Name" }]}
              >
                <Input size="large" />
              </MyFormItem>
              <MyFormItem
               style={{fontFamily:'Gopher'}}
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: "Please input Last Name" }]}
              >
                <Input size="large" />
              </MyFormItem>
            </div>
            <MyFormItem
            style={{fontFamily:'Gopher'}}
              name="phoneNumber"
              label={t("EditRecipient.PhoneNumber")}
              rules={[
                { required: true, message: "Please Enter the Phone number" },
                {
                  pattern: new RegExp(
                    /^(\+221)?[ -]?[7-8][0-9]{2}[ -]?[0-9]{3}[ -]?[0-9]{3}$/
                  ),
                  message: "Please enter a valid Senegal phone number",
                },
              ]}
            >
              {/* <Input size="large" /> */}
              <Input addonBefore={prefixSelector} size="large" />
            </MyFormItem>
            <MyFormItem
              name="email"
              label={t("EditRecipient.email")}
              rules={[
                { required: true, message: "Please input E-mail" },
                { type: "email", message: "The input is not valid E-mail" },
              ]}
            >
              <Input size="large" />
            </MyFormItem>
            <MyFormItem
              name="address"
              label={t("EditRecipient.address")}
              rules={[{ required: true, message: "Please input address" }]}
            >
              <Input size="large" />
            </MyFormItem>
            <div style={{ display: "flex", gap: "1.5rem" }}>
              <MyFormItem
                name="city"
                label={t("EditRecipient.City")}
                rules={[{ required: true, message: "Please input city" }]}
              >
                <Input size="large" />
              </MyFormItem>
              <MyFormItem
                name="state"
                label={t("EditRecipient.State")}
                rules={[{ required: true, message: "Please input state" }]}
              >
                <Input size="large" />
              </MyFormItem>
            </div>
            <div style={{ display: "flex", gap: "1.5rem" }}>
              <MyFormItem
                name="addressZipCode"
                label={t("EditRecipient.ZipCode")}
                rules={[{ required: true, message: "Please input Zip Code" }]}
              >
                <Input size="large" />
              </MyFormItem>
              <MyFormItem
                name="country"
                label={t("EditRecipient.Country")}
                rules={[{ required: true, message: "Please input country" }]}
              >
                <Input size="large" />
              </MyFormItem>
            </div>

            <MyFormItem
              name="bankDetail"
              label={t("EditRecipient.Howsend")}
              rules={[
                {
                  required: true,
                  message: "Please Select how recipient receive their money ",
                },
              ]}
            >
              <Radio.Group name="radioGroup">
                <Radio value="mobileMoney">
                  {t("EditRecipient.OnlineBanking")}
                </Radio>
                <Radio value="cash">{t("EditRecipient.CashPickup")}</Radio>
              </Radio.Group>
            </MyFormItem>
            <Form.Item>
              <Button
                className="signupbtn"
                type="primary"
                htmlType="submit"
                style={{
                  width: "100%",
                }}
              >
                <span style={{ fontSize: "18px" }}>
                  {t("EditRecipient.Continue")}
                </span>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Content>
  );
};
export default AddRecipient;
