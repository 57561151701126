import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  InputNumber,
  Form,
  Row,
  Col,
  Select,
  Space,
  Radio,
  Checkbox,
  Typography,
  Modal
} from "antd";
import { Link } from "react-router-dom";
import "./index.css";
import RemitClickInfo from "../Components/RemitClickInfo";
import Footerpage from "./Footerpage";
import { CloseOutlined } from "@ant-design/icons";
import { signUpUser } from "../Services/authServices";
import { confirmSignUp } from "aws-amplify/auth";
import styles from "./style.css";
import {
  signUp,
  signIn,
  fetchUserAttributes,
  getCurrentUser,
  updateUserAttributes,
  updateUserAttribute,
  signOut,
  currentAuthenticatedUser,
} from "@aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import * as mutations from "../graphql/mutations";
import { useNavigate } from "react-router-dom";
import logo from "../Image/logo.png";
import { useTranslation } from "react-i18next";

const SignUp = ({ onLoginSuccess }) => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const isMobile = viewportWidth < 768;
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [isOtpStage, setIsOtpStage] = useState(false);
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [otpError, setOtpError] = useState("");
  const API = generateClient();
  const [hasAgreed, setHasAgreed] = useState(false);
  const [countryCode, setCountryCode] = useState("+1");
  const [tableID, setTableID] = useState("");
  const [phoneNumber, setPhonenUmber] = useState("");
  const [canResend, setCanResend] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const showModal = (content) => {
    setModalContent(content);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setModalContent('');
  };
  async function updateCustomAttribute(newAttributeValue) {
    try {
      // Assuming `getCurrentUser` returns a user object similar to what AWS Amplify Auth.currentAuthenticatedUser() would return
      const user = await getCurrentUser();

      // Construct the input object expected by `updateUserAttribute'
      const input = {
        userAttribute: {
          attributeKey: "custom:TableID", // The key of the attribute you want to update
          value: newAttributeValue, // The new value for the custom attribute
        },
        options: {},
      };

      // Call `updateUserAttribute` with the constructed input
      const result = await updateUserAttribute(input);
      console.log("Attribute update result: ", result);
    } catch (error) {
      console.error("Error updating user attributes:", error);
    }
  }

  // signup function is here
  async function handleSignUp(values) {
    console.log(values);
    const fullPhoneNumber = `${countryCode}${values.phone_number.replace(
      /\D/g,
      ""
    )}`;
    setPhonenUmber(fullPhoneNumber);
    if (form.getFieldValue("accountType") === "business") {
      setIsBusiness(true);
    } else {
      setIsBusiness(false);
    }

    try {
      // Await the signUpUser call and capture the signUpResponse
      const signUpResponse = await signUpUser({
        username: values.email, // Assuming username is the email
        password: values.password,
        email: values.email,
        phone_number: fullPhoneNumber,
        autoSignIn: { enabled: false },
      });
      setUsername(values.email);
      setIsOtpStage(true);
    } catch (error) {
      setIsError(true);
      const message = error.toString().split(":").pop().trim();
      setErrMsg(message);
      console.log("Error", error);
    }
  }
  // const response = signOut();
  // console.log("signout response ", response);
  useEffect(() => {
    const handleWindowResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleOtpSubmit = async (values) => {
    const { otp } = values;
    try {
      const response = await confirmSignUp({
        username,
        confirmationCode: otp,
      });

      await signIn({
        username, // Assuming this is the email or username used for sign-up
        password: form.getFieldValue("password"), // You might need to manage password differently
      });
      //console.log(values);

      // Use userId for the id field in the input
      const input = {
        name: form.getFieldValue("name"),
        email: form.getFieldValue("email"),
        phoneNumber: phoneNumber,
        termsAndConditionsAccepted: true,
        privacyPolicyAcknowledged: true,
        identificationVerified: false,
        isOrganization: isBusiness,
        userStatus: true,
        isRejected: false,
        emailVerified: true,
      };
      // Call the createUserModel mutation with the input
      const createUser = await API.graphql({
        query: mutations.createUser,
        variables: { input },
      });
      setTableID(createUser.data.createUser.id);
      // Update the custom attribute before navigating to the dashboard
      await updateCustomAttribute(createUser.data.createUser.id);
      // Navigate to the Dashboard page
      onLoginSuccess();
      navigate("/dashboard");
    } catch (error) {
      console.error("Error verifying OTP", error);
      setIsError(true);
      const message = error.toString().split(":").pop().trim();
      console.log(message);
      setErrMsg(message);
      // setOtpError("Failed to verify OTP. Please try again.");
    }
  };

  // useEffect(() => {
  //   // Start the timer when the component mounts
  //   const timer = setTimeout(() => {
  //     setCanResend(true); // Enable the "Resend OTP" button after 1 minute
  //   }, 60000); // 60000 milliseconds = 1 minute

  //   // Clean up the timer when the component unmounts
  //   return () => clearTimeout(timer);
  // }, []); // Empty dependency array means this effect runs only once on mount

  // // confirm user through otp is here

  // const handleResendOtp = () => {
  //   // Logic to resend the OTP
  //   // Remember to disable the button again after resending
  //   setCanResend(false);

  //   // Restart the timer to enable the button after another minute
  //   setTimeout(() => {
  //     setCanResend(true);
  //   }, 60000);
  // };

  return (
    <>
      {isMobile && (
        <Row
          style={{
            display: "flex",
            borderBottomStyle: "solid",
            borderBottomColor: "#F2F2F2",
            borderBottomWidth: "0.1px",
          }}
        >
          <Col
            span={20}
            style={{
              display: "flex",
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "1rem",
              }}
            >
              <img
                src={logo}
                alt="RemitClick"
                className="headerLogo"
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
              />
            </div>
          </Col>
          <Col
            span={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link to="/">
              <Button
                type="primary"
                icon={<CloseOutlined />}
                className="crossSign-mobile"
              />
            </Link>
          </Col>
        </Row>
      )}
      <Row style={{ height: "100vh" }}>
        <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 10 }}>
          <RemitClickInfo />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 14 }}>
          <div className="signUpRightSec">
            {!isOtpStage ? (
              <div className="signbox">
                {!isMobile && (
                  <Link
                    to="/"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="primary"
                      icon={<CloseOutlined />}
                      className="crossSign"
                    />
                  </Link>
                )}
                <h2 className="signupTitle"> {t("register.SignUp")}</h2>
                <div className="form">
                  <Form form={form} onFinish={handleSignUp}>
                    <label className="formlabel" style={{ fontSize: "16px" }}>
                      {t("register.Name")}
                    </label>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your first name",
                        },
                      ]}
                    >
                      <Input
                        className="forminput"
                        placeholder={t("register.Name")}
                        autoComplete="off"
                      />
                    </Form.Item>

                    <label className="formlabel" style={{ fontSize: "16px" }}>
                      {t("register.EmailAddress")}
                    </label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email address!",
                        },
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                      ]}
                    >
                      <Input
                        className="forminput"
                        placeholder={t("register.EmailAddress")}
                      />
                    </Form.Item>

                    <label className="formlabel" style={{ fontSize: "16px" }}>
                      {t("register.PhoneNumbers")}
                    </label>

                    <Form.Item
                      name="phone_number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                        () => ({
                          validator(_, value) {
                            if (!value || /^\d+$/.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "Please enter a valid phone number! Only digits are allowed."
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Space align="start" style={{ width: "100%" }}>
                        <Select
                          className="unique-select2"
                          defaultValue="+1"
                          onChange={setCountryCode}
                        >
                          <Select.Option value="+1">CAD +1</Select.Option>
                          {/* <Select.Option value="+221">NGN +221</Select.Option> */}
                        </Select>
                        <Input
                          style={{ width: "16rem" }}
                          className="forminput"
                          placeholder={t("register.PhoneNumbers")}
                        />
                      </Space>
                    </Form.Item>

                    <label className="formlabel" style={{ fontSize: "16px" }}>
                      {t("register.Password")}{" "}
                    </label>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        className="forminput"
                        placeholder={t("register.Password")}
                      />
                    </Form.Item>

                    <label className="formlabel" style={{ fontSize: "16px" }}>
                      {t("register.ConfirmPassword")}
                    </label>
                    <Form.Item
                      name="cpassword"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        className="forminput"
                        placeholder={t("register.ConfirmPassword")}
                      />
                    </Form.Item>

                    <label className="formlabel" style={{ fontSize: "16px" }}>
                      {t("register.ChooseAccountType")}
                    </label>
                    <Form.Item
                      name="accountType"
                      rules={[
                        {
                          required: true,
                          message: "Please select your account type!",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="Personal"> {t("register.Person")}</Radio>
                        <Radio value="Business">{t("register.Business")}</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item>
                      <Checkbox
                        onChange={(e) => setHasAgreed(e.target.checked)}
                      >
                        {t("register.terms")}{" "}
                        <a
        className="agreement-link"
        href="#"
        onClick={(e) => { e.preventDefault(); showModal('privacy'); }}
      >
        {t("register.PrivacyPolicy")}
      </a>{" "}
      {t("register.and")}{" "}
      <a
        className="agreement-link"
        href="#"
        onClick={(e) => { e.preventDefault(); showModal('terms'); }}
      >
        {t("register.TermsConditions")}
      </a>
                      </Checkbox>
                    </Form.Item>
                    {/* <Modal
        title={modalContent === 'privacy' ? t("register.PrivacyPolicy") : t("register.TermsConditions")}
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        className="custom-modal"
      >
        <div className="custom-modal-content">
          {modalContent === 'privacy' ? (
            <div>
              <h2>{t("register.PrivacyPolicy")}</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
             
            </div>
          ) : (
            <div>
              <h2>{t("register.TermsConditions")}</h2>
              <p>Curabitur pretium tincidunt lacus. Nulla gravida orci a odio. Nullam varius, turpis et commodo pharetra, est eros bibendum elit, nec luctus magna felis sollicitudin mauris. Integer in mauris eu nibh euismod gravida. Duis ac tellus et risus vulputate vehicula. Donec lobortis risus a elit. Etiam tempor. Ut ullamcorper, ligula eu tempor congue, eros est euismod turpis, id tincidunt sapien risus a quam.</p>
              <p>Maecenas fermentum consequat mi. Donec fermentum. Pellentesque malesuada nulla a mi. Duis sapien sem, aliquet nec, commodo eget, consequat quis, neque. Aliquam faucibus, elit ut dictum aliquet, felis nisl adipiscing sapien, sed malesuada diam lacus eget erat. Cras mollis scelerisque nunc. Nullam arcu. Aliquam consequat. Curabitur augue lorem, dapibus quis, laoreet et, pretium ac, nisi. Aenean magna nisl, mollis quis, molestie eu, feugiat in, orci. In hac habitasse platea dictumst.</p>
          
            </div>
          )}
        </div>
      </Modal>
                     */}
                    {isError && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <Text style={{ color: "red" }}>{errMsg}</Text>
                      </div>
                    )}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="signupbtn"
                      disabled={!hasAgreed}
                    >
                      {t("register.SignUp")}
                    </Button>
                  </Form>

                  <p className="signupFooter">
                    {t("register.AlreadyAccount")}{" "}
                    <Link to="/signIn" style={{ textDecoration: "none" }}>
                      {t("register.SignIn")}
                    </Link>
                  </p>
                </div>
              </div>
            ) : (
              <div className="signbox">
                <h2 className="signupTitle"> {t("OTP.VerifyOTP")}</h2>
                <Form className="form" onFinish={handleOtpSubmit}>
                  <label className="formlabel" style={{ fontSize: "16px" }}>
                    {t("OTP.EnterOTP")}
                  </label>
                  <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the OTP sent to you",
                      },
                    ]}
                    help={otpError} // Display the OTP error message
                    validateStatus={otpError ? "error" : ""} // Show validation status based on the error state
                  >
                    <Input
                      className="forminput"
                      placeholder="6-digit OTP"
                      maxLength={6}
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="signupbtn"
                  >
                    {t("OTP.VerifyOTP")}
                  </Button>
                  {/* <Button
                    type="primary"
                    htmlType="submit"
                    className="signupbtn"
                    onClick={handleResendOtp}
                    disabled={!canResend} // Button is disabled based on the canResend state
                  ></Button> */}
                </Form>
              </div>
            )}
          </div>
        </Col>
        {/* This is the closing tag that was likely missing */}
        {/* <Footerpage /> */}
      </Row>
    </>
  );
};
export default SignUp;
