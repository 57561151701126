import { Content } from "antd/es/layout/layout";
import React, { useState, useEffect } from "react";
import { Input, Radio, Row, Col, Form, Select, Button, Space } from "antd";
import "./style.css";
import FormItem from "antd/es/form/FormItem";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getTableID } from "../Services/authServices";
import { generateClient } from "aws-amplify/api";
import * as mutations from "../graphql/mutations";
import { useTranslation } from "react-i18next";
import { colors } from "../styles/colors";

const { Option } = Select;

const MyFormItemContext = React.createContext([]);
function toArr(str) {
  return Array.isArray(str) ? str : [str];
}

const MyFormItem = ({ name, disabled, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;

  return <Form.Item name={concatName} {...props} />;
};

const MyFormItems = ({ name, label, rules, editable, ...rest }) => {
  return (
    <Form.Item name={name} label={label} rules={rules}>
      {editable ? (
        <Input size="large" {...rest} />
      ) : (
        <Input size="large" disabled {...rest} />
      )}
    </Form.Item>
  );
};
const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select style={{ width: 100 }} defaultValue="+221">
      {" "}
      {/* Set defaultValue */}
      <Option value="+221">+221</Option>
      {/* <Option value="+1">+1</Option> */}
    </Select>
  </Form.Item>
);

const EditRecipient = () => {
  const { t, i18n } = useTranslation();

  const [form] = Form.useForm();
  const location = useLocation();

  const navigate = useNavigate(); // Use the useNavigate hook for navigation
  const recipientDetails = location.state.recipient;
  console.log(
    "recipientDetails.recipientDeliveryMethod",
    recipientDetails.recipientDeliveryMethod
  );
  const id = recipientDetails.id;
  const isRecipientVerified = recipientDetails.isRecipientVerified;
  const API = generateClient();
  const [iscoming, IsComing] = useState(false);
  const {
    recipient,
    currencyNow,
    currencyNow2,
    sendRate,
    getRate,
    cadToXOFRate,
    formattedSendRate,
    selectedPaymentMethod,
    selectedReasons,
    selectTime,
  } = location.state;
  useEffect(() => {
    if (location.state.iscoming === true) {
      IsComing(true);
    }
  }, [location.state]);
  useEffect(() => {
    const extractPhoneDetails = (fullPhoneNumber) => {
      const match = fullPhoneNumber.match(/^(\+\d{3})(\d+)$/);
      return match
        ? { prefix: match[1], number: match[2] }
        : { prefix: "+221", number: "" };
    };

    // Check if recipientDetails.phoneNumber exists and then extract details
    if (recipientDetails.phoneNumber) {
      const { prefix, number } = extractPhoneDetails(
        recipientDetails.phoneNumber
      );

      // Use setFieldsValue to set the form fields
      form.setFieldsValue({
        prefix: prefix,
        phoneNumber: number,
      });
    }
    console.log("recipientDetails.phoneNumber", recipientDetails.phoneNumber);
  }, [form, recipientDetails.phoneNumber]);
  // Extract phone details function

  const onFinish = async (values) => {
    const tableID = await getTableID();
    if (!tableID) {
      console.error("Table ID is not available.");
      return;
    }
    const phoneNumberWithoutCode = values.phoneNumber.replace(
      /^(\+[0-6]+)\s*/,
      ""
    );

    const input = {
      id: id,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: phoneNumberWithoutCode,
      email: values.email,
      recipientDeliveryMethod: values.bankDetail,
      addressState: values.state,
      addressCity: values.city,
      addressStreet: values.address,
      userID: tableID,
      addressCountry: values.country,
      addressZipCode: values.zip,
      typeOfRecipient: values.typeofRecipient,
      isRecipientVerified: false,
    };
    try {
      const updateRecipient = await API.graphql({
        query: mutations.updateRecipient,
        variables: { input },
      });
      const updatedRecipient = updateRecipient.data.updateRecipient;

      if (iscoming) {
        navigate("/overview", {
          state: {
            recipient: updatedRecipient,
            currencyNow: currencyNow,
            currencyNow2: currencyNow2,
            sendRate: sendRate,
            getRate: getRate,
            cadToXOFRate: cadToXOFRate,
            formattedSendRate: formattedSendRate,
            selectedPaymentMethod: selectedPaymentMethod,
            selectedReasons: selectedReasons,
            selectTime: selectTime,
          },
        });
      } else {
        navigate("/selectRecipient");
      } // Navigate upon successful creation
    } catch (err) {
      console.error("Error updating recipient:", err);
    }
  };
  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <div className="recMainDiv" style={{ padding: "1rem" }}>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="recHeadingDiv"
        >
          <h1 style={{ color: colors.primary ,fontFamily: 'Gopher'}}>
            {t("EditRecipient.EditRecipient")}
          </h1>
        </div>
        <hr style={{ marginTop: "-0.5rem", marginBottom: "1rem" }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            initialValues={{
              firstName: recipientDetails.firstName,
              lastName: recipientDetails.lastName,
              prefix: recipientDetails.prefix, // Set the initial value for the country code
              phoneNumber: recipientDetails.number, // Set the initial value for the phone number
              typeofRecipient: recipientDetails.typeOfRecipient,
              email: recipientDetails.email,
              address: recipientDetails.addressStreet,
              state: recipientDetails.addressState,
              city: recipientDetails.addressCity,
              country: recipientDetails.addressCountry,
              zip: recipientDetails.addressZipCode,
              bankDetail: recipientDetails.recipientDeliveryMethod,
              typeofRecipient: recipientDetails.typeOfRecipient,
              // Add other initial values here if needed
            }}
          >
            <MyFormItem
              name="typeofRecipient"
              label={t("EditRecipient.TypeofRecipient")}
              style={{ marginTop: "1rem" }}
            >
              <Radio.Group disabled={isRecipientVerified}>
                <Radio value="Personal">{t("EditRecipient.Person")}</Radio>
                <Radio value="Business">{t("EditRecipient.Business")}</Radio>
              </Radio.Group>
            </MyFormItem>
            {isRecipientVerified ? (
              <div>
                <div
                  style={{ display: "flex", gap: "1.5rem", marginTop: "1rem" }}
                >
                  <MyFormItems
                    name="firstName"
                    label={t("EditRecipient.FirstName")}
                    rules={[
                      { required: true, message: "Please input First Name" },
                    ]}
                    // Set editable to false to disable editing
                  />
                  {/* <Input size="large" /> */}
                  {/* </MyFormItem> */}

                  <MyFormItems
                    name="lastName"
                    label={t("EditRecipient.LastName")}
                    editable={false}
                    rules={[
                      { required: true, message: "Please input Last Name" },
                    ]}
                  />
                  {/* <Input size="large" editable={false} />
                  </MyFormItem> */}
                </div>
                <MyFormItems
                  name="phoneNumber"
                  label={t("EditRecipient.PhoneNumber")}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the Phone number",
                    },
                    {
                      pattern: new RegExp(
                        /^(\+221)?[ -]?[7-8][0-9]{2}[ -]?[0-9]{3}[ -]?[0-9]{3}$/
                      ),
                      message: "Please enter a valid Senegal phone number",
                    },
                  ]}
                />
                {/* <Input size="large" /> */}
                {/* <Input addonBefore={prefixSelector} size="large" />
                </MyFormItem> */}
              </div>
            ) : (
              <div>
                <div
                  style={{ display: "flex", gap: "1.5rem", marginTop: "1rem" }}
                >
                  <MyFormItem
                    name="firstName"
                    label={t("EditRecipient.FirstName")}
                    rules={[
                      { required: true, message: "Please input First Name" },
                    ]}
                    // Set editable to false to disable editing
                  >
                    <Input size="large" />
                  </MyFormItem>

                  <MyFormItem
                    name="lastName"
                    label={t("EditRecipient.LastName")}
                    editable={false}
                    rules={[
                      { required: true, message: "Please input Last Name" },
                    ]}
                  >
                    <Input size="large" editable={false} />
                  </MyFormItem>
                </div>
                <MyFormItem
                  name="phoneNumber"
                  label={t("EditRecipient.PhoneNumber")}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the Phone number",
                    },
                    {
                      pattern: new RegExp(
                        /^[7-8][0-9]{2}[ -]?[0-9]{3}[ -]?[0-9]{3}$/
                      ),
                      message: "Please enter a valid Senegal phone number",
                    },
                  ]}
                >
                  <Input
                    addonBefore={
                      <Form.Item name="prefix" noStyle>
                        <Select style={{ width: 100 }}>
                          <Option value="+221">+221</Option>
                        </Select>
                      </Form.Item>
                    }
                    size="large"
                  />
                </MyFormItem>
              </div>
            )}
            <MyFormItem
              name="email"
              label={t("EditRecipient.email")}
              rules={[
                { required: true, message: "Please input E-mail" },
                { type: "email", message: "The input is not valid E-mail" },
              ]}
            >
              <Input size="large" />
            </MyFormItem>
            <MyFormItem
              name="address"
              label={t("EditRecipient.address")}
              rules={[{ required: true, message: "Please input address" }]}
            >
              <Input size="large" />
            </MyFormItem>
            <div style={{ display: "flex", gap: "1.5rem" }}>
              <MyFormItem
                name="city"
                label={t("EditRecipient.City")}
                rules={[{ required: true, message: "Please input city" }]}
              >
                <Input size="large" />
              </MyFormItem>
              <MyFormItem
                name="state"
                label={t("EditRecipient.State")}
                rules={[{ required: true, message: "Please input state" }]}
              >
                <Input size="large" />
              </MyFormItem>
            </div>
            <div style={{ display: "flex", gap: "1.5rem" }}>
              <MyFormItem
                name="zip"
                label={t("EditRecipient.ZipCode")}
                rules={[{ required: true, message: "Please input country" }]}
              >
                <Input size="large" />
              </MyFormItem>
              <MyFormItem
                name="country"
                label={t("EditRecipient.Country")}
                rules={[{ required: true, message: "Please input country" }]}
              >
                <Input size="large" />
              </MyFormItem>
            </div>
            {/* <MyFormItem
              name="bankDetail"
              label="Bank Name"
              rules={[
                {
                  required: true,
                  message: "Please enter bank name",
                },
              ]}
            >
              <Input size="large" />
            </MyFormItem>
            <MyFormItem
              name="recipientNumber"
              label="Recipient Account Number"
              rules={[
                {
                  required: true,
                  message: "Please enter recipient's account number",
                },
              ]}
            >
              <Input size="large" />
            </MyFormItem> */}
            <MyFormItem
              name="bankDetail"
              label={t("EditRecipient.Howsend")}
              rules={[
                {
                  required: true,
                  message: "Please Select how they will Receive Money",
                },
              ]}
            >
              <Radio.Group
                defaultValue={recipientDetails.recipientDeliveryMethod}
              >
                <Radio value="mobileMoney">
                  {t("EditRecipient.OnlineBanking")}
                </Radio>
                <Radio value="cash">{t("EditRecipient.CashPickup")}</Radio>
              </Radio.Group>
            </MyFormItem>
            <Form.Item>
              <Button
                className="signupbtn"
                type="primary"
                htmlType="submit"
                style={{
                  width: "100%",
                }}
              >
                <span style={{ fontSize: "18px" }}>
                  {t("EditRecipient.Continue")}
                </span>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Content>
  );
};
export default EditRecipient;
