import React, { useEffect, useState } from "react";
import { Content } from "antd/es/layout/layout";
import "./style.css";
import {
  Button,
  Card,
  Collapse,
  Modal,
  Spin,
  Alert,
  message,
  Typography,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  getFullName,
  getTableID,
  getUserInfo,
  getThresholdLimit,
} from "../Services/authServices";
import { fetchUserAttributes } from "@aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import { useTranslation } from "react-i18next";
import { colors } from "../styles/colors";

const Overview = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false); // State to control the visibility of the modal

  const [user, setUser] = useState([]);
  const [fullName, setFullName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [alert, setAlert] = useState({ type: "", message: "", show: false });
  const [remitanceAlert, setRemitanceAlert] = useState({
    type: "",
    message: "",
    show: false,
  });
  const [isActive, setIsActive] = useState(true); // Start timer automatically
  const [selectTime, setSelectTime] = useState(location.state.selectTime);
  const [seconds, setSeconds] = useState(selectTime); // Convert minutes to seconds
  const [iscoming, IsComing] = useState(true); // Convert minutes to seconds
  const { Title, Text } = Typography;
  const [xOFToCADRate, setXOFToCadRate] = useState(0.1);
  const [cadToXOFRate, setcadToXOFRate] = useState(location.state.cadToXOFRate);
  const [getRate, setGetRate] = useState(location.state.getRate);
  const [remitanceLimit, setRemitanceLimit] = useState();
  const [thresholdLimit, setThresholdLimit] = useState();
  const client = generateClient();

  const {
    recipient,
    currencyNow,
    currencyNow2,
    sendRate,
    formattedSendRate,
    selectedPaymentMethod,
    selectedReasons,
    sourceOfFund,
  } = location.state;

  // timer......

  useEffect(() => {
    let interval: any;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        setSelectTime((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setShowModal(true);
      setIsActive(false);
    }
    // console.log("location", location.state);
    // console.log("seconds", seconds);
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  async function fetchCurrentRate() {
    const query = `query MyQuery {
      listAdmins {
        items {
          finalCADToXOF
          finalXOFToCAD
        }
      }
    }
    `;
    const response = await client.graphql({
      query: query,
    });

    // cadToXOFRate(response.data.listAdmins.items[0].finalCADToXOF);
    setXOFToCadRate(response.data.listAdmins.items[0].finalXOFToCAD);
    const recipietrate =
      formattedSendRate * response.data.listAdmins.items[0].finalCADToXOF;
    setGetRate(recipietrate);
    console.log(
      "current rate",
      sendRate * cadToXOFRate,
      getRate,
      sendRate,
      cadToXOFRate
    );
  }
  const formattedTime = () => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  useEffect(() => {
    currentAuthenticatedUser();
  }, []);
  useEffect(() => {
    // Check the transfer amount against the remittance limit
    if (formattedSendRate > remitanceLimit) {
      setRemitanceAlert({
        type: "error",
        // Pass the formatted remitanceLimit as a variable to the translation function
        message: t("sendRemittance.limitMaxErr", {
          limit: formatNumber(remitanceLimit),
        }),
        show: true,
      });
    } else if (parseFloat(getRate) > parseFloat(thresholdLimit)) {
      // Check if getRate exceeds thresholdLimit and format it
      setRemitanceAlert({
        type: "error",
        // Pass the formatted thresholdLimit as a variable to the translation function
        message: t("sendRemittance.orangeMaxErr", {
          limit: formatNumber(thresholdLimit),
        }),
        show: true,
      });
    } else {
      // If there's no error, ensure the alert is not showing
      setRemitanceAlert({ ...remitanceAlert, show: false });
    }
  }, [formattedSendRate, remitanceLimit, getRate, thresholdLimit, t]);
  async function currentAuthenticatedUser() {
    try {
      setIsDataLoading(true); // Set to true when starting to load data
      const user = await fetchUserAttributes();
      setUser(user);
      const full_name = await getFullName();
      setFullName(full_name);

      const tableID = await getTableID();
      const user_info = await getUserInfo(tableID);
      setRemitanceLimit(user_info.remainingTransferLimit);

      const thresholdlimit = await getThresholdLimit();
      setThresholdLimit(thresholdlimit);

      setIsDataLoading(false); // Set to false once all data has been loaded
    } catch (err) {
      console.log(err);
      setIsDataLoading(false); // Ensure to set to false even if there's an error
    }
  }
  const triggerInteracLambda = async () => {
    const payload = {
      CustomerEmail: user.email,
      CustomerName: fullName,
      Description: selectedReasons,
      Amount: formattedSendRate,
      currencySender: currencyNow,
      currencyRecipient: currencyNow2,
      getRate: getRate,
      cadToXOFRate: cadToXOFRate,
      recipientID: recipient.id,
      userID: user["custom:TableID"],
      sourceOfFund: sourceOfFund,
      selectedPaymentMethod: selectedPaymentMethod,
      recipientPhone: recipient.phoneNumber,
      recipientFirstName: recipient.firstName,
      recipientLastName: recipient.lastName,
      recipientDeliveryMethod: recipient.recipientDeliveryMethod,
    };
    console.log("typeof payload to Lambda:", typeof payload.Amount);
    try {
      console.log("Sending payload to Lambda:", payload);
      const response = await axios.post(
        "https://dm8a3p2kid.execute-api.us-east-2.amazonaws.com/default/RequestInteracPayment-dev",
        payload
      );
      console.log("Lambda response:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error triggering Lambda:",
        error.response ? error.response.data : error
      );
      throw error.response ? error.response.data : error;
    }
  };

  const triggerWalletLambda = async () => {
    const payload = {
      CustomerEmail: user.email,
      CustomerName: fullName,
      Description: selectedReasons,
      Amount: formattedSendRate,
      currencySender: currencyNow,
      currencyRecipient: currencyNow2,
      getRate: getRate,
      cadToXOFRate: cadToXOFRate,
      recipientID: recipient.id,
      userID: user["custom:TableID"],
      selectedPaymentMethod: selectedPaymentMethod,
      sourceOfFund: sourceOfFund,
      recipientPhone: recipient.phoneNumber,
      recipientFirstName: recipient.firstName,
      recipientLastName: recipient.lastName,
      recipientDeliveryMethod: recipient.recipientDeliveryMethod,
    };
    console.log("Sending payload to Lambda:", payload);

    try {
      const response = await axios.post(
        "https://x47mcbftv4.execute-api.us-east-2.amazonaws.com/default/WalletPaymentMethod-dev",
        payload
      );
      console.log("Lambda response:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error triggering Lambda:",
        error.response ? error.response.data : error
      );
      throw error.response ? error.response.data : error;
    }
  };

  const triggerPaymentMethodLambda = async () => {
    setIsModalVisible(true);
    setLoading(true);
    let response = null; // Initialize response variable

    try {
      switch (selectedPaymentMethod) {
        case "interac":
          response = await triggerInteracLambda(); // Assuming this returns a response object
          break;
        case "wallet":
          response = await triggerWalletLambda(); // Assuming this returns a response object
          break;
        default:
          console.error("Unsupported payment method:", selectedPaymentMethod);
          setAlert({
            type: "error",
            message: "Unsupported payment method",
            show: true,
          });
          return; // Exit the function if the payment method is unsupported
      }

      // Check if the statusCode indicates an error or if the body contains error information
      if (response && response.statusCode >= 400) {
        const errorBody = JSON.parse(response.body); // Parse the body to get the error details
        console.error("Lambda error:", errorBody.error, errorBody.details);

        setAlert({
          type: "error",
          message: "Payment could not be processed",
          show: true,
        });
      } else {
        // If the response indicates success
        setAlert({
          type: "success",
          message: "Payment completed successfully",
          show: true,
        });
      }
    } catch (error) {
      console.error("Error during payment process:", error);
      // Handle any exceptions thrown during the Lambda function call
      setAlert({
        type: "error",
        message: "An error occurred during the payment process",
        show: true,
      });
    } finally {
      setLoading(false);
      setIsModalVisible(false);
    }
  };

  const handleSubmit = async () => {
    setShowModal(false);
    fetchCurrentRate();
  };
  const handleCancel = async () => {
    setShowModal(false);
    navigate("/dashboard");
  };
  function formatNumber(num) {
    return Number(num)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const remittanceAlertComponent = remitanceAlert.show && (
    <Alert
      message={remitanceAlert.message}
      type={remitanceAlert.type}
      showIcon
    />
  );
  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      <div className="recMainDiv" style={{ padding: "1rem" }}>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="recHeadingDiv"
        >
          {/* Conditional rendering based on alert state */}
          <h1 style={{ color: colors.primary ,fontFamily:'Gopher' }}>
            {alert.show ? "Transaction Receipt" : t("SendMoney.Overview")}
          </h1>
        </div>
        <hr />
      </div>

      <div style={{ width: "100%", maxWidth: "30rem" }}>
        <Collapse
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
          defaultActiveKey={["1"]}
          items={[
            {
              key: "1",
              // label:'Transfer summary',
              label: (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ fontSize: "18px", fontWeight: "600" ,  fontFamily: 'Gopher'}}>
                    {t("SendMoney.Transfer_summary")}
                  </span>
                  {!alert.show && (
                    // <Link to="/moneyCalculator">
                    <Button
                      onClick={() => {
                        navigate("/moneyCalculator", {
                          state: {
                            recipient, // This is the recipient state you're currently passing
                            currencyNow, // The currently selected currency
                            currencyNow2, // The target currency
                            formattedSendRate, // The amount of money in the currencyNow that the user wants to send
                            getRate, // The amount of money in the currencyNow2 that the recipient will get
                            cadToXOFRate, // The exchange rate from CAD to XOF
                            selectedReasons, // reason of sending
                            sourceOfFund,
                            iscoming,
                            selectedPaymentMethod,
                            // selectTime,
                            // time remaining to do treanscation
                          },
                        });
                      }}
                      style={{ color: "white", backgroundColor: colors.secondary,fontFamily: 'Gopher' }}
                    >
                      {t("SendMoney.Edit")}
                    </Button>
                    // </Link>
                  )}
                </div>
              ),
              children: (
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={{fontFamily:'Gopher'}}>{t("SendMoney.Transfer_Amount")}</p>
                    <p style={{fontFamily:'Gopher'}}>${formatNumber(formattedSendRate)}</p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={{fontFamily:'Gopher'}}>{t("SendMoney.ExchangeRate")}</p>
                    <p>{`1 ${currencyNow.toUpperCase()} = ${formatNumber(
                      cadToXOFRate
                    )} ${currencyNow2.toUpperCase()}`}</p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={{fontFamily:'Gopher'}}>{t("SendMoney.Recipientgets")}</p>
                    <p style={{fontFamily:'Gopher'}}>CFA {formatNumber(getRate)}</p>
                  </div>
                  <hr />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={{fontFamily:'Gopher'}}>{t("SendMoney.Fees")}</p>
                    <p>$ 0.00</p>
                  </div>
                  <hr />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>
                      <b style={{fontFamily:'Gopher'}}>{t("SendMoney.Total_to_pay")}</b>
                    </p>
                    <p style={{fontFamily:'Gopher'}}>$ {formatNumber(formattedSendRate)}</p>
                  </div>
                </div>
              ),
            },
          ]}
        />
        {remittanceAlertComponent}
        <Collapse
          style={{ marginTop: "1rem" }}
          items={[
            {
              key: "2",
              // label:'Transfer summary',
              label: (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ fontSize: "18px", fontWeight: "600",fontFamily:'Gopher' }}>
                    {t("SendMoney.Recipient_details")}
                  </span>
                  {!alert.show && (
                    // <Link to="/addRecipient">
                    <Button
                      onClick={() => {
                        navigate("/editRecipient", {
                          state: {
                            recipient, // This is the recipient state you're currently passing
                            currencyNow, // The currently selected currency
                            currencyNow2, // The target currency
                            formattedSendRate, // The amount of money in the currencyNow that the user wants to send
                            getRate, // The amount of money in the currencyNow2 that the recipient will get
                            cadToXOFRate, // The exchange rate from CAD to XOF
                            selectedReasons, // reason of sending
                            sourceOfFund,
                            iscoming,
                            selectedPaymentMethod,
                            selectTime,
                            // time remaining to do treanscation
                          },
                        });
                      }}
                      style={{ color: "white", backgroundColor: colors.secondary,fontFamily:'Gopher'  }}
                    >
                      {t("SendMoney.Edit")}
                    </Button>
                    // </Link>
                  )}
                </div>
              ),
              children: (
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={{fontFamily:'Gopher' }}>{t("register.Name")}</p>
                    <p style={{fontFamily:'Gopher' }}>
                      {recipient.firstName} {recipient.lastName}
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={{fontFamily:'Gopher' }}>{t("register.PhoneNumbers")}</p>
                    <p style={{fontFamily:'Gopher' }}>{recipient.phoneNumber}</p>
                  </div>
                  <hr />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={{fontFamily:'Gopher'}}>{t("SendMoney.recipient_Methods")}</p>
                    <p style={{fontFamily:'Gopher'}}>{recipient.recipientDeliveryMethod}</p>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={{fontFamily:'Gopher'}}>Money Wallet</p>
                    <p style={{fontFamily:'Gopher'}}>Orange Money</p>
                  </div>
                </div>
              ),
            },
          ]}
        />
        <Collapse
          style={{ marginTop: "1rem" }}
          items={[
            {
              key: "3",
              label: (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ fontSize: "18px", fontWeight: "600",fontFamily:'Gopher' }}>
                    {t("SendMoney.Payment_details")}
                  </span>
                  {!alert.show && (
                    // <Link to="/PaymentMethod">
                    <Button
                      onClick={() => {
                        //console.log("recipient",recipient.recipientDeliveryMethod);
                        navigate("/PaymentMethod", {
                          state: {
                            recipient, // This is the recipient state you're currently passing
                            currencyNow, // The currently selected currency
                            currencyNow2, // The target currency
                            formattedSendRate, // The amount of money in the currencyNow that the user wants to send
                            getRate, // The amount of money in the currencyNow2 that the recipient will get
                            cadToXOFRate, // The exchange rate from CAD to XOF
                            selectedReasons, // reason of sending
                            sourceOfFund,
                            iscoming,
                            selectedPaymentMethod,
                            selectTime,
                          },
                        });
                      }}
                      style={{ color: "white", backgroundColor: colors.secondary,fontFamily:'Gopher' }}
                    >
                      {t("SendMoney.Edit")}
                    </Button>
                    // </Link>
                  )}
                </div>
              ),
              children: (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{fontFamily:'Gopher'}}>{t("SendMoney.Paymeny_Methods")}</p>
                  <p style={{fontFamily:'Gopher'}}>{selectedPaymentMethod}</p>
                </div>
              ),
            },
          ]}
        />

        {!alert.show && (
          <div>
            <div style={{ padding: 10 }}>
              <Text strong className="converter-input-title">
                Time Left {formattedTime()}
              </Text>
            </div>

            <Button
              onClick={triggerPaymentMethodLambda}
              style={{ marginTop: "1rem", width: "100%" }}
              className="signupbtn"
              size="large"
              // Disable the button if data is still loading or if a remittance alert is showing
              disabled={isDataLoading || remitanceAlert.show}
            >
              <span style={{ fontSize: "18px" ,fontFamily:'Gopher'}}>
                {t("SendMoney.Confirm_and_Send")}
              </span>
            </Button>
          </div>
        )}

        <Modal
          title="Processing Payment"
          visible={isModalVisible}
          footer={null}
          closable={false}
        >
          {loading ? (
            <Spin tip="Loading..." />
          ) : (
            alert.show && (
              <Alert message={alert.message} type={alert.type} showIcon />
            )
          )}
        </Modal>

        {/* Display the success/error alert outside the modal after the modal is closed */}
        {alert.show && !isModalVisible && (
          <Alert
            message={alert.message}
            type={alert.type}
            showIcon
            closable
            afterClose={() => setAlert({ type: "", message: "", show: false })}
            style={{ marginTop: "20px" }}
          />
        )}
      </div>
      {/* modal is here of time expired exchnage rate  */}
      <Modal
        title="CADXOF Exchange Rate"
        visible={showModal}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Update
          </Button>,
          <Button key="submit" type="primary" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <p style={{fontFamily:'Gopher'}}>
          The exchange rate has expired. Please review the updated exchange
          rate."
        </p>
      </Modal>
    </Content>
  );
};
export default Overview;
