import { Footer } from "antd/es/layout/layout";
import React from "react";
import logo from "../Image/logo.png";
import "./style.css";
import { useTranslation } from "react-i18next";
import { colors } from "../styles/colors";

const RemitClickInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="signUpLeftSec">
      <img src={logo} alt="logo" className="logo" />
      <div style={{ padding: "2rem 5rem" }}>
        <p className="remitclickInfo" style={{color:colors.white,fontFamily:'Gopher'}}>
          {t("remitclickInfo.info")}
        </p>
      </div>
    </div>
  );
};

export default RemitClickInfo;
