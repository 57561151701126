// authService.js
import { signUp, signIn, fetchUserAttributes } from "@aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export async function signUpUser({ username, password, email, phone_number }) {
  try {
    const signUpResponse = await signUp({
      username,
      password,
      options: {
        userAttributes: {
          phone_number, // Make sure phone_number is in E.164 format
        },
        autoSignIn: {
          // If you want to enable auto sign-in after registration, set this to true
          enabled: false,
        },
      },
    });
    console.log("Sign up success", signUpResponse);
    return signUpResponse;
  } catch (error) {
    console.error("Error signing up:", error);
    throw error;
  }
}

export async function signInUser(username, password) {
  try {
    const user = await signIn(username, password);
    console.log("Sign in success", user);
    return user;
  } catch (error) {
    console.error("Error signing in:", error);
    throw error;
  }
}
export async function getTableID() {
  try {
    const user = await fetchUserAttributes();
    return user["custom:TableID"];
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getUserName() {
  try {
    const user = await fetchUserAttributes();
    return user.email;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getUserVerificationStatus(tableID) {
  try {
    const user = await fetchUserAttributes();
    //const tableID = user["custom:TableID"];

    console.log("FUNCTION GOT TABLE ID", tableID);
    const userVerificationQuery = /* GraphQL */ `
      query MyQuery($id: ID!) {
        getUser(id: $id) {
          identificationVerified
          isOrganization
          verificationStatus
        }
      }
    `;

    // Use the API.graphql method to send the query
    const { data } = await client.graphql({
      query: userVerificationQuery,
      variables: { id: tableID }, // Ensure you pass the variables correctly
    });

    // Assuming the API returns the user verification status directly
    const { getUser } = data;

    console.log("FUNCTION GOT THIS DATA", getUser);
    const { identificationVerified, isOrganization, verificationStatus } =
      getUser;

    // Determine whether to show KYB (Know Your Business) or KYC (Know Your Customer) option
    let verificationOption = null;
    if (!identificationVerified) {
      verificationOption = isOrganization ? "KYB" : "KYC";
    }

    // Return an object with both status and the determined option
    return {
      identificationVerified,
      isOrganization,
      verificationStatus,
      verificationOption, // Will be null, "KYB", or "KYC"
    };
  } catch (err) {
    console.log("Error fetching user verification status:", err);
    return null; // Return null in case of an error
  }
}

export async function getUserInfo(tableID) {
  try {
    const user = await fetchUserAttributes();
    // const tableID = user["custom:TableID"];
    const userInfo = /* GraphQL */ `
      query MyQuery($id: ID!) {
        getUser(id: $id) {
          phoneNumber
          name
          lastName
          firstName
          email
          dateOfBirth
          remainingTransferLimit
          businessName
          businessReferenceNumber
          remitanceLimit
          isOrganization
          identificationVerified
        }
      }
    `;
    // Use the API.graphql method to send the query
    const { data } = await client.graphql({
      query: userInfo,
      variables: { id: tableID }, // Ensure you pass the variables correctly
    });
    // Assuming the API returns the user object directly
    const { getUser } = data;

    return {
      phoneNumber: getUser.phoneNumber,
      lastName: getUser.lastName,
      firstName: getUser.firstName,
      email: getUser.email,
      dateOfBirth: getUser.dateOfBirth,
      address: getUser.address,
      name: getUser.name,
      businessName: getUser.businessName,
      remainingTransferLimit: getUser.remainingTransferLimit,
      businessReferenceNumber: getUser.businessReferenceNumber,
      remitanceLimit: getUser.remitanceLimit,
      isOrganization: getUser.isOrganization,
      identificationVerified: getUser.identificationVerified,
    };
  } catch (err) {
    console.log(err);
    return null;
  }
}
export async function getFullName() {
  try {
    const user = await fetchUserAttributes();
    const tableID = user["custom:TableID"];

    const userInfoQuery = /* GraphQL */ `
      query MyQuery($id: ID!) {
        getUser(id: $id) {
          lastName
          firstName
        }
      }
    `;

    // Use the API.graphql method to send the query
    const { data } = await client.graphql({
      query: userInfoQuery,
      variables: { id: tableID }, // Ensure you pass the variables correctly
    });

    // Assuming the API returns the user object directly
    const { getUser } = data;

    // Concatenate firstName and lastName to get the full name
    const fullName = `${getUser.firstName} ${getUser.lastName}`;

    return fullName; // Return the full name
  } catch (err) {
    console.log("Error fetching full name:", err);
    return null; // Return null in case of an error
  }
}
export async function getName() {
  try {
    const user = await fetchUserAttributes();
    const tableID = user["custom:TableID"];

    const userInfoQuery = /* GraphQL */ `
      query MyQuery($id: ID!) {
        getUser(id: $id) {
          name
        }
      }
    `;

    // Use the API.graphql method to send the query
    const { data } = await client.graphql({
      query: userInfoQuery,
      variables: { id: tableID }, // Ensure you pass the variables correctly
    });

    // Assuming the API returns the user object directly
    const { getUser } = data;

    // Concatenate firstName and lastName to get the full name
    const name = `${getUser.name}`;

    return name; // Return the full name
  } catch (err) {
    console.log("Error fetching full name:", err);
    return null; // Return null in case of an error
  }
}
export async function getThresholdLimit() {
  try {
    const getThresholdLimit = /* GraphQL */ `
      query GetAdmin($id: ID!) {
        getAdmin(id: $id) {
          monthlyLimit
          thresholdlimit
        }
      }
    `;
    const id = "43e1274f-66d1-4eba-8c22-f5a02b7519d7";
    // Use the API.graphql method to send the query
    const { data } = await client.graphql({
      query: getThresholdLimit,
      variables: { id: id }, // Ensure you pass the variables correctly
    });
    // Assuming the API returns the user object directly
    const { getAdmin } = data;

    // Concatenate firstName and lastName to get the full name
    const thresholdlimit = `${getAdmin.thresholdlimit}`;

    return thresholdlimit;
  } catch (err) {
    console.log("Error fetching full name:", err);
    return null; // Return null in case of an error
  }
}
