import React, { useState, useEffect } from "react";
import "./style.css";
import { Button, Layout, Menu, Cascader, Drawer, Row, Col, Select } from "antd";
import logo from "../Image/logo.png";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { CookiesProvider, useCookies } from "react-cookie";
const { Header } = Layout;

const HeaderComponent = () => {
  const { t, i18n } = useTranslation();
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [defLang, setDefLang] = useState("en");
  const [visible, setVisible] = useState(false);
  const isMobile = viewportWidth < 768;

  const [cookies, setCookie] = useCookies(["lang"]);

  useEffect(() => {
    console.log("check", cookies.lang);
    i18n.changeLanguage(cookies.lang);
    setDefLang(cookies.lang);
  }, []);

  const showDrawer = () => {
    setVisible(!visible);
  };

  const menuItem = [
    {
      key: "0",
      name: "header.home",
      path: "/",
    },
    {
      key: "1",
      name: "header.about",
      path: "/about",
    },
    {
      key: "2",
      name: "header.support",
      path: "/support",
    },
  ];

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setDefLang(value);
    i18n.changeLanguage(value);
    setCookie("lang", value);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          height: !isMobile ? 150 : 80,
          display: "flex",
          justifyContent: isMobile ? "start" : "center",
          padding: 0,
          alignItems: "center",
          backgroundColor: "#00515c",
        }}
      >
        {!isMobile ? (
          <Row
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Col
              md={{ span: 4 }}
              xl={{ span: 6 }}
              style={{
                paddingLeft: 10,
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                to="/"
                style={{
                  height: "100%",
                  width: "100%",
                  padding: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={logo}
                  alt="SALANE"
                  // className="headerLogo"
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                  }}
                />
              </Link>
            </Col>
            <Col
              md={{ span: 19 }}
              xl={{ span: 17 }}
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              backgroundColor="red"
            >
              <Menu
                mode="horizontal"
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                  justifyContent: "center",
                }}
              >
                {menuItem.map((item) => (
                  <Menu.Item key={item.key}>
                    <Link  style={{ fontFamily:'Gopher', color: 'white',fontSize:'20px'  }} to={item.path}>{t(item.name)}</Link>
                  </Menu.Item>
                ))}
              </Menu>

              <Select
                defaultValue="en"
                placeholder="Language"
                style={{
                  margin: 20,
                  width: 120,
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  borderRadius: 20,
                }}
                value={defLang}
                bordered={false}
                onChange={handleChange}
                options={[
                  { value: "en", label: "English" },
                  { value: "fr", label: "Français" },
                ]}
              />
              <Link to="/signIn">
                <Button size="large" shape="round" className="login-btn">
                  {t("register.Login")}
                </Button>
              </Link>
            </Col>
          </Row>
        ) : (
          <Row
            style={{
              display: "flex",
              height: "100%",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Col
              span={3}
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="text"
                onClick={showDrawer}
                size="large"
                icon={<MenuOutlined />}
              />
            </Col>
            <Col
              span={21}
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <img
                src={logo}
                alt="SALANE"
                className="headerLogo"
                style={{ height: "100%" }}
              />
            </Col>
          </Row>
        )}
      </Header>
      <Drawer
        placement="left"
        closable={true}
        onClose={showDrawer}
        open={visible}
        style={{ zIndex: 99999 }}
      >
        <Menu style={{ fontWeight: "bolder", borderInlineEnd: "none" }}>
          {menuItem.map((item) => (
            <Menu.Item key={item.key}>
              <Link to={item.path}>{t(item.name)}</Link>
            </Menu.Item>
          ))}
        </Menu>

        <Link to="/signIn" style={{ marginLeft: "1rem" }}>
          <Button size="large" shape="round" className="login-btn">
          {t("register.Login")}
          </Button>
        </Link>
        <Row>
          <Select
            defaultValue={defLang}
            placeholder="Language"
            style={{
              margin: 20,
              width: 120,
              backgroundColor: "white",
              backgroundColor: "white",
              border: "1px solid #ccc",
              borderRadius: 20,
            }}
            bordered={false}
            onChange={handleChange}
            options={[
              { value: "en", label: "English" },
              { value: "fr", label: "Français" },
            ]}
          />
        </Row>
      </Drawer>
    </>
  );
};

export default HeaderComponent;
