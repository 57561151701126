import "./App.css";
import { useEffect, useState, useCallback } from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { signOut } from "@aws-amplify/auth";
import { getCurrentUser } from "aws-amplify/auth";
import config from "./amplifyconfiguration.json";

// Home
import Home from "./Screens/Home";
import About from "./Screens/About";
import Support from "./Screens/Support";
// Auth
import SignIn from "./Screens/SignIn";
import SignUp from "./Screens/SignUp";
import Kyc from "./Screens/Kyc";
// Dashboard
import DefaultLayout from "./Layout/DefaultLayout";
import Dashboard from "./Screens/Dashboard";
import SelectRecipient from "./Screens/SelectRecipient";
import AddRecipient from "./Screens/AddRecipient";
import Transactions from "./Screens/Transactions";
import MyAccount from "./Screens/MyAccount";
import Settings from "./Screens/Settings";
import MoneyCalculator from "./Screens/MoneyCalculator";
import PaymentMethod from "./Screens/PaymentMethod";
import Overview from "./Screens/OverView";
import SelectEditRecipient from "./Screens/SelectEditRecipient";
import EditRecipient from "./Screens/EditRecipient";
import InterectPaymentDetails from "./Screens/InterectPaymentDetails";
import ForgetPassword from "./Screens/ForgetPassword";
import VerifyEmail from "./Screens/VerifyEmail";
import ChangePassword from "./Screens/ChangePassword";
import ResetPassword from "./Screens/ResetPassword";
import MyAccountDetails from "./Screens/MyAccountDetails";
import Wallet from "./Screens/Wallet";
import FundWallet from "./Screens/FundWallet";
import KYCUser from "./Screens/KYCUser";
import KYB from "./Screens/KYB";

Amplify.configure(config);
function App() {
  async function currentAuthenticatedUser() {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      console.log(`The username: ${username}`);
      console.log(`The userId: ${userId}`);
      console.log(`The signInDetails: ${signInDetails}`);
      return username;
    } catch (err) {
      console.log(err);
    }
  }
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleLoginSuccess = async () => {
    console.log("handleLogin ", isLoggedIn);

    // Assuming setIsLoggedIn is asynchronous and returns a promise
    setIsLoggedIn(true);

    const loginTimestamp = new Date().getTime().toString();
    localStorage.setItem("loginTimestamp", loginTimestamp);

    navigate("/dashboard");
  };

  // const handleLoginSuccess = () => {
  //   console.log("handleLoginSuccess called");
  //   setIsLoggedIn(true);
  // };
  const checkLoginStatus = async () => {
    console.log("checkLoginStatus");
    const loginTimestamp = localStorage.getItem("loginTimestamp");
    try {
      const user = currentAuthenticatedUser();

      const currentTime = new Date().getTime();
      const oneDay = 7 * 24 * 60 * 60 * 1000; //1 week

      if (
        loginTimestamp &&
        currentTime - parseInt(loginTimestamp, 10) < oneDay
      ) {
        console.log("Login as ", user.username);
        setIsLoggedIn(true);
        localStorage.setItem("loginTimestamp", currentTime.toString());
      } else {
        console.log("Login X - over 24 hour");
        localStorage.removeItem("loginTimestamp");
      }
      setLoading(false);
    } catch (error) {
      console.log("Login X");
      localStorage.removeItem("loginTimestamp");
      setLoading(false);
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  console.log("handleLoginSuccess:", handleLoginSuccess);
  const [redirectTo, setRedirectTo] = useState(null);

  // Logout user if InActive
  const handleLogout = async () => {
    try {
      const response = await signOut();
      console.log("signout response ", response);
      localStorage.removeItem("loginTimestamp");
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const logoutUser = useCallback(() => {
    // Perform logout operations, e.g., clearing user session, redirecting to login page
    handleLogout();
    setIsLoggedIn(false);
    localStorage.removeItem("loginTimestamp");
    navigate("/signIn"); // Assuming `useNavigate` is used for navigation
  }, [navigate]);

  useEffect(() => {
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(logoutUser, 600000); // 600000 ms = 10 minutes
    };

    // Set initial timer
    resetTimer();

    // Event listeners to reset timer on user interactions
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("mousedown", resetTimer);
    window.addEventListener("keypress", resetTimer);
    window.addEventListener("touchmove", resetTimer);
    window.addEventListener("onscroll", resetTimer);

    // Cleanup function to remove event listeners
    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("mousedown", resetTimer);
      window.removeEventListener("keypress", resetTimer);
      window.removeEventListener("touchmove", resetTimer);
      window.removeEventListener("onscroll", resetTimer);
    };
  }, [logoutUser]);
  return (
    <div className="App">
      {!loading && (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/support" element={<Support />} />

          {isLoggedIn ? (
            <Route element={<DefaultLayout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/selectRecipient" element={<SelectRecipient />} />
              <Route path="/myAccount" element={<MyAccount />} />
              <Route path="/myaccountdetails" element={<MyAccountDetails />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/addRecipient" element={<AddRecipient />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/moneyCalculator" element={<MoneyCalculator />} />
              <Route path="/overView" element={<Overview />} />
              <Route path="/PaymentMethod" element={<PaymentMethod />} />
              <Route path="/fundWallet" element={<FundWallet />} />
              <Route path="/kycuser" element={<KYCUser />} />
              <Route path="/kybuser" element={<KYB />} />

              <Route
                path="/selectEditRecipient"
                element={<SelectEditRecipient />}
              />
              <Route path="/editRecipient" element={<EditRecipient />} />
              <Route
                path="/interectPaymentDetails"
                element={<InterectPaymentDetails />}
              />

              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Route>
          ) : (
            <>
              <Route
                path="/signIn"
                element={<SignIn onLoginSuccess={handleLoginSuccess} />}
              />
              <Route
                path="/signup"
                element={<SignUp onLoginSuccess={handleLoginSuccess} />}
              />
              <Route path="/kyc" element={<Kyc />} />
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route path="/verifyEmail" element={<VerifyEmail />} />
              <Route path="/changePassword" element={<ChangePassword />} />
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route path="*" element={<Navigate to="/" />} />

              {/* <Route/> */}
            </>
          )}
        </Routes>
      )}
    </div>
  );
}

export default App;
