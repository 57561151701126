import React from "react";
import { Layout } from "antd";
const { Header, Content, Footer, Sider } = Layout;

const Footerpage = () => {
  const date = new Date();

  return (
    <Layout>
      <Footer style={{ textAlign: "center"}}>
        Copyright © {date.getFullYear()}  All Right Reserved.
      </Footer>
    </Layout>
  );
};
export default Footerpage;
