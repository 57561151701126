// created by Honey

import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Layout,
  theme,
  Cascader,
  Input,
  Card,
  Select,
  message,
} from "antd";
import { format } from "date-fns";

import FooterComponent from "../Components/FooterComponent";
import HeaderComponent from "../Components/HeaderComponent";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getTableID } from "../Services/authServices";
import { generateClient } from "aws-amplify/api";
import * as mutations from "../graphql/mutations";
const { Option } = Select;
const { Content } = Layout;
const { TextArea } = Input;
const Support = () => {
  const navigate = useNavigate(); // Use the useNavigate hook for navigation

  const { t, i18n } = useTranslation();
  const [transactionStatus, setTransactionStatus] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const [showResult, setShowResult] = useState("none");
  const API = generateClient();
  const [trackingNo, setTrackingNo] = useState(""); // State to hold input value
  const [form] = Form.useForm();
  const [alert, setAlert] = useState({ type: "", message: "", show: false });
  const [messageApi, contextHolder] = message.useMessage();

  const handleInputChange = (event) => {
    const { value } = event.target;
    setTrackingNo(value);

    // If the input is cleared, hide the result
    if (value === "") {
      setShowResult("none");
    }
    setTrackingNo(event.target.value); // Update the trackingNo state with input value
  };

  async function fetchRecord() {
    if (trackingNo !== "") {
      try {
        const query = `query MyQuery {
          listTransactions(filter: {trackingID: {eq: "${trackingNo}"}}, limit: 1000) {
            items {
              transactionStatus
              transactionDate
              trackingID
            }
          }
        }`;
        const response = await API.graphql({ query: query });
        const transaction = response.data.listTransactions.items[0];
        const formattedDate = format(
          new Date(transaction.transactionDate),
          "MMMM dd, yyyy hh:mm:ss a"
        );

        setTransactionStatus(transaction.transactionStatus);
        setTransactionDate(formattedDate);
        setShowResult("block");
      } catch (error) {
        console.log("Error - listTransactions -  ", error);
      }
    } else {
      setShowResult("none");
    }
  }
  const info = () => {
    messageApi.info("Hello, Ant Design!");
  };
  const contentList = [
    {
      content: "support.content1",
    },
    {
      content: "support.content2",
    },
    {
      content: "support.content3",
    },
  ];
  const MyFormItemContext = React.createContext([]);
  function toArr(str) {
    return Array.isArray(str) ? str : [str];
  }
  const MyFormItem = ({ name, disabled, ...props }) => {
    const prefixPath = React.useContext(MyFormItemContext);
    const concatName =
      name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;

    return <Form.Item name={concatName} {...props} />;
  };

  const onFinish = async (values) => {
    const input = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      message: values.messages,
      //status: values.state,
    };
    try {
      const onCreateContactForma = await API.graphql({
        query: mutations.createContactForm,
        variables: { input },
      });

      message.success("Form submitted successfully!");

      navigate("/"); // Navigate upon successful creation
    } catch (err) {
      console.error("Error onCreateContactForma:", err);
    }
  };

  return (
    <Layout>
      <HeaderComponent />
      <Content
        className="site-layout"
        style={{
          padding: "2rem",
          minHeight: "calc(100vh - 200px)",
          backgroundColor: "#e0f7f9",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1
         className="heading"
         style={{
         fontWeight: "bold",
           color: "#669999",
           fontFamily:'Gopher'
         }}
          >
            {t("support.title")}
          </h1>
        </div>
        <div>
          <div style={{ marginBottom: 20 }}>
            <div className="trackingInfo">
              <p style={{ fontFamily: "Gopher" }}>{t("support.main")}</p>
            </div>
            {contentList.map((item, i) => (
              <p style={{ fontFamily: "Gopher" }} key={i}>
                {t(item.content)}
              </p>
            ))}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <Input
                style={{
                  height: "50px",
                  maxWidth: "20rem",
                  fontFamily: "Gopher",
                  marginRight: 10, // Add some right margin for spacing between Input and Button
                }}
                value={trackingNo} // Bind value to the trackingNo state
                onChange={handleInputChange} // Handle input changes
                placeholder={t("Transcation.trackingID")}
              />
              <Form.Item style={{ margin: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#00a99d",
                    borderRadius: "10px",
                    width: "10rem",
                    height: "3rem",
                    fontFamily: "Gopher",
                  }}
                  onClick={fetchRecord}
                >
                  {t("contact.Submit")}
                </Button>
              </Form.Item>
            </div>
          </div>
          <div
            style={{
              marginBottom: "1rem",
              marginTop: "1rem",
              display: showResult,
            }}
            className="trackingResult"
          >
            <p>Status : {transactionStatus}</p>
            <p>Date : {transactionDate}</p>
          </div>
          <div>
            <p style={{ fontFamily: "Gopher" }}>{t("support.text")}</p>
          </div>
          <div className="inquiryDiv" style={{ marginTop: 25 }}>
            <div className="inquiryInfo">
              <h2 style={{ fontFamily: "Gopher" }}>{t("contact.title")}</h2>
              <p style={{ maxWidth: "80%", fontFamily: "Gopher" }}>
                {t("contact.main")}
              </p>
              <p style={{ fontWeight: "bold", fontFamily: "Gopher" }}>
                {t("contact.contactTitle")}
              </p>
              <p style={{ fontFamily: "Gopher" }}> {t("contact.email")}</p>
              <p style={{ fontFamily: "Gopher" }}>{t("contact.live")}</p>
              <div>
                <p style={{ fontFamily: "Gopher" }}>{t("contact.hours")}</p>
                <p style={{ fontFamily: "Gopher" }}>{t("contact.hours1")}</p>
                <p style={{ fontFamily: "Gopher" }}>{t("contact.hours2")}</p>
              </div>
              <p style={{ fontFamily: "Gopher" }}>{t("contact.feedback")}</p>
              <p style={{ fontFamily: "Gopher" }}>
                {t("contact.feedbackContent")}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "70%",
              }}
            >
              <Card
                title="Send Message"
                bordered={false}
                style={{ width: "70%", fontFamily: "Gopher" }}
              >
                <Form
                  layout="vertical"
                  onFinish={onFinish}
                  form={form}
                  initialValues={{}}
                >
                  <MyFormItem
                    name="firstName"
                    style={{ marginBottom: 0, fontFamily: "Gopher" }}
                    rules={[
                      { required: true, message: "Please input Last Name" },
                    ]}
                  >
                    <Input placeholder={t("EditRecipient.FirstName")} />
                  </MyFormItem>

                  <MyFormItem
                    name="lastName"
                    style={{
                      marginBottom: 0,
                      width: "100%",
                      fontFamily: "Gopher",
                    }}
                    rules={[
                      { required: true, message: "Please input Last Name" },
                    ]}
                  >
                    <Input
                      placeholder={t("EditRecipient.LastName")}
                      style={{ marginTop: "1rem", fontFamily: "Gopher" }}
                    />
                  </MyFormItem>

                  <MyFormItem
                    style={{ marginBottom: 0, fontFamily: "Gopher" }}
                    name="email"
                    rules={[{ required: true, message: "Please input email" }]}
                  >
                    <Input
                      placeholder="Email"
                      style={{ marginTop: "1rem", fontFamily: "Gopher" }}
                    />
                  </MyFormItem>

                  <MyFormItem
                    style={{ marginBottom: 0, fontFamily: "Gopher" }}
                    name="PhoneNumber"
                    rules={[
                      { required: false, message: "Please input Last Name" },
                    ]}
                  >
                    <Input
                      placeholder={t("register.PhoneNumbers")}
                      allowClear
                      style={{ marginTop: "1rem", fontFamily: "Gopher" }}
                    />
                  </MyFormItem>
                  <MyFormItem
                    style={{ marginBottom: 0, fontFamily: "Gopher" }}
                    name="messages"
                    rules={[
                      { required: false, message: "Please input messages" },
                    ]}
                  >
                    <TextArea
                      placeholder={t("SendMoney.msg")}
                      allowClear
                      style={{ marginTop: "1rem", fontFamily: "Gopher" }}
                    />
                  </MyFormItem>

                  <Form.Item style={{ marginTop: 20 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        backgroundColor: "#00a99d",
                        borderRadius: "15px",
                        width: "10rem",
                        height: "3rem",
                        fontFamily: "Gopher",
                      }}
                    >
                      {t("contact.Submit_form")}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Content>
      <FooterComponent />
    </Layout>
  );
};
export default Support;
