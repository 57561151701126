import React, { useEffect, useState } from "react";
import { Content } from "antd/es/layout/layout";
import "./style.css";
import {
  Input,
  Form,
  Button,
  DatePicker,
  Row,
  Col,
  Avatar,
  Upload,
  message,
  Modal,
} from "antd";
import { generateClient } from "aws-amplify/api";
import { getUserInfo, getTableID } from "../Services/authServices";
import {
  UserOutlined,
  CheckCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import * as mutations from "../graphql/mutations";
import { useTranslation } from "react-i18next";
import { uploadData, getUrl } from "aws-amplify/storage";
import { deleteUser, signOut } from "aws-amplify/auth";

const MyAccount = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const API = generateClient();
  const [form] = Form.useForm();
  const [name, setName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteTxt, setDeleteTxt] = useState("");
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    fetchProfilePic();
    const fetchUserInformation = async () => {
      const tableID = await getTableID();
      const status = await getUserInfo(tableID);
      console.log("status", status);
      setName(status.name);
      setPhoneNumber(status.phoneNumber);
      form.setFieldsValue({
        name: status.name,
        phoneNumber: status.phoneNumber,
      });
    };
    fetchUserInformation();
  }, []);

  const [userImage, setUserImage] = useState("");

  const onFinish = async (values) => {
    const tableID = await getTableID();
    if (!tableID) {
      console.error("Table ID is not available.");
      return;
    }

    try {
      await API.graphql({
        query: mutations.updateUser,
        variables: {
          input: {
            id: tableID,
            name: values.name,
            phoneNumber: values.phoneNumber,
          },
        },
      });
      navigate("/dashboard");
    } catch (err) {
      console.error("Error updating user:", err);
      // Set the error message
      setErrorMessage("Invalid number");
    }
  };

  // --------------------------------- Delete Account ------------------------------------
  const handleLogout = async () => {
    try {
      const response = await signOut();
      console.log("signout response ", response);
      localStorage.removeItem("loginTimestamp");
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  async function handleDeleteUser() {
    try {
      await deleteUser();
      await handleLogout();
    } catch (error) {
      console.log(error);
    }
  }

  const handleDeleteAccount = () => {
    if (deleteTxt !== "delete") {
      // If the input doesn't match "delete", show an error message
      message.error("Please type 'delete' to confirm account deletion.");
      return;
    }

    console.log("Delete Account");
    closeDeleteModal();
    handleDeleteUser();
  };
  const closeDeleteModal = () => {
    setDeleteTxt("");
    setIsModalVisible(false);
    handleDeleteUser();
  };
  // --------------------------------- Fetch Profile ------------------------------------
  const fetchProfilePic = async () => {
    try {
      const tableID = await getTableID();
      const awskey = `UserProfilePictures/${tableID}/profile`;
      const getUrlResult = await getUrl({
        key: awskey,
        options: {
          accessLevel: "guest",
          targetIdentityId: "XXXXXXX", // Ensure this is correctly configured
          validateObjectExistence: true,
          expiresIn: 900,
          useAccelerateEndpoint: false,
        },
      });
      setUserImage(getUrlResult.url.toString()); // Update state with the signed URL
    } catch (Error) {
      console.error("Error downloading pic ", Error);
    }
  };

  // --------------------------------- Upload New Profile Pic ------------------------------------
  const uriToBlob = (uri: string): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      // If successful -> return with blob
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function () {
        reject(new Error("uriToBlob failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });
  };

  const uploadProfilePicture = async (file) => {
    try {
      const tableID = await getTableID();
      const fullKey = `UserProfilePictures/${tableID}/profile`;
      const contentType = file.type;

      const result = await uploadData({
        key: fullKey,
        data: file,
        options: {
          level: "guest",
          contentType: contentType,
        },
      });

      console.log("Succeeded: ", result);
      fetchProfilePic();
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      <div
        className="recMainDiv"
        style={{ textAlign: "center", maxWidth: "600px", width: "100%" }}
      >
        <h1 style={{ color: "#3D49AB" ,fontFamily: 'Gopher'}}>{t("dashboard.MyAccount")}</h1>

        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Avatar
            size={100}
            src={userImage} // Use the state variable that holds the signed URL
            icon={!userImage && <UserOutlined style={{ fontSize: "45px" }} />}
            style={{ border: "5px solid #3D49AB" }}
          />
          <Upload
            customRequest={() => {}}
            showUploadList={false}
            beforeUpload={(file) => {
              // Call the uploadProfilePicture function when a new image is uploaded
              uploadProfilePicture(file);
              return false;
            }}
          >
            <Button icon={<UploadOutlined />} style={{ marginTop: "15px",fontFamily: 'Gopher' }}>
              {t("profile.uploadimage")}
            </Button>
          </Upload>
        </div>

        <Form
          form={form}
          name="dynamic_form_item"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ name: name, phoneNumber: phoneNumber }}
          style={{ marginTop: "20px" ,fontFamily:'Gopher'}}
        >
          <Form.Item
            name="name"
            label={t("profile.preferName")}
            rules={[{ required: true, message: "Please input your name" }]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label={t("register.PhoneNumbers")}
            rules={[
              { required: true, message: "Please input your phone number" },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <Form.Item>
            <Button type="primary" htmlType="submit" className="signupbtn">
              {t("profile.update")}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              danger
              onClick={() => setIsModalVisible(true)}
            >
              Delete Account
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        title="Confirm Account Deletion"
        visible={isModalVisible}
        onOk={handleDeleteAccount}
        onCancel={() => setIsModalVisible(false)}
      >
        <p style={{fontFamily:'Gopher'}}>
          Please type <strong>"delete"</strong> to confirm the deletion of your
          account:
        </p>
        <Input
          value={deleteTxt}
          onChange={(e) => setDeleteTxt(e.target.value)}
        />
      </Modal>
    </Content>
  );
};

export default MyAccount;
