import { Content } from "antd/es/layout/layout";
import React, { useState, useEffect } from "react";
import { SearchOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { Input, List, Skeleton, Button, Avatar } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import * as queries from "../graphql/queries";
import { getTableID } from "../Services/authServices";
import { useTranslation } from "react-i18next";
import { colors } from "../styles/colors";
const SelectRecipient = () => {
  const { t, i18n } = useTranslation();

  const API = generateClient();
  const [recipients, setRecipients] = useState([]);
  const navigate = useNavigate();
  const handleRecipientClick = (recipient) => {
    navigate("/moneyCalculator", { state: { recipient } });
  };

  useEffect(() => {
    const fetchRecipients = async () => {
      try {
        const MyQuery = `
          query GetRecipientsByUser($id: ID!) {
            getUser(id: $id) {
              Recipients {
                items {
                  addressState
                  email
                  firstName
                  lastName
                  id
                  phoneNumber
                  reasonSending
                  recipientDeliveryMethod
                  userID
                  addressStreet
                  addressCity
                  isRecipientVerified
                  addressCountry
                  typeOfRecipient
                  addressZipCode
                }
              }
            }
          }
        `;
        const tableID = await getTableID();

        if (!tableID) {
          console.error("Table ID is not available.");
          return;
        }
        const userData = await API.graphql({
          query: MyQuery,
          variables: { id: tableID },
        });
        let recipientList = userData.data.getUser.Recipients.items;

        // Sort recipients alphabetically by firstName, then by lastName
        recipientList.sort((a, b) => {
          const nameA = `${a.firstName || ""} ${
            a.lastName || ""
          }`.toLowerCase();
          const nameB = `${b.firstName || ""} ${
            b.lastName || ""
          }`.toLowerCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

        setRecipients(recipientList);
      } catch (err) {
        console.error("Error fetching recipients:", err);
      }
    };
    fetchRecipients();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredRecipients = recipients.filter((recipient) => {
    const firstName = recipient.firstName || "";
    const lastName = recipient.lastName || "";
    return (
      firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
     
      }}
    >
      <div className="recMainDiv">
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="recHeadingDiv"
        >
          <h1 style={{ color: colors.primary , fontFamily:'Gopher'}}>
            {t("dashboard.SelectTheRecipient")}
          </h1>
        </div>
        <hr />
        <Input
          allowClear={true}
          style={{ marginTop: "1rem", fontFamily:'Gopher' }}
          size="large"
          className="custom-input"
          value={searchTerm}
          onChange={handleSearch}
          placeholder={t("dashboard.SearchRecipient")}
          prefix={<SearchOutlined style={{ color: colors.primary}} />}
        />

        <div
          style={{
            height: "50vh",
            overflowY: "auto",
            marginTop: "2rem",
          }}
        >
          <List
            size="large"
            bordered={false}
            dataSource={filteredRecipients}
            renderItem={(recipient) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                  marginBottom: "1rem",
                  padding: "10px",
                  cursor: "pointer",
                  fontFamily:'Gopher'
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor =  colors.secondary)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "inherit")
                }
                onClick={() => handleRecipientClick(recipient)} // Update here to use the click handler
              >
                <Avatar
                  src={`https://api.dicebear.com/7.x/initials/svg?seed=${recipient.firstName} ${recipient.lastName}`}
                  style={{ marginTop: "0.2rem" }} // Add some margin to separate the avatar from the name
                />
                <List.Item  className="list-item-text" style={{ width: "95%", fontSize: "16px" }}>
                  {`${recipient.firstName} ${recipient.lastName}`}
                </List.Item>

                {/* <RightOutlined style={{ color: "#3D49AB" }} /> */}
              </div>
            )}
            style={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "10px",
              fontFamily:'Gopher'
            }}
          />
        </div>
        <Link to="/addRecipient">
          <Button
            icon={<PlusOutlined style={{ color: colors.primary}} />}
            style={{
              marginTop: "2rem",
              width: "100%",
              color:  colors.primary,
              border: "1px solid #00515c",
              fontFamily:'Gopher'
            }}
            size="large"
          >
            {t("dashboard.AddNewRecipient")}
          </Button>
        </Link>
      </div>
    </Content>
  );
};
export default SelectRecipient;
