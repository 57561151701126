// colors.ts
export const colors = {
  primary: '#00515c',
  secondary: '#00a99d',
  background: '#fcee21',
  background1: '#e0f7f9',
  success: '#28a745',
  danger: '#dc3545',
  warning: '#ffc107',
  info: '#17a2b8',
  light: '#f8f9fa',
  dark: '#343a40',
  darkGray: '#333333',
  white: '#ffffff',
  yellow: '#e6bb00',

  black: '#000000',
  darkRed_2: '#B10011',
  darkRed_3: '#682229',
  darkOrange_1: '#FF8000',
  darkOrange_2: '#D16D08',
  darkOrange_3: '#BF7100',
  darkYellow_1: '#CB9E17',
  darkYellow_2: '#9F7A0C',
  darkYellow_3: '#634A00',
  darkGreen_1: '#118C2D',
  darkGreen_2: '#08681E',
  darkGreen_3: '#043C10',
  darkBlue_1: '#028FDF',
  darkBlue_2: '#004A74',
  darkBlue_3: '#002B43',
  purple: '#7600E5',
  darkPurle_1: '#5F00B8',
  darkPurle_2: '#430081',
  darkPurle_3: '#250048',
  lightGray: '#949494',
};
