import React, { useEffect, useState } from "react";
import { Content } from "antd/es/layout/layout";
import "./style.css";
import {
  Input,
  Form,
  Button,
  DatePicker,
  Row,
  Col,
  Avatar,
  Upload,
} from "antd";
import { UserOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { generateClient } from "aws-amplify/api";
import { getUserInfo, getTableID } from "../Services/authServices";
import { useNavigate } from "react-router-dom";
import * as mutations from "../graphql/mutations";
import { useTranslation } from "react-i18next";
import { uploadData, getUrl } from "aws-amplify/storage";
import moment from "moment";

const MyAccountDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const API = generateClient();
  const [form] = Form.useForm();
  const [user, setUser] = useState();
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    transactionLimit: "",
  });

  const [transactionLimit, setTransactionLimit] = useState("");
  const [userImage, setUserImage] = useState("");
  console.log("userImage", userImage);
  useEffect(() => {
    fetchProfilePic();
    const fetchUserInformation = async () => {
      const tableID = await getTableID();
      const status = await getUserInfo(tableID);
      console.log("status", status);
      setUserInfo({
        ...userInfo,
        firstName: status.firstName,
        lastName: status.lastName,
        email: status.email,
        phoneNumber: status.phoneNumber,
        dateOfBirth: status.dateOfBirth,
        businessName: status.businessName,
        remainingTransferLimit: status.remainingTransferLimit,
        businessReferenceNumber: status.businessReferenceNumber,
        remitanceLimit: status.remitanceLimit,
        isOrganization: status.isOrganization,
        identificationVerified: status.identificationVerified,
      });

      const combinedLimit = `$${status.remainingTransferLimit.toFixed(
        2
      )} / $${status.remitanceLimit.toFixed(2)}`;
      setTransactionLimit(combinedLimit); // Set it in state
      form.setFieldsValue({
        firstName: status.firstName,
        lastName: status.lastName,
        email: status.email,
        phoneNumber: status.phoneNumber,
        dateOfBirth: moment(status.dateOfBirth),
        remainingTransferLimit: status.remainingTransferLimit,
        transactionLimit: combinedLimit,
        identificationVerified: status.identificationVerified,
      });
    };
    fetchUserInformation();
  }, []);

  const fetchProfilePic = async () => {
    try {
      const tableID = await getTableID();
      const awskey = `UserProfilePictures/${tableID}/profile`;
      const getUrlResult = await getUrl({
        key: awskey,
        options: {
          accessLevel: "guest", // can be 'private', 'protected', or 'guest' but defaults to `guest`
          // @ts-ignore
          targetIdentityId: "XXXXXXX", // id of another user, if `accessLevel` is `guest`
          validateObjectExistence: true, // defaults to false
          expiresIn: 900, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
          useAccelerateEndpoint: false, // Whether to use accelerate endpoint.
        },
      });
      console.log("signed URL: ", getUrlResult.url);
      console.log("URL expires at: ", getUrlResult.expiresAt);
      setUserImage(getUrlResult.url.toString());
    } catch (Error) {
      console.log("Error downloading pic ", Error);
    }
  };
  const goToMyAccountPage = () => {
    navigate("/myAccount"); // Use the path to your My Account page
  };

  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        flexDirection: "column",
        padding: "1rem",
      }}
    >
      <div
        className="recMainDiv"
        style={{ textAlign: "center", maxWidth: "600px", width: "100%" }}
      >
        <h1 style={{ color: "#3D49AB" ,fontFamily: 'Gopher'}}>{t("dashboard.MyAccount")}</h1>

        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Avatar
            size={100}
            src={userImage}
            icon={!userImage && <UserOutlined style={{ fontSize: "45px" }} />}
            style={{ border: "5px solid #3D49AB" }}
          />
          {/* Conditional rendering for the verification tick */}
          {userInfo.identificationVerified && (
            <CheckCircleOutlined
              style={{
                color: "green",
                fontSize: "24px",
                position: "relative",
                top: "-25px",
                right: "-30px",
                backgroundColor: "white",
                borderRadius: "50%",
              }}
            />
          )}
          {/* Conditional rendering for account type */}
          <span style={{ fontSize: "18px", fontWeight: "500",fontFamily:'Gopher' }}>
            {userInfo.isOrganization ? "Business Account" : "Personal Account"}
          </span>
        </div>

        <Form
          form={form}
          name="dynamic_form_item"
          layout="vertical"
          initialValues={userInfo}
          style={{ marginTop: "20px" }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
              style={{fontFamily:'Gopher'}}
                name="firstName"
                label="Legal First Name"
                rules={[
                  { required: true, message: "Please input your first name" },
                ]}
              >
                <Input
                  size="large"
                  disabled
                  className="custom-disabled-input"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                 style={{fontFamily:'Gopher'}}
                name="lastName"
                label="Legal Last Name"
                rules={[
                  { required: true, message: "Please input your last name" },
                ]}
              >
                <Input
                  size="large"
                  disabled
                  className="custom-disabled-input"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
             style={{fontFamily:'Gopher'}}
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please input your email",
                type: "email",
              },
            ]}
          >
            <Input size="large" disabled className="custom-disabled-input" />
          </Form.Item>

          <Form.Item
             style={{fontFamily:'Gopher'}}
            name="phoneNumber"
            label="Phone Number"
            rules={[
              { required: true, message: "Please input your phone number" },
            ]}
          >
            <Input size="large" disabled className="custom-disabled-input" />
          </Form.Item>

          <Form.Item
             style={{fontFamily:'Gopher'}}
            name="dateOfBirth"
            label="Date of Birth"
            rules={[
              { required: true, message: "Please input your date of birth" },
            ]}
          >
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              disabled
              className="custom-disabled-input"
            />
          </Form.Item>

          <Form.Item name="transactionLimit" label="Transaction Limit"    style={{fontFamily:'Gopher'}}>
            <Input size="large" disabled className="custom-disabled-input" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              className="signupbtn"
              onClick={goToMyAccountPage} // Use the onClick event for navigation
            >
              {t("SendMoney.Edit")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Content>
  );
};

export default MyAccountDetails;
