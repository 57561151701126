import { Footer } from "antd/es/layout/layout";
import React from "react";
import logo from "../Image/logo.png";
import { Button, Input, Row, Col, Form, Checkbox, Typography } from "antd";
import { Link } from "react-router-dom";
import "./style.css";
const VerifyEmail = () => {
  return (
    <div
      style={{
        paddingTop: 50,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img src={logo} alt="Logo" style={{ maxWidth: "10%", height: "10%" }} />
        <h2>Verify It's You</h2>
        <p style={{ textAlign: "center" }}>
          Get Verified with an email sent to : {"abc@gmail.com"}
        </p>
        <Link to="/changePassword">
          <Button
            className="signupbtn"
            style={{ maxWidth: "40%", height: "10%" }}
          >
            Verify Email
          </Button>
        </Link>
      </div>
    </div>
  );
};
export default VerifyEmail;
