import React, { useEffect, useState } from "react";
import { Divider, Card, List, Avatar, Button, Skeleton } from "antd";
import { Content } from "antd/es/layout/layout";
import canadaFlag from "../Image/ca-circle.png";
import logo from "../Image/logo.png";
import axios from "axios";
import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchUserAttributes } from "@aws-amplify/auth";
import * as queries from "../graphql/queries";
import {
  SendOutlined,
  WalletOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
} from "@ant-design/icons";
import {
  getTableID,
  getUserVerificationStatus,
} from "../Services/authServices";
import { generateClient } from "aws-amplify/api";
import { useTranslation } from "react-i18next";

const Wallet = () => {
  const API = generateClient();
  const navigate = useNavigate();
  const [wallet, setWallet] = useState([]);
  const [walletHistory, setWalletHistory] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState([]); // State to hold the current page's data
  const [combinedDataSorted, setCombinedDataSorted] = useState([]);
  const pageSize = 5; // Number of items per page
  const { t, i18n } = useTranslation();

  const [verificationStatus, setVerificationStatus] = useState({
    identificationVerified: false,
    isOrganization: false,
    verificationOption: null,
  });

  useEffect(() => {
    const fetchVerificationStatus = async () => {
      const tableID = await getTableID();

      const status = await getUserVerificationStatus(tableID);

      //console.log("verification status: ", status);
      if (status) {
        setVerificationStatus(status);
        if (status.identificationVerified) {
          console.log("User is already verified");
        } else {
          console.log("User is already verified");
        }
      }
    };

    fetchVerificationStatus();
  }, []);

  function formatNumber(number, decimalPlaces = 3) {
    return number.toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  }

  const handleSubmit = async () => {
    if (verificationStatus.isOrganization) {
      navigate("/kybuser");
    } else {
      navigate("/kycuser");
    }
  };

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const MyQuery = `
          query GetWalletByUser($id: ID!) {
            getUser(id: $id) {
                Wallets {
                    items {
                      availableBalance
                      id
                      userID
                    }
                }
            }
          }
        `;
        const tableID = await getTableID();

        if (!tableID) {
          console.error("Table ID is not available.");
          return;
        }
        const userData = await API.graphql({
          query: MyQuery,
          variables: { id: tableID },
        });
        const walletDetails = userData.data.getUser.Wallets.items[0] || {
          availableBalance: 0,
        };
        setWallet(walletDetails);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching wallet:", err);
      }
    };
    const fetchWalletHistory = async () => {
      try {
        const tableID = await getTableID(); // Ensure you have this function implemented

        if (!tableID) {
          console.error("Table ID is not available.");
          return;
        }

        const MyQuery = `
            query GetWalletHistory($id: ID!) {
              getUser(id: $id) {
                AddFundWallets(sortDirection: ASC) {
                  items {
                    status
                    requestDate
                    paymentMethod
                    completionDate
                    amountCAD
                  }
                }
              }
            }
          `;

        const userData = await API.graphql({
          query: MyQuery,
          variables: { id: tableID },
        });
        console.log("asd", userData);
        const historyItems = userData.data.getUser.AddFundWallets.items.map(
          (item) => ({
            ...item,
            amountCAD: parseFloat(item.amountCAD).toFixed(2), // Round amountCAD to 2 decimal points
          })
        );

        console.log("historyItems", historyItems);
        setWalletHistory(historyItems);
      } catch (err) {
        console.error("Error fetching wallet history:", err);
      }
    };

    const fetchTransactionHistory = async () => {
      try {
        const tableID = await getTableID(); // Your logic to get the table ID

        const MyQuery = `
          query GetTransactionsHistory($id: ID!) {
            getUser(id: $id) {
              Transactions(filter: { RecievedAmountMethod: { eq: "wallet" } }, sortDirection: ASC) {
                items {
                  ReceivedAmountCAD
                  RecievedAmountMethod
                  recipientFirstName
                  recipientLastName
                  transactionDate
                  transactionStatus
                }
              }
            }
          }
        `;

        const userData = await API.graphql({
          query: MyQuery,
          variables: { id: tableID },
        });

        const transactionItems = userData.data.getUser.Transactions.items;
        console.log("transactionItems", transactionItems);
        setTransactionHistory(transactionItems); // Update your state with the fetched data
      } catch (err) {
        console.error("Error fetching transaction history:", err);
      }
    };

    fetchWallet();
    fetchTransactionHistory();
    fetchWalletHistory();
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      // Assuming fetchWalletHistory and fetchTransactionHistory have already been called
      // and walletHistory and transactionHistory states are set
      const combinedData = [...walletHistory, ...transactionHistory];

      // Sort combined data by latest to oldest date
      combinedData.sort((a, b) => {
        const dateA = new Date(a.requestDate || a.transactionDate);
        const dateB = new Date(b.requestDate || b.transactionDate);
        return dateB.getTime() - dateA.getTime(); // Ensure sorting by timestamp
      });

      // Store the combined and sorted data for future pagination
      setCombinedDataSorted(combinedData); // Store the full sorted list
      setPageData(combinedData.slice(0, pageSize)); // Initialize with first page data
    };

    fetchAllData();
  }, [walletHistory, transactionHistory]); // Re-run when walletHistory or transactionHistory changes

  const handlePageChange = (page) => {
    // Calculate start and end indices for the current page
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Use the combinedDataSorted for pagination
    setPageData(combinedDataSorted.slice(startIndex, endIndex)); // Update the data for the current page
  };

  const fundWallet = () => {
    navigate("/fundWallet", { state: { wallet } });
  };
  return (
    <Content>
      <div>
        <Divider orientation="left">
          <span style={{ fontSize: "22px" ,fontFamily:'Gopher'}}> {t("dashboard.Wallet")}</span>
        </Divider>
        <div className="card-container">
          {loading ? (
            <Skeleton active />
          ) : (
            <Card className="customCard" style={{}}>
              <div
                style={{
                  alignSelf: "flex-start",
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                }}
              >
                <img
                  src={canadaFlag}
                  alt="Canadian Flag"
                  style={{
                    width: "2.7rem", // Increased width to fill the circle
                    height: "2.5rem", // Increased height to fill the circle
                    borderRadius: "100%",
                    objectFit: "cover",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </div>
              <h1 style={{ marginTop: "-1rem",fontFamily:'Gopher' }}>
                {" "}
                {t("dashboard.CurrentBalance")}
              </h1>
              <h1 style={{ margin: "0",fontFamily:'Gopher' }}>
                ${formatNumber(wallet.availableBalance || 0, 2)}
              </h1>
              <div
                style={{
                  alignSelf: "flex-start",
                  width: "100%",
                }}
              >
                {wallet.id ? (
                  <p
                    style={{
                      marginTop: "3rem",
                      fontSize: "0.75rem", // Smaller font size
                      fontWeight: "400", // Adjust font weight if needed
                      whiteSpace: "nowrap", // Prevents the text from wrapping
                      overflow: "hidden", // Hides overflowed text
                      textOverflow: "ellipsis", // Adds ellipsis to overflowed text
                      maxWidth: "90%", // Ensure the max width to prevent overflow, adjust as needed
                      fontFamily:'Gopher'
                    }}
                  >
                    {wallet.id}
                  </p>
                ) : (
                  <p
                    style={{
                      marginTop: "3rem",
                      fontSize: "1rem", // Adjusted font size
                      fontWeight: "bold", // Bold font weight
                      color: "yellow", // Red color for attention
                      textAlign: "center", // Centered text
                      fontFamily:'Gopher'
                    }}
                    onClick={handleSubmit}
                  >
                    Click Here To Complete KYC/KYB to activate your wallet.
                  </p>
                )}
              </div>
            </Card>
          )}
          <div className="action-cards">
            <Card
              onClick={wallet.id ? fundWallet : undefined}
              style={{
                cursor: wallet.id ? "pointer" : "not-allowed",
                width: "10rem",
                height: "10rem",
                display: "flex",
                flexDirection: "column",
                borderWidth: ".2rem",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "1.5rem",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s, transform 0.3s",
                fontFamily:'Gopher'
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#1d8896";
                e.currentTarget.querySelector(".icon").style.color = "#FFFFFF";
                e.currentTarget.querySelector(".text").style.color = "#FFFFFF"; // Change text color on hover
                e.currentTarget.querySelector(".icon").style.fontSize = "4rem"; // Increase icon size on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#FFFFFF";
                e.currentTarget.querySelector(".icon").style.color = "#000000";
                e.currentTarget.querySelector(".text").style.color = "#000000"; // Reset text color on hover out
                e.currentTarget.querySelector(".icon").style.fontSize = "3rem"; // Reset icon size on hover out
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <WalletOutlined
                  className="icon"
                  style={{ fontSize: "3rem", transition: "font-size 0.3s" }}
                />
                <span
                  className="text"
                  style={{ marginTop: "0.5rem", transition: "color 0.3s",fontFamily:'Gopher' }}
                >
                  {t("dashboard.AddFund")}
                </span>
              </div>
            </Card>

            <Card
              onClick={() => wallet.id && navigate("/selectRecipient")}
              style={{
                width: "10rem",
                height: "10rem",
                display: "flex",
                borderWidth: ".2rem",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "1.5rem",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                cursor: wallet.id ? "pointer" : "not-allowed",
                transition: "background-color 0.3s, transform 0.3s",
                fontFamily:'Gopher'
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#1d8896";
                e.currentTarget.querySelector(".icon").style.color = "#FFFFFF";
                e.currentTarget.querySelector(".text").style.color = "#FFFFFF"; // Change text color on hover
                e.currentTarget.querySelector(".icon").style.fontSize = "4rem"; // Increase icon size on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#FFFFFF";
                e.currentTarget.querySelector(".icon").style.color = "#000000";
                e.currentTarget.querySelector(".text").style.color = "#000000"; // Reset text color on hover out
                e.currentTarget.querySelector(".icon").style.fontSize = "3rem"; // Reset icon size on hover out
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SendOutlined
                  className="icon"
                  style={{ fontSize: "3rem", transition: "font-size 0.3s" }}
                />
                <span
                  className="text"
                  style={{ marginTop: "0.5rem", transition: "color 0.3s",fontFamily:'Gopher' }}
                >
                  {t("dashboard.SendMoney")}
                </span>
              </div>
            </Card>
          </div>
        </div>
      </div>

      {/* <Button type="primary" onClick={() => triggerLambda()}>
        Test Lambda Trigger
      </Button> */}
      <div style={{ display: "block", flexDirection: "column" }}>
        <Divider orientation="left" style={{ marginTop: "2rem" }}>
          <span style={{ fontSize: "22px",fontFamily:'Gopher' }}>
            {t("dashboard.WalletHistory")}
          </span>
        </Divider>
        {/* <Button
          style={{
            width: "10rem",
            float: "right",
            color: "#3D49AB",
            fontWeight: "bold",
            fontSize: "18px",
          }}
          type="text"
        >
          <span style={{ fontSize: "18px" }}> {t("dashboard.ViewAll")}</span>
        </Button> */}
        <List
          itemLayout="horizontal"
          dataSource={pageData} // Use the pageData state here
          pagination={{
            style: { paddingBottom: "1rem" },
            onChange: handlePageChange,
            pageSize: pageSize,
            total: walletHistory.length + transactionHistory.length, // Total number of items
          }}
          style={{
            backgroundColor: "white",
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            borderRadius: "1rem",
          }}
          renderItem={(item, index) => {
            // Convert requestDate to a Date object
            const date = new Date(item.requestDate || item.transactionDate);
            // Format the date and time to a more readable format in the local time zone
            const formattedDate = date.toLocaleString();

            return (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    item.amountCAD >= 0 ? (
                      <UpCircleOutlined
                        style={{ fontSize: "24px", color: "green" }}
                      />
                    ) : (
                      <DownCircleOutlined
                        style={{ fontSize: "24px", color: "red" }}
                      />
                    )
                  }
                  title={
                    <span style={{fontFamily:'Gopher'}}>
                      {" "}
                      {t("Transcation.Transactionon")}
                      {formattedDate}
                    </span>
                  }
                />
                {/* Flex container for method and status */}
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  {/* Div for displaying method in uppercase */}
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      textTransform: "capitalize",
                    }}
                  >
                    {item.paymentMethod
                      ? // If paymentMethod exists, it's from walletHistory
                        t("Transcation.Depositedvia") +
                        " " +
                        `${item.paymentMethod}`
                      : // If paymentMethod is undefined, it's from transactionHistory
                        `${item.RecievedAmountMethod} To ${
                          item.recipientFirstName || "Unknown"
                        } ${item.recipientLastName || " Unknown"}`}
                  </div>
                  {/* Div for displaying status */}
                  <div style={{ flex: 1, textAlign: "center" }}>
                    <p style={{fontFamily:'Gopher'}}>
                      {t("Transcation.status")}:{" "}
                      {item.status || item.transactionStatus}
                    </p>
                  </div>
                </div>
                {/* Amount */}
                <div style={{ textAlign: "right", minWidth: "100px" }}>
                  <p style={{fontFamily:'Gopher'}}>{`${formatNumber(
                    Number(item.amountCAD || item.ReceivedAmountCAD),
                    2
                  )} CAD`}</p>
                </div>
              </List.Item>
            );
          }}
        />
      </div>
    </Content>
  );
};
export default Wallet;
