import { Form, Input, Layout } from "antd";
import React from "react";
import "./style.css";
import { SendOutlined } from "@ant-design/icons";
import { IoIosSend } from "react-icons/io";
import { FaInstagram, FaLinkedin, FaFacebook, FaTwitter } from "react-icons/fa";
import Footerpage from "../Screens/Footerpage";
import { useTranslation } from "react-i18next";
const { Search } = Input;

const FooterComponent = () => {
  const { t, i18n } = useTranslation();
  const date = new Date();

  const menuItem = [
    {
      key: "0",
      name: "header.home",
      path: "/",
    },
    {
      key: "1",
      name: "header.about",
      path: "/about",
    },
    {
      key: "2",
      name: "header.support",
      path: "/support",
    },
  ];
  return (
    <Layout>
      <div className="footerDiv">
        <div className="footerInfoDiv">
          <p style={{ paddingRight: 20 }}>{t("footer.followUs")}</p>
          <div>
            <h3>{t("footer.follow_Us")}</h3>
            <div className="icons" style={{ display: "flex", gap: "1rem" }}>
              <FaInstagram fontSize={"16px"} />
              <FaLinkedin fontSize={"16px"} />
              <FaFacebook fontSize={"16px"} />
              <FaTwitter fontSize={"16px"} />
            </div>
          </div>
        </div>
        <div className="footerLinkDiv">
          <h3>{t("footer.UsefulLinks")}</h3>
          <hr
            style={{ width: "15%", float: "left", marginTop: "-0.5rem" }}
          />{" "}
          <br />
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            {menuItem.map((item, i) => (
              <a href={item.path} style={{ textDecoration: "none", color: "white" }}>
                {t(item.name)}
              </a>
            ))}
          </div>
        </div>
        <div>
          © {date.getFullYear()} {t("footer.copyright")}
        </div>
      </div>
    </Layout>
  );
};
export default FooterComponent;
