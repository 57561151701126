import React, { useState } from "react";
import {
  Button,
  Layout,
  Typography,
  Menu,
  Cascader,
  Drawer,
  Row,
  Col,
  Tabs,
} from "antd";
import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { updatePassword } from "@aws-amplify/auth";
import { Input, Form } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
import { useTranslation } from "react-i18next";
import { colors } from "../styles/colors";

const Settings = () => {
  const { Text } = Typography;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const isMobile = viewportWidth < 768;
  const { Header, Content, Footer, Sider } = Layout;
  const [showPassword, setShowPassword] = useState(false);
  const [newshowPassword, setNewShowPassword] = useState(false);
  const [confirmshowPassword, setConfirmShowPassword] = useState(false);
  const [otp, setOTP] = useState("");
  const [passwords, setPassword] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isForgetPassword, setIsForgetPassword] = useState(true);
  const [isVerifyOTP, setIsVerifyOTP] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setNewShowPassword(!newshowPassword);
  };
  const toggleConfPasswordVisibility = () => {
    setConfirmShowPassword(!confirmshowPassword);
  };

  const [activeTab, setActiveTab] = useState("changePassword");

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };
  const handleForgetPasswordClick = () => {
    form
      .validateFields()
      .then((values) => {
        const { email } = values;
        setEmail(email);
        handleResetPassword(email);
        // Your logic for handling the reset password process with the email
      })
      .catch((error) => {
        console.error("Validation error:", error);
        setIsError(true);
        setErrMsg("Please enter a valid email");
      });
  };

  const handleVerifyOTPClick = () => {
    setIsForgetPassword(false);
    setIsVerifyOTP(false);
    setIsChangePassword(true);
  };
  const handleResetPassword = async (username) => {
    try {
      const output = await resetPassword({ username });
      handleResetPasswordNextSteps(output, username);
    } catch (error) {
      setIsError(true);
      const message = error.toString().split(":").pop().trim();
      if (message === "Username/client id combination not found.") {
        const messages = "User Not Found";
        setIsError(true);

        setErrMsg(messages);
      } else if (
        message ===
        "Exceeded daily email limit for the operation or the account. If a higher limit is required, please configure your user pool to use your own Amazon SES configuration for sending email."
      ) {
        const messages =
          "Your daily limit exceed,Please try again after some time";
        setIsError(true);
        setErrMsg(messages);
      } else {
        console.log(message);
        setErrMsg(message);
      }
    }
  };

  function handleResetPasswordNextSteps(output, username) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(
          `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
        );
        setIsForgetPassword(false);
        setIsVerifyOTP(true);
        setIsChangePassword(false);
        //setIsOtpStage(true);
        break;
      case "DONE":
        console.log("Successfully reset password.");
        break;
    }
  }

  const handleResetPasswords = () => {
    // Get the form values when the "ForgetPassword" button is clicked
    form
      .validateFields()
      .then((values) => {
        handleConfirmResetPassword({
          username: email,
          confirmationCode: otp,
          newPassword: passwords,
        });
        // Your logic for handling the reset password process with the email
      })
      .catch((error) => {
        const message = error.toString().split(":").pop().trim();
        console.log(message);
        console.error("Validation error:", error);
        setIsError(true);
        setErrMsg("Please enter a valid email");
      });
  };

  async function handleConfirmResetPassword({
    username,
    confirmationCode,
    newPassword,
  }) {
    try {
      await confirmResetPassword({ username, newPassword, confirmationCode });
      navigate("/signIn");
    } catch (error) {
      console.log(error);
      setIsError(true);
      const message = error.toString().split(":").pop().trim();
      setErrMsg(message);
    }
  }

  return (
    <div>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          flexDirection: "column",
          padding: "1rem",
        }}
      >
        <div
          className="recMainDiv"
          style={{ textAlign: "center", maxWidth: "600px", width: "100%" }}
        >
          <h1 style={{ color: colors.primary,fontFamily:'Gopher' }}>{t("dashboard.Setting")}</h1>
          <div
            style={{
              border: "1px solid gray",
              borderColor: colors.primary,
              borderRadius: 5,
            }}
          >
            <div>
              <div style={{ display: "flex", padding: 10 }}></div>

              <div style={{ width: "90%" }}></div>
              {activeTab === "changePassword" && (
                <>
                  {isForgetPassword && (
                    <div style={{ padding: 20 }}>
                      <h1 style={{ color: colors.primary,fontFamily:'Gopher'  }}>
                        {t("ChnagePassword.ChnageMyPassword")}
                      </h1>
                      <hr />
                      <div className="form">
                        <Form
                          form={form}
                          onValuesChange={(changedValues, allValues) => {
                            // Handle changes in the form values
                            setEmail(allValues.email);
                          }}
                        >
                          <label
                            className="formlabel"
                            style={{ fontSize: "16px", color:colors.primary,fontFamily:'Gopher' }}
                          >
                            {t("EditRecipient.email")}
                          </label>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Email",
                              },
                            ]}
                          >
                            <Input
                              className="forminput"
                              placeholder={t("EditRecipient.email")}
                            />
                          </Form.Item>
                          {isError && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <Text style={{ color: "red" }}>{errMsg}</Text>
                            </div>
                          )}
                          <Button
                            className="signupbtn"
                            onClick={handleForgetPasswordClick}
                          >
                            {t("EditRecipient.Continue")}
                          </Button>
                        </Form>
                      </div>
                    </div>
                  )}
                  {isVerifyOTP && (
                    <div style={{ padding: 20 }}>
                      <h1 style={{ color:colors.primary ,fontFamily:'Gopher'}}>
                        {t("ChnagePassword.ChnagePassword")}
                      </h1>
                      <hr />
                      <div className="form">
                        <Form
                          className="form"
                          onValuesChange={(changedValues, allValues) => {
                            // Handle changes in the form values
                            setOTP(allValues.otp);
                            console.log("Changed otp", otp);
                          }}
                        >
                          <label
                            className="formlabel"
                            style={{ fontSize: "16px" ,color:colors.primary,fontFamily:'Gopher'}}
                          >
                            {t("OTP.VerifyOTP")}
                          </label>
                          <Form.Item
                            name="otp"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the OTP sent to you",
                              },
                            ]}
                            help={otpError} // Display the OTP error message
                            validateStatus={otpError ? "error" : ""} // Show validation status based on the error state
                          >
                            <Input
                              className="forminput"
                              placeholder="6-digit OTP"
                              maxLength={6}
                            />
                          </Form.Item>
                          {/* <Link to={`/resetPassword?username=${email}&otp=${otp}`}> */}
                          {isError && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <Text style={{ color: "red" ,fontFamily:'Gopher'}}>{errMsg}</Text>
                            </div>
                          )}
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="signupbtn"
                            onClick={handleVerifyOTPClick}
                          >
                            {t("OTP.VerifyOTP")}
                          </Button>
                          {/* </Link> */}
                        </Form>
                      </div>
                    </div>
                  )}
                  {isChangePassword && (
                    <div style={{ padding: 20 }}>
                      <h1 style={{ color: colors.primary , fontFamily:'Gopher'}}>
                        {t("ChnagePassword.ChnageMyPassword")}
                      </h1>
                      <hr />
                      <div>
                        <div className="form">
                          <Form
                            form={form}
                            name="dynamic_form_item"
                            layout="vertical"
                            style={{ width: "80%" }}
                            onValuesChange={(changedValues, allValues) => {
                              // Handle changes in the form values
                              setPassword(allValues.password);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <label
                                  htmlFor="name"
                                  style={{ fontWeight: "bold",color:colors.primary ,fontFamily:'Gopher'}}
                                >
                                  {t("ChnagePassword.CurrentPassword")}{" "}
                                </label>
                                <div>
                                  <Form.Item
                                    name="cdpassword"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please enter Current Password",
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="forminput"
                                      placeholder={t(
                                        "ChnagePassword.CurrentPassword"
                                      )}
                                      type={showPassword ? "text" : "password"}
                                      suffix={
                                        <Button
                                          type="text"
                                          onClick={togglePasswordVisibility}
                                          icon={
                                            showPassword ? (
                                              <EyeOutlined />
                                            ) : (
                                              <EyeInvisibleOutlined />
                                            )
                                          }
                                        />
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",

                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <label
                                  htmlFor="name"
                                  style={{ fontWeight: "bold" ,color:colors.primary, fontFamily:'Gopher'}}
                                >
                                  {t("ChnagePassword.EnterNewPassword")}
                                </label>
                                <div>
                                  <div style={{ flex: 1 }}>
                                    <Form.Item
                                      name="newpassword"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Create a new Password",
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="forminput"
                                        placeholder={t(
                                          "ChnagePassword.EnterNewPassword"
                                        )}
                                        type={
                                          newshowPassword ? "text" : "password"
                                        }
                                        suffix={
                                          <Button
                                            type="text"
                                            onClick={
                                              toggleNewPasswordVisibility
                                            }
                                            icon={
                                              newshowPassword ? (
                                                <EyeOutlined />
                                              ) : (
                                                <EyeInvisibleOutlined />
                                              )
                                            }
                                          />
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <label
                                  htmlFor="name"
                                  style={{ fontWeight: "bold",color:colors.primary ,fontFamily:'Gopher' }}
                                >
                                  {t("ChnagePassword.ConfirmPassword")}
                                </label>
                                <div>
                                  <div style={{ flex: 1 }}>
                                    <Form.Item
                                      name="password"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Create a new Password",
                                        },
                                      ]}
                                    >
                                      <Input
                                        className="forminput"
                                        placeholder={t(
                                          "ChnagePassword.ConfirmPassword"
                                        )}
                                        type={
                                          newshowPassword ? "text" : "password"
                                        }
                                        suffix={
                                          <Button
                                            type="text"
                                            onClick={
                                              toggleNewPasswordVisibility
                                            }
                                            icon={
                                              newshowPassword ? (
                                                <EyeOutlined />
                                              ) : (
                                                <EyeInvisibleOutlined />
                                              )
                                            }
                                          />
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div>
                              {isError && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <Text style={{ color: "red" }}>{errMsg}</Text>
                                </div>
                              )}
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="signupbtn"
                                onClick={handleResetPasswords}
                                onMouseEnter={(e) =>
                                  (e.target.style.opacity = 0.8)
                                }
                                onMouseLeave={(e) =>
                                  (e.target.style.opacity = 1)
                                }
                              >
                                {t("ChnagePassword.ChnagePassword")}{" "}
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {/* 
              {activeTab === "security" && (
                <>
                  <div>
                    <h1 style={{ color: "#3D49AB" }}>Security</h1>
                    <hr />
                    <div>
                      <p>This is the Security tab content.</p>
                      {/* You can add your security form or content here */}
              {/* </div>
                  </div>
                </> */}
            </div>
          </div>
          {/* <div
            style={{
              border: "1px solid gray",
              borderColor: "#c8c8c8",
              borderRadius: 5,
              // Border on all sides
            }}
          >
            <div>
              <p style={{ color: "#3D49AB", padding: 10 }}>Change Password</p>
              <div
                style={{
                  border: "1px solid gray",
                  borderColor: "#c8c8c8",
                  borderRadius: 5,
                }}
              ></div>
            </div>
            <div style={{ padding: 20 }}>
              <div style={{ display: "flex" }} className="recHeadingDiv">
                <h1 style={{ color: "#3D49AB" }}>Change My Password</h1>
              </div>
              <hr />
              <div>
                <div className="form">
                  <Form
                    name="dynamic_form_item"
                    layout="vertical"
                    onFinish={onFinish}
                    style={{ width: "80%" }}
                  >
                    <div
                      style={{
                        display: "flex",

                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <label htmlFor="name" style={{ fontWeight: "bold" }}>
                          Current Password
                        </label>
                        <div>
                          <Form.Item
                            name="currentPassword"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Current Password",
                              },
                            ]}
                          >
                            <Input
                              className="forminput"
                              placeholder="Current Password"
                              type={showPassword ? "text" : "password"}
                              suffix={
                                <Button
                                  type="text"
                                  onClick={togglePasswordVisibility}
                                  icon={
                                    showPassword ? (
                                      <EyeOutlined />
                                    ) : (
                                      <EyeInvisibleOutlined />
                                    )
                                  }
                                />
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: 10,
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <label
                          htmlFor="password"
                          style={{ fontWeight: "bold" }}
                        >
                          New Password
                        </label>
                        <div style={{ flex: 1 }}>
                          <Form.Item
                            name="newPassword"
                            rules={[
                              {
                                required: true,
                                message: "Create a new Password",
                              },
                            ]}
                          >
                            <Input
                              className="forminput"
                              placeholder="Current Password"
                              type={newshowPassword ? "text" : "password"}
                              suffix={
                                <Button
                                  type="text"
                                  onClick={toggleNewPasswordVisibility}
                                  icon={
                                    newshowPassword ? (
                                      <EyeOutlined />
                                    ) : (
                                      <EyeInvisibleOutlined />
                                    )
                                  }
                                />
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        paddingTop: 10,
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <label
                          htmlFor="password"
                          style={{ fontWeight: "bold" }}
                        >
                          Confirm New Password
                        </label>
                        <div style={{ flex: 1 }}>
                          <Form.Item
                            name="confirmNewPassword"
                            rules={[
                              {
                                required: true,
                                message: "Confirm New Password",
                              },
                            ]}
                          >
                            <Input
                              className="forminput"
                              placeholder="Current Password"
                              type={confirmshowPassword ? "text" : "password"}
                              suffix={
                                <Button
                                  type="text"
                                  onClick={toggleConfPasswordVisibility}
                                  icon={
                                    confirmshowPassword ? (
                                      <EyeOutlined />
                                    ) : (
                                      <EyeInvisibleOutlined />
                                    )
                                  }
                                />
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>

                    <div>
                      <button
                        className="blueButton"
                        style={{
                          width: "100%",
                          opacity: 1,
                          transition: "opacity 0.3s",
                        }}
                        onMouseEnter={(e) => (e.target.style.opacity = 0.8)}
                        onMouseLeave={(e) => (e.target.style.opacity = 1)}
                      >
                        Update
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </Content>
    </div>
  );
};
export default Settings;
