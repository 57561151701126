import React, { useState, useEffect } from "react";
import { Content } from "antd/es/layout/layout";

import {
  Card,
  Radio,
  Modal,
  Form,
  Input,
  Typography,
  Flex,
  Button,
  Spin,
  DatePicker,
  Result,
} from "antd";

import moment from 'moment'; // Import moment for date handling

import { CopyOutlined } from "@ant-design/icons";
import "./style.css";
import * as mutations from "../graphql/mutations";

import { getTableID } from "../Services/authServices";

import { fetchUserAttributes } from "@aws-amplify/auth";
import * as queries from "../graphql/queries";
import { SendOutlined, WalletOutlined } from "@ant-design/icons";
import { generateClient } from "aws-amplify/api";
import { getUserVerificationStatus , getUserInfo} from "../Services/authServices"; // Adjust the import path as necessary
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getFullName } from "../Services/authServices";
import axios from "axios";
const KYCUser = () => {
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const API = generateClient();
  const [KYCURL, setKYCURL] = useState('');
  const [showKYCModal, setShowKYCModal] = useState(false); // State to control the visibility of the KYC modal
  const [inputDisabled, setInputDisabled] = useState(false); // New state to manage input fields' disabled status
  const { t, i18n } = useTranslation();
  //const walletDetails = location.state.wallet;
  const [form] = Form.useForm();
  const [formDisabled, setFormDisabled] = useState(false); // State to disable form interaction
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationContent, setConfirmationContent] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control the visibility of the modal
  const [user, setUser] = useState([]);
  const [fullName, setFullName] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(null); // This will store the form values temporarily

  useEffect(() => {
    const fetchVerificationStatus = async () => {
      try {
        const tableID = await getTableID();
        const status = await getUserVerificationStatus(tableID);
        const userInfo = await getUserInfo(tableID);
        
        if (status && status.verificationStatus === "Requested" && !status.identificationVerified) {
          console.log("User Data",userInfo.firstName, userInfo.lastName, userInfo.dateOfBirth);

          form.setFieldsValue({
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            dob: moment(userInfo.dateOfBirth), // Use moment to convert dateOfBirth to a moment object
          });

          setInputDisabled(true); // Disable input fields but not the button
        }
      } catch (error) {
        console.error("Error fetching verification status:", error);
      }
    };

    fetchVerificationStatus();
  }, []);

  useEffect(() => {
    // Function to proactively ask for camera permission
    const requestCameraPermission = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
          stream.getTracks().forEach(track => track.stop());
        });
        console.log("Camera permission granted");
      } catch (error) {
        console.log("Camera permission denied");
      }
    };
    requestCameraPermission();
  }, []);


  const handleFormSubmit = (values) => {
    if (inputDisabled) {
      console.error("Form submission is disabled due to pending verification.");
      setFormValues(values); // Temporarily store form values
      handleSubmit();
      return;
    }

    setFormValues(values); // Temporarily store form values
    setShowModal(true); // Trigger the confirmation modal
  };


  const handleCardSelect = (value) => {
    setSelectedPaymentMethod(value);
  };
  async function currentAuthenticatedUser() {
    try {
      const user = await fetchUserAttributes();
      setUser(user);
      const full_name = await getFullName();
      //console.log("full_name", full_name);
      setFullName(full_name);
      return user;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  useEffect(() => {
    currentAuthenticatedUser();
    console.log("Test state:", location.state);
  }, []);

 

  const handleCancel = () => {
    setShowKYCModal(false); // Function to close the KYC modal
  }

  const handleSubmit = async (values) => {


    if (!formValues) {
      console.error("No form data available.");
      return;
    }

    setLoading(true); // Start loading before the API call
    setShowModal(false); // Close the modal when the submission starts
    
    console.log("Starting submission process");
  
    const tableID = await getTableID();
    if (!tableID) {
      console.error("Table ID is not available.");
      return;
    }
    console.log(`Table ID: ${tableID}`);
    
    try {

      console.log('Received DOB:', formValues.dob);
      console.log('Received DOB:', formValues.firstName);

      console.log('Received DOB:', formValues.firstName);



      let formattedDateOfBirth = '';
    // Ensure values.dob is a valid date
    if (formValues.dob && !isNaN(new Date(formValues.dob).getTime())) {
      formattedDateOfBirth = new Date(formValues.dob).toISOString().split('T')[0];
    } else {
      throw new Error('Invalid date of birth provided.');
    }

      
      const input = {
        id: tableID,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        dateOfBirth: formattedDateOfBirth,
        verificationStatus: 'Submitted',
        // Format the date to match AWSDate
        // Include other non-nullable fields based on your schema requirements
      };
  
      console.log("Sending update request with input:", input);
  
      // Performing the update operation
      const result = await API.graphql({
        query: mutations.updateUser,
        variables: { input }, // Correctly pass the input object as mutation variables
      });
  
      console.log("Update result", result);


       // After successful update, call your API to get the link
       const response = await fetch(`https://p5f1470p0g.execute-api.us-east-2.amazonaws.com/default/SetupIComplyForAll-dev?userId=${tableID}`);
       if (!response.ok) {
         throw new Error(`API call failed with status: ${response.status}`);
       }
       const responseData = await response.json();
 
       // Assuming the API returns an object with a URL property
       console.log("API response received:", responseData);
 
       // Example: If the API returns an object with a url property
       if (responseData.url) {
         console.log("Verification URL:", responseData.url);
         setKYCURL(responseData.url); // Store URL in state to pass to modal
         // Perform actions with the URL, e.g., store it in state, open it in a modal, etc.
       } else {
         console.error("No URL returned from the API.");
       }
 
  
      setShowKYCModal(true); // Open the KYC modal after successful submission
      //openKYCInPopup();
      // Handle post-update logic, such as setting confirmation content
      // setConfirmationContent("Your details have been updated successfully.");
      // setShowConfirmation(true); // You might want to show a success message or navigate elsewhere

      
      console.log("Update successful, confirmation shown");
    } catch (err) {
      console.error("Error updating user:", err);
      // Handle error cases, perhaps by setting an error message in the UI
    } finally {
      setLoading(false); // Stop loading regardless of the result
      console.log("Submission process completed");
    }
  };


  const handleConfirm =  async (values)=> {
    // Logic for what happens when user clicks 'Confirm' in the modal
    console.log("Starting submission process");
      setShowModal(false);
  };

  const handleBackToWallet = () => {
    setShowConfirmation(false);
    navigate("/moneyCalculator"); // Assuming '/wallet' is your wallet page route
  };
  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        padding: "20px",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        style={{ width: "100%", maxWidth: "400px" }}
        //disabled={formDisabled} // Disable form based on formDisabled state

      >
        <Typography.Title level={2} style={{ textAlign: "center" }}>
        {t("ProvideDetails.Provide_Details")}
        </Typography.Title>
       
        <Form.Item name="firstName" label= {t("EditRecipient.FirstName")} rules={[{ required: true, message: "Please enter your first name!" }]}>
          <Input placeholder="First Name" disabled={inputDisabled}/>
        </Form.Item>

        <Form.Item name="lastName" label= {t("EditRecipient.LastName")}rules={[{ required: true, message: "Please enter your last name!" }]}>
          <Input placeholder="Last Name" disabled={inputDisabled}/>
        </Form.Item>

        <Form.Item name="dob" label= {t("EditRecipient.DateOfBirth")} rules={[{ required: true, message: "Please select your date of birth!" }]}>
        <DatePicker format='YYYY-MM-DD' valueFormat='YYYY-MM-DD' disabled={inputDisabled} />
        </Form.Item>
        

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            {loading ? <Spin /> :  t("EditRecipient.Continue")}
          </Button>
        </Form.Item>
      </Form>

   


      <Modal
      title="Confirm your information"
      visible={showModal}
      onOk={handleSubmit}
      onCancel={() => setShowModal(false)}
      footer={[
        <Button key="back" onClick={() => setShowModal(false)}>
          Edit
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Confirm
        </Button>,
      ]}
    >
      <p>Your information should exactly match what's on your ID.</p>
      <p>Mismatched information will delay your transfer.</p>
    </Modal>


  


    <Modal
        title="KYC Details"
        visible={showKYCModal}
        onCancel={handleCancel}
        width={800} // Adjust the width as necessary
        footer={null} // Optionally remove the footer
      >
        <p>To complete your verification, please allow access to your camera.</p>

        <iframe 
          src={KYCURL}
          allow="camera"
          frameBorder="0"
          width="100%"
          height="500px"
          allowFullScreen>
        </iframe>
      </Modal>

    </Content>
  );
};

export default KYCUser;
