import React, { useEffect, useState } from "react";
import { Row, Col, Card, Space, Input, Select, Button, Modal } from "antd";
import { Content } from "antd/es/layout/layout";
import { Typography } from "antd";
import { Link, redirect, useNavigate, useLocation } from "react-router-dom";
import {} from "react-router-dom";
import "./style.css";
import {
  getUserVerificationStatus,
  getTableID,
} from "../Services/authServices"; // Adjust the import path as necessary
import cad_flag from "../Image/flag/cad_flag.png";
import xof_flag from "../Image/flag/xof_flag.png";
import { generateClient } from "aws-amplify/api";
import { useTranslation } from "react-i18next";
import { colors } from "../styles/colors";


const client = generateClient();
const { Text } = Typography;
const { Option } = Select;

const MoneyCalculator = () => {
  const { t } = useTranslation();

  const location = useLocation();
  console.log(location);

  const [iscoming, IsComing] = useState(false);
  const recipient = location.state?.recipient;
  const [currencyNow, setCurrencyNow] = useState("cad");
  const [currencyNow2, setCurrencyNow2] = useState("xof");
  const [cadToXOFRate, setcadToXOFRate] = useState(0.1);
  const [xOFToCADRate, setXOFToCadRate] = useState(0.1);
  const [sendRate, setSendRate] = useState(0.0);
  const [getRate, setGetRate] = useState(0.0);
  const [changeField1, setChangedField1] = useState(false);
  const [changeField2, setChangedField2] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState("");
  const [sourceOfFund, setSourceOfFund] = useState("");
  const [selectTime, setSelectTime] = useState(location.state.selectTime);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    location.state.selectedPaymentMethod
  );

  const navigate = useNavigate();
  const [sendRateError, setSendRateError] = useState("");
  const [identificationVerified, setIdentificationVerified] = useState(false); // Assuming isOrganization is a boolean state
  const [showModal, setShowModal] = useState(false); // State to control the visibility of the modal
  const [verificationStatus, setVerificationStatus] = useState({
    identificationVerified: false,
    isOrganization: false,
    verificationOption: null,
  });
  // currency list
  const currency_list = [
    {
      value: "cad",
      image: cad_flag,
      code: "CAD",
    },
    {
      value: "xof",
      image: xof_flag,
      code: "XOF",
    },
  ];

  useEffect(() => {
    const fetchVerificationStatus = async () => {
      const tableID = await getTableID();

      const status = await getUserVerificationStatus(tableID);

      console.log("verification status: ", status);
      if (status) {
        setVerificationStatus(status);
        if (status.identificationVerified) {
          setIdentificationVerified(true);
        } else {
          setIdentificationVerified(false);
        }
      }
    };

    fetchVerificationStatus();
  }, []);

  useEffect(() => {
    fetchCurrentRate();
  }, []);

  useEffect(() => {
    if (location.state?.iscoming === true) {
      IsComing(true);
    }
    setSendRate(location.state?.formattedSendRate || 0);
    setGetRate(location.state?.getRate || 0);
    setSelectedReasons(location.state?.selectedReasons || "");
    setSourceOfFund(location.state?.sourceOfFund || "");
    // ... other state updates based on location.state
  }, [location.state]);

  const handleSubmit = async () => {
    if (verificationStatus.isOrganization) {
      navigate("/kybuser");
    } else {
      navigate("/kycuser");
    }
  };

  const handleChange = (value) => {
    setSelectedReasons(value); // Update the selected reasons in the state
  };
  // fetch rate
  async function fetchCurrentRate() {
    const query = `query MyQuery {
      listAdmins {
        items {
          finalCADToXOF
          finalXOFToCAD
        }
      }
    }
    `;
    const response = await client.graphql({
      query: query,
    });

    console.log("Response", response.data.listAdmins.items[0]);
    setcadToXOFRate(response.data.listAdmins.items[0].finalCADToXOF);
    setXOFToCadRate(response.data.listAdmins.items[0].finalXOFToCAD);

    console.log(
      "current rate",
      sendRate * cadToXOFRate,
      getRate,
      sendRate,
      cadToXOFRate
    );
  }
  const handleMoneyClick = () => {
    // Check if sendRate is empty, null, undefined, or 0
    if (!sendRate || parseFloat(sendRate) === 0) {
      setSendRateError("Please enter a valid amount greater than 0.");
      return; // Stop the function if the amount is not valid
    } else {
      setSendRateError("");
    }

    // Check if the user is not verified
    if (!identificationVerified) {
      setShowModal(true); // Show modal or any indication that the user needs to be verified
      return;
    }
    const formattedSendRate = parseFloat(sendRate).toFixed(2);
    if (iscoming) {
      navigate("/overview", {
        state: {
          recipient, // This is the recipient state you're currently passing
          currencyNow, // The currently selected currency
          currencyNow2, // The target currency
          formattedSendRate, // The amount of money in the currencyNow that the user wants to send
          getRate: sendRate * cadToXOFRate, // The amount of money in the currencyNow2 that the recipient will get
          cadToXOFRate, // The exchange rate from CAD to XOF
          selectedReasons, // reason of sending
          selectTime: 300,
          selectedPaymentMethod,
          sourceOfFund,
          // time remaining to do treanscation
        },
      });
    } else {
      navigate("/PaymentMethod", {
        state: {
          recipient, // This is the recipient state you're currently passing
          currencyNow, // The currently selected currency
          currencyNow2, // The target currency
          formattedSendRate, // The amount of money in the currencyNow that the user wants to send
          getRate: sendRate * cadToXOFRate, // The amount of money in the currencyNow2 that the recipient will get
          cadToXOFRate, // The exchange rate from CAD to XOF
          selectedReasons, // reason of sending
          selectedReasons,
          sourceOfFund,
        },
      });
      console.log("hellop", {
        recipient,
        currencyNow,
        currencyNow2,
        sendRate,
        getRate,
        cadToXOFRate,
        xOFToCADRate,
        selectedReasons,
        sourceOfFund,
      });
    }
  };

  // const resetTimer = () => {
  //   setIsActive(false);
  //   setSeconds(300);
  // };

  return (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        flexDirection: "column",
        padding: "1rem",
        // backgroundColor:'red'
      }}
    >
      {/* <div>Time Left: {formattedTime()}</div> */}

      <div className="recMainDiv" style={{ padding: "1rem" }}>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="recHeadingDiv"
        >
          <h1 style={{ color: colors.primary, fontFamily:'Gopher' }}>
            {t("SendMoney.how_much_you_send")}
          </h1>
        </div>
        <hr />
      </div>
      <div className="calCard">
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Card>
              <div className="converter-title-container-screen">
                <Text className="converter-title-screen">
                  {t("SendMoney.ExchangeRate")}
                </Text>
                <Text className="converter-rate-screen">
                  {currencyNow === "cad"
                    ? `1 CAD = ${cadToXOFRate.toFixed(2)} XOF`
                    : `1 XOF = ${xOFToCADRate} CAD `}
                </Text>
              </div>
              <div>
                <div className="converter-input-container">
                  <Text strong className="converter-input-title-screen">
                    {t("SendMoney.Yousendexactly")}
                  </Text>
                  <Space.Compact>
                    <Input
                      placeholder="0"
                      value={
                        currencyNow === "cad"
                          ? changeField2
                            ? (+getRate / cadToXOFRate || 0).toLocaleString()
                            : (+sendRate || 0).toLocaleString()
                          : changeField2
                          ? (+getRate / xOFToCADRate || 0).toLocaleString()
                          : (+sendRate || 0).toLocaleString()
                      }
                      style={{ borderRadius: "1rem 0rem 0rem 1rem" }}
                      onChange={(e) => {
                        const value = e.target.value.replace(/,/g, "");
                        // Check if the value is empty or if it's a valid number greater than 0
                        if (
                          value === "" ||
                          (/^[0-9]*\.?[0-9]*$/.test(value) &&
                            parseFloat(value) > 0)
                        ) {
                          setSendRateError("");
                          if (value !== "") {
                            setSendRate(+value);
                          } else {
                            setSendRate(0); // or whatever your initial state is for sendRate
                          }
                          setChangedField1(true);
                          setChangedField2(false);
                        } else {
                          setSendRateError(
                            "Please enter a valid amount greater than 0."
                          );
                        }
                      }}
                    />
                    <Select
                      className="select-border"
                      defaultValue="cad"
                      disabled={true}
                      value={currencyNow}
                    >
                      {currency_list.map((item) => (
                        <Option value={item.value} key={item.value}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.2rem",
                            }}
                          >
                            <img
                              src={item.image}
                              width={30}
                              height={30}
                              alt={item.code}
                            />
                            <Text>{item.code}</Text>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Space.Compact>
                  {sendRateError && (
                    <div style={{ color: "red", marginTop: "5px" }}>
                      {sendRateError}
                    </div>
                  )}
                </div>
                <div className="converter-input-container">
                  <Text strong className="converter-input-title">
                    {t("SendMoney.Recipientgets")}
                  </Text>
                  <Space.Compact>
                    <Input
                      placeholder="0"
                      value={
                        currencyNow === "cad"
                          ? changeField1
                            ? (+sendRate * cadToXOFRate || 0).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : (+getRate || 0).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : changeField1
                          ? (+sendRate * xOFToCADRate || 0).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : (+getRate || 0).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                      }
                      onChange={(e) => {
                        const value = e.target.value.replace(/,/g, ""); // Remove commas for calculation
                        if (/^[0-9]*\.?[0-9]*$/.test(value)) {
                          const formattedValue = parseFloat(value).toFixed(2);
                          setGetRate(formattedValue);
                          setChangedField1(false);
                          setChangedField2(true);
                        }
                      }}
                      style={{ borderRadius: "1rem 0rem 0rem 1rem" }}
                    />

                    <Select
                      className="select-border"
                      defaultValue="xof"
                      disabled={true}
                      value={currencyNow2}
                    >
                      {currency_list.map((item) => (
                        <Option value={item.value} key={item.value}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.2rem",
                            }}
                          >
                            <img
                              src={item.image}
                              width={30}
                              height={30}
                              alt={item.code}
                            />
                            <Text>{item.code}</Text>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Space.Compact>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        {/* <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col className="homePartFirstRight">
            <div> */}
        {/* <Text className="converter-title">What is the purpose of your transfer</Text> */}
        {/* <h1 style={{ color: "#3085C3" }}>
                {t("SendMoney.purpose_your_transfer")}
              </h1>
              <hr />

              <Select
                className="unique-select"
                mode="tags"
                placeholder={t("SendMoney.select_purpose")}
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  backgroundColor: "white",
                }}
                bordered={false}
                onChange={handleChange}
                options={[
                  {
                    value: "School fees payments",
                    label: "School fees payments",
                  },
                  {
                    value: "House purchase down payment",
                    label: "House purchase down payment",
                  },
                  {
                    value: "House rents payments",
                    label: "House rents payments",
                  },
                  {
                    value: "Transfer funds for the upkeep of family members",
                    label: "Transfer funds for the upkeep of family members",
                  },
                  {
                    value: "Payment for travel tickets, holiday logistics",
                    label: "Payment for travel tickets, holiday logistics",
                  },
                  {
                    value: "Financial gifts payment",
                    label: "Financial gifts payment",
                  },
                  {
                    value: "Forex currency exchange services",
                    label: "Forex currency exchange services",
                  },
                  {
                    value: "Utilities and bills payment",
                    label: "Utilities and bills payment",
                  },
                  {
                    value: "Virtual currency exchange to fiat currencies",
                    label: "Virtual currency exchange to fiat currencies",
                  },
                  {
                    value: "Bank support services",
                    label: "Bank support services",
                  },
                  {
                    value: "General remittances",
                    label: "General remittances",
                  },
                ]}
                value={selectedReasons}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col className="homePartFirstRight">
            <div> */}
        {/* <Text className="converter-title">What is the purpose of your transfer</Text> */}
        {/* <h1 style={{ color: "#3085C3" }}>
                {t("SendMoney.purpose_your_transfer")}
              </h1>
              <hr />

              <Select
                className="unique-select"
                mode="tags"
                placeholder={t("SendMoney.select_purpose")}
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  backgroundColor: "white",
                }}
                bordered={false}
                onChange={handleChange}
                options={[
                  {
                    value: "School fees payments",
                    label: "School fees payments",
                  },
                  {
                    value: "House purchase down payment",
                    label: "House purchase down payment",
                  },
                  {
                    value: "House rents payments",
                    label: "House rents payments",
                  },
                  {
                    value: "Transfer funds for the upkeep of family members",
                    label: "Transfer funds for the upkeep of family members",
                  },
                  {
                    value: "Payment for travel tickets, holiday logistics",
                    label: "Payment for travel tickets, holiday logistics",
                  },
                  {
                    value: "Financial gifts payment",
                    label: "Financial gifts payment",
                  },
                  {
                    value: "Forex currency exchange services",
                    label: "Forex currency exchange services",
                  },
                  {
                    value: "Utilities and bills payment",
                    label: "Utilities and bills payment",
                  },
                  {
                    value: "Virtual currency exchange to fiat currencies",
                    label: "Virtual currency exchange to fiat currencies",
                  },
                  {
                    value: "Bank support services",
                    label: "Bank support services",
                  },
                  {
                    value: "General remittances",
                    label: "General remittances",
                  },
                ]}
                value={selectedReasons}
              />
            </div>
          </Col>
        </Row> */}
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col>
            <div>
              <h1 style={{ color: colors.primary,fontFamily:'Gopher'  }}>
                {t("sendRemittance.sendingPurpose")}
              </h1>
              <hr />

              <Select
                className="unique-select"
                placeholder={t("sendRemittance.selectPurpose")}
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  backgroundColor: "white",
                }}
                bordered={false}
                onChange={(value) => setSelectedReasons(value)}
                value={selectedReasons}
                options={[
                  {
                    value: "Family support",
                    label: t("sendRemittance.familySupport"),
                  },
                  {
                    value: "Business transaction",
                    label: t("sendRemittance.businessTransaction"),
                  },
                  { value: "Education", label: t("sendRemittance.education") },
                  { value: "Gift", label: t("sendRemittance.gift") },
                  { value: "Savings", label: t("sendRemittance.savings") },
                  {
                    value: "Loan payment",
                    label: t("sendRemittance.loanPayment"),
                  },
                  {
                    value: "Investment",
                    label: t("sendRemittance.investment"),
                  },
                  {
                    value: "Goods and services",
                    label: t("sendRemittance.goods"),
                  },
                  {
                    value: "Property transaction",
                    label: t("sendRemittance.property"),
                  },
                  {
                    value: "Tax, insurance payment",
                    label: t("sendRemittance.tax"),
                  },
                  {
                    value: "Charity / Aid",
                    label: t("sendRemittance.charity"),
                  },
                  { value: "Other", label: t("sendRemittance.other") },
                ]}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col>
            <div>
              <h1 style={{ color: colors.primary,fontFamily:'Gopher'  }}>
                {t("sendRemittance.sourceOfFund")}
              </h1>
              <hr />

              <Select
                className="unique-select"
                placeholder={t("sendRemittance.selectSource")}
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  backgroundColor: "white",
                  fontFamily:'Gopher' 
                }}
                bordered={false}
                onChange={(value) => setSourceOfFund(value)}
                value={sourceOfFund}
                options={[
                  {
                    value: "Salary / Income",
                    label: t("sendRemittance.salaryIncome"),
                  },
                  { value: "Savings", label: t("sendRemittance.savings") },
                  {
                    value: "Pension / Retirement",
                    label: t("sendRemittance.pensionRetirement"),
                  },
                  {
                    value: "Borrowed Funds / Loan",
                    label: t("sendRemittance.borrowFunds"),
                  },
                  { value: "Gift", label: t("sendRemittance.gift") },
                  { value: "Welfare", label: t("sendRemittance.welfare") },
                  {
                    value: "Inheritance",
                    label: t("sendRemittance.inheritance"),
                  },
                  {
                    value: "Charitable donations",
                    label: t("sendRemittance.charitableDonations"),
                  },
                  { value: "Other", label: t("sendRemittance.other") },
                ]}
              />
            </div>
          </Col>
        </Row>

        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col
            className="homePartFirstRight"
            xs={{ span: 24 }}
            lg={{ span: 12 }}
          >
            <div style={{ width: "100%" }}>
              {/* <div>Time Left: {formattedTime()}</div> */}
              <Button
                className="signupbtn"
                type="primary"
                htmlType="submit"
                style={{
                  width: "100%",
                }}
                onClick={handleMoneyClick}
              >
                <span style={{ fontSize: "18px" ,fontFamily:'Gopher' }}>
                  {t("EditRecipient.Continue")}
                </span>
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        title="Please Complete Your KYC/KYB Process"
        visible={showModal}
        onOk={handleSubmit}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Access Now!
          </Button>,
        ]}
      >
        <p>
          Please Complete KYC/KYB Process to Transafer Money Via Our Portal.
        </p>
      </Modal>
    </Content>
  );
};
export default MoneyCalculator;
