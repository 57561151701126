import React from "react";
import { Col, Row, Typography, Skeleton } from "antd";
const { Text } = Typography;
const HomePagePart3 = () => {
  const features = [
    {
      title: "Swift International Transfers",
      description:
        "Send money globally with lightning speed. Experience swift and secure international remittances for seamless cross-border transactions.",
    },
    {
      title: "Bank Deposit",
      description:
        "Effortlessly transfer funds to bank accounts. Enjoy the ease of bank-to-bank transfers, ensuring your money reaches its destination securely.",
    },
    {
      title: "Mobile Money",
      description:
        "Modernize your money with mobile payments. Seamlessly connect to mobile money services, embracing the digital age of financial transactions.",
    },
    {
      title: "Saved Recipient Profiles",
      description:
        "Simplify future transfers with saved recipients. Store recipient detail for quick and hassle-free transactions, eliminating repetitive data entry.",
    },
    {
      title: "Real-time Transaction Tracking",
      description:
        "Stay informed with live transaction updates. Keep tabs on your money's journey with real-time tracking, ensuring peace of mind.",
    },
    {
      title: "Security and Support",
      description:
        "Profect and assist with 2FA and expoert help. Rely on top-notch security with two-factor authentication(2FA) and decicated customer support.",
    },
  ];
  return (
    <div style={{padding:"2rem 0rem"}}>
      <div
        style={{ textAlign: "center", marginTop: "2rem", marginBottom: "3rem" }}
      >
      </div>

      <div>
        <Row
          style={{
            padding: "0rem 1rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          {features.map((feature) => (
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "2rem",
                fontFamily:'Gopher'
              }}
              key={feature.title} // Assuming each title is unique
            >
              <div
                style={{
                  width: "80%",
                  display: "flex",
                }}
              >
                <div>
                  <Skeleton.Image active />
                </div>
                <div style={{ marginLeft: "1rem" }}>
                  <Text
                    style={{
                      display: "block",
                      marginBottom: "1rem",
                      fontWeight: "bold",
                      color: "#0070cc",
                      fontFamily:'Gopher'
                    }}
                  >
                    {feature.title}
                  </Text>
                  <Text style={{fontFamily:'Gopher'}}> {feature.description}</Text>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
export default HomePagePart3;
