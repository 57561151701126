import React from "react";
import { Button, Input, InputNumber, Form, Upload } from "antd";
import { Link } from "react-router-dom";
import "./index.css";
import RemitClickInfo from "../Components/RemitClickInfo";
import Footerpage from "./Footerpage";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
const SignUp = () => {
  return (
    <main className="mainsec">
      <div className="conatiner">
        <RemitClickInfo />
        <div className="signUpRightSec">
          <Link to="/">
            <Button
              type="primary"
              icon={<CloseOutlined />}
              className="crossSign"
            />
          </Link>
          <div className="box">
            <h2 className="signupTitle">Kyc</h2>
            <div className="form">
              <Form>
                <label className="formlabel" style={{ fontSize: "16px" }}>
                  First name
                </label>
                <Form.Item
                  //   label="First Name"
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "please enter First Name",
                    },
                  ]}
                >
                  <Input
                    className="forminput"
                    placeholder="First Name"
                    autocomplete="off"
                  />
                </Form.Item>
                <label className="formlabel" style={{ fontSize: "16px" }}>
                  Last name
                </label>
                <Form.Item
                  //   label="Last Name"
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "please enter Last Name",
                    },
                  ]}
                >
                  <Input className="forminput" placeholder="Last Name" />
                </Form.Item>
                <label className="formlabel" style={{ fontSize: "16px" }}>
                  Phone number
                </label>
                <Form.Item
                  //   label="Phone Number"
                  name="phonenumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Phone Number!",
                    },
                  ]}
                >
                  <Input className="forminput" placeholder="Phone Number" />
                </Form.Item>
                <h3 className="signupTitle">ID Requirements</h3>
                <label className="formlabel" style={{ fontSize: "16px" }}>
                  Driving licence number
                </label>
                <Form.Item
                  //   label="First Name"
                  name="licence"
                  rules={[
                    {
                      required: true,
                      message: "please enter Driving licence number",
                    },
                  ]}
                >
                  <Input
                    className="forminput"
                    placeholder="Driving licence number"
                    autocomplete="off"
                  />
                </Form.Item>
                <label className="formlabel" style={{ fontSize: "16px" }}>
                  Country of Licence
                </label>
                <Form.Item
                  //   label="Last Name"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "please enter Country of Licence",
                    },
                  ]}
                >
                  <Input className="forminput" placeholder="Last Name" />
                </Form.Item>
                <label className="formlabel" style={{ fontSize: "16px" }}>
                  Passport Number
                </label>
                <Form.Item
                  //   label="Phone Number"
                  name="passport"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Passport Number!",
                    },
                  ]}
                >
                  <Input className="forminput" placeholder="Passport Number" />
                </Form.Item>
                <label className="formlabel" style={{ fontSize: "16px" }}>
                  Country of Passport
                </label>
                <Form.Item
                  //   label="Last Name"
                  name="countryPassport"
                  rules={[
                    {
                      required: true,
                      message: "please enter Country of Passport",
                    },
                  ]}
                >
                  <Input className="forminput" placeholder="Last Name" />
                </Form.Item>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label className="formlabel" style={{ fontSize: "16px" }}>
                    Upload ID 1:
                  </label>
                  <Form.Item>
                    <Upload>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </Form.Item>

                  <label className="formlabel" style={{ fontSize: "16px" }}>
                    Upload ID 2:
                  </label>
                  <Form.Item>
                    <Upload>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </Form.Item>
                </div>

                <Button className="signupbtn">Submit</Button>
              </Form>

              <p className="signupFooter">
                Already have an account?{" "}
                <Link to="" style={{ textDecoration: "none" }}>
                  {" "}
                  Sign In
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footerpage />
      </div>
    </main>
  );
};
export default SignUp;
