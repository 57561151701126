import React, { useState, useEffect } from "react";
import { Button, Input, Row, Col, Form, Checkbox, Typography } from "antd";
import { Link } from "react-router-dom";
import "./index.css";
import RemitClickInfo from "../Components/RemitClickInfo";
import { CloseOutlined } from "@ant-design/icons";
import logo from "../Image/logo.png";
import { signIn } from "aws-amplify/auth";
import { useTranslation } from "react-i18next";
import { signOut } from "@aws-amplify/auth";
import { colors } from "../styles/colors";

const SignIn = ({ onLoginSuccess }) => {
  const { Text } = Typography;
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const isMobile = viewportWidth < 768;
  const [usernameInput, setUserName] = useState("");
  const [passwordInput, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { t } = useTranslation();

  //console.log("onLoginSuccess prop:", onLoginSuccess);
  async function handleSignIn({ username2, password2 }) {
    try {
      //console.log("received", username2, password2);
      const { isSignedIn, nextStep } = await signIn({
        username: username2,
        password: password2,
      });
      onLoginSuccess();
    } catch (error) {
      console.log("error signing in", error);
      setIsError(true);
      const message = error.toString().split(":").pop().trim();
      setErrMsg(message);
    }
  }

  useEffect(() => {
    signOut()
    const handleWindowResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      {isMobile && (
        <Row
          style={{
            display: "flex",
            borderBottomStyle: "solid",
            borderBottomColor: "#F2F2F2",
            borderBottomWidth: "0.1px",
          }}
        >
          <Col
            span={20}
            style={{
              display: "flex",
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "1rem",
              }}
            >
              <img
                src={logo}
                style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                  }}
                alt="RemitClick"
                className="headerLogo"
              />
            </div>
          </Col>
          <Col
            span={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link to="/">
              <Button
                type="primary"
                icon={<CloseOutlined />}
                className="crossSign-mobile"
              />
            </Link>
          </Col>
        </Row>
      )}
      <Row style={{ height: "100vh" }}>
        <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 10 }}>
          <RemitClickInfo />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 14 }}>
          <div className="signinRightSec">
            <div className="signbox">
              {!isMobile && (
                <Link
                  to="/"
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    type="primary"
                    icon={<CloseOutlined />}
                    className="crossSign"
                  />
                </Link>
              )}
              <h2 className="signupTitle">{t("login.signins")}</h2>
              <div className="form">
                <Form
                  onValuesChange={(changedValues, allValues) => {
                    // Handle changes in the form values
                    setUserName(allValues.username);
                    setPassword(allValues.password);

                   
                  }}
                >
                  <label className="formlabel" style={{ fontSize: "16px",fontFamily: 'Gopher' }}>
                  {t("login.Username")}
                  </label>
                  <Form.Item
                    //   label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Username",
                      },
                    ]}
                  >
                    <Input className="forminput" placeholder="Username" />
                  </Form.Item>

                  <label className="formlabel" style={{ fontSize: "16px",color:colors.primary,fontFamily: 'Gopher' }}>
                  {t("login.Password")}
                  </label>
                  <Form.Item
                    //   label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      className="forminput"
                      placeholder="Password"
                    />
                  </Form.Item>

                  <Form.Item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox style={{colors:colors.primary,fontFamily: 'Gopher'}}> {t("login.Rememberme")}</Checkbox>
                      </Form.Item>
                      <Link to="/forgetPassword"><label style={{color:colors.primary,fontFamily: 'Gopher'}}>
                      {t("login.Forgotpassword")}
                        </label></Link>
                    </div>
                  </Form.Item>
                  {isError && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <Text style={{ color: "red",fontFamily: 'Gopher' }}>{errMsg}</Text>
                    </div>
                  )}
                  <Button
                    onClick={() =>
                      handleSignIn({
                        username2: usernameInput,
                        password2: passwordInput,
                      })
                    }
                    className="signupbtn"
                  >
                   {t("login.signins")}
                  </Button>
                  {/* <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Log in
                </Button> */}
                </Form>

                <p className="signupFooter">
                {t("login.Donthaveanaccount")}
                  <Link to="/signUp" style={{ textDecoration: "none", color:colors.primary ,fontFamily: 'Gopher' }}>
                  {t("login.SignUp")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Col>
        {/* <Footerpage /> */}
      </Row>
    </>
  );
};
export default SignIn;
