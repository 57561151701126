import React, { useState, useEffect, useMediaQuery } from "react";
import logo from "../Image/logo.png";
import {
  UploadOutlined,
  AppstoreOutlined,
  UserOutlined,
  VideoCameraOutlined,
  SendOutlined,
  BellOutlined,
  DollarOutlined,
  SettingOutlined,
  LogoutOutlined,
  DownOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { getUserVerificationStatus } from "../Services/authServices"; // Adjust the import path as necessary
import { useTranslation } from "react-i18next";
import { CookiesProvider, useCookies } from "react-cookie";
import { signOut } from "@aws-amplify/auth";
import {
  Button,
  Layout,
  Menu,
  theme,
  Popover,
  Space,
  App,
  Modal,
  Select,
} from "antd";
import { Link } from "react-router-dom"; // Import Link
import { Outlet } from "react-router-dom";
import { getName } from "../Services/authServices";
import { getTableID } from "../Services/authServices";
import { colors } from "../styles/colors";
const { Header, Content, Footer, Sider } = Layout;

const DefaultLayout = ({ children }) => {
  const { t, i18n } = useTranslation();

  const item2 = [
    {
      key: "1",
      key: "1",
      icon: React.createElement(AppstoreOutlined),
      label: t("dashboard.Dashboards"),
      path: "/dashboard",
      label: "Dashboard",
      path: "/dashboard",
    },
    {
      key: "2",
      icon: React.createElement(SendOutlined),
      label: t("dashboard.SendMoney"),
      path: "/selectRecipient",
    },
    {
      key: "3",
      icon: React.createElement(UserOutlined),
      label: t("dashboard.Recipients"),
      path: "/selectEditRecipient",
    },
    {
      key: "4",
      icon: React.createElement(DollarOutlined),
      label: t("dashboard.Transactions"),
      path: "/transactions",
    },
    {
      key: "5",
      icon: React.createElement(WalletOutlined),
      label: t("dashboard.Wallet"),
      path: "/wallet",
    },
  ];
  const [verificationStatus, setVerificationStatus] = useState({
    identificationVerified: false,
    isOrganization: false,
    verificationOption: null,
  });
  const [viewportWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOrganization, setIsOrganization] = useState(true); // Assuming isOrganization is a boolean state
  const [cookies, setCookie] = useCookies(["lang"]);

  const isMobile = viewportWidth < 768;

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const headerHeight = 90;
  const [defLang, setDefLang] = useState("en");

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [fullName, setFullName] = useState("Loading...");
  const handleLogout = async () => {
    try {
      const response = await signOut();
      console.log("signout response ", response);
      localStorage.removeItem("loginTimestamp");
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  useEffect(() => {
    setDefLang(i18n.language);

    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchVerificationStatus = async () => {
      const tableID = await getTableID();

      const status = await getUserVerificationStatus(tableID);

      console.log("verification status: ", status);
      if (status) {
        setVerificationStatus(status);
        if (status.isOrganization) {
          setIsOrganization(true);
        } else {
          setIsOrganization(false);
        }
      }
    };
    fetchVerificationStatus();
  }, []);

  useEffect(() => {
    const fetchFullName = async () => {
      const name = await getName();
      console.log("NAME FROM CODE: ", name);
      if (name) {
        setFullName(name); // Update the state with the fetched full name
      } else {
        setFullName("User"); // Fallback name in case of an error or if name is not found
      }
    };
    fetchFullName();
  }, []);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setDefLang(value);
    i18n.changeLanguage(value);
    setCookie("lang", value);
  };

  const logoutContent = (
    <Space direction="vertical">
      <div width="90%">
        <Link to="/settings">
          <SettingOutlined style={{ color: "black" }} />
          <Button type="text">{t("dashboard.Setting")}</Button>
        </Link>
        <div>
          <Link to="/myaccountdetails">
            <UserOutlined size={30} style={{ color: "black" }} />
            <Button type="text">{t("dashboard.MyAccount")}</Button>
          </Link>
        </div>
        <div>
          <LogoutOutlined style={{ color: "black" }} />
          <Button type="text" onClick={handleLogout}>
            {t("dashboard.Logout")}
          </Button>
        </div>
        <div>
          {!verificationStatus.identificationVerified &&
            (verificationStatus.verificationOption === "KYB" ? (
              <Link to="/kybuser">
                <UserOutlined size={30} style={{ color: "black" }} />
                <Button type="text">KYB</Button>
              </Link>
            ) : (
              <Link to="/kycuser">
                <UserOutlined size={30} style={{ color: "black" }} />
                <Button type="text">KYC</Button>
              </Link>
            ))}
        </div>
      </div>
    </Space>
  );

  const [visible, setVisible] = useState(false); // State for the Modal
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Layout>
      <Sider breakpoint="md" collapsedWidth="50" style={{ background: "white" }}>
        <div className="demo-logo-vertical">
          {!isMobile && (
            <img
              src={logo}
              alt="image"
              style={{ width: "100%", height: "auto", padding: 20 }}
              md={{}}
            />
          )}
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            style={{ marginTop: "1rem", fontSize: "16px",fontFamily:'Gopher' }}
          >
            {item2.map((item) => (
              <Menu.Item key={item.key} icon={item.icon}>
                <Link  style={{ fontFamily:'Gopher',fontSize:'18px' }} to={item.path}>{item.label}</Link> 
                {/* Use Link here */}
              </Menu.Item>
            ))}
          </Menu>
        </div>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 2,
            paddingRight: 35,
            background: colorBgContainer,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {isMobile ? (
            <img
              src={logo}
              alt="image"
              style={{
                maxHeight: "100%",
                width: "auto",
                height: "auto",
                display: "flex",
                paddingLeft: 10,
              }}
            />
          ) : (
            <div></div>
          )}

          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            {/* <BellOutlined style={{ fontSize: "20px", color: "#325FD5" }} /> */}
            <Select
              defaultValue="en"
              placeholder="Language"
              style={{
                margin: 20,
                width: 120,
                backgroundColor: "white",
                backgroundColor: "white",
                border: "1px solid #ccc",
                borderRadius: 20,
              }}
              value={defLang}
              bordered={false}
              onChange={handleChange}
              options={[
                { value: "en", label: "English" },
                { value: "fr", label: "Français" },
              ]}
            />
            <Popover
              placement="bottomRight"
              content={logoutContent}
              trigger="click"
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <h3 style={{ color: "#325FD5",  fontFamily:'Gopher'}}>{fullName}</h3>
                <DownOutlined style={{ color: "#325FD5" }} />
              </div>
            </Popover>
          </div>
        </Header>
        <Content style={{ margin: "24px 16px 0" }}>
          {/* <div style={{ padding: 24, minHeight: 360 }}>{children}</div> */}
          <div style={{ padding: 24, minHeight: screenHeight - headerHeight }}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
