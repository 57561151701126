import React from "react";
import { Breadcrumb, Button, Layout, Menu, theme, Skeleton } from "antd";
import { Link } from "react-router-dom";
import logo from "../Image/logo.png";
import "./style.css";
import FooterComponent from "../Components/FooterComponent";
import HeaderComponent from "../Components/HeaderComponent";
import { useTranslation } from "react-i18next";
import about1 from "../Image/about1.png";
import about2 from "../Image/about2.png";
import './index.css';  // Ensure this CSS file includes the font-face declaration

const { Header, Content, Footer } = Layout;

const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <Layout>
      <HeaderComponent />
      <Content
        style={{
          padding: "2rem",
          // margin: "2rem",
          minHeight: "calc(100vh - 200px)",
          backgroundColor: "#e0f7f9",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            

            <div style={{ maxWidth: "50%" }}>
              <h1
                className="heading"
                style={{
                fontWeight: "bold",
                  color: "#669999",
                  fontFamily:'Gopher'
                }}

              >
                {t("about.Diaspora")}
              </h1>
              <p className="contentParagraph">
                {t("about.main")} <br />
                <br />
                {t("about.content1")}
              </p>
              <img
                src={about1}
                alt="image1"
                className="aboutImage"

               style={{   maxWidth: "570px",
               maxHeight: "350px",
               width: "100%", paddingBottom:30}}

              />
            </div>
        {/* <div style={{ maxWidth: "50%",  justifyContent:'flex-end', alignContent:'flex-end', alignItems:'flex-end', alignSelf:'flex-end'}}> */}
        <div style={{ maxWidth: "50%"}}>

              <h1
                className="heading"
                style={{
                 // marginBottom: "2rem",
                  fontWeight: "bold",
                  color: "#669999",
                  fontFamily:'Gopher'
                }}
              >
                {t("about.Afrique")}
              </h1>
              <p className="contentParagraph">
                {t("about.content2")} <br />
                <br />
                {t("about.content3")}
              </p>
              <img
                src={about2}
                alt="image1"
                className="aboutImage"
                style={{
                  maxWidth: "570px",
                  maxHeight: "350px",
                  width: "100%",
                 
                 //paddingBottom: 50,
                }}
              />
            </div> 
          </div>
        </div>

        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <h1
        className="heading"
        style={{ marginBottom: "2rem", fontWeight: "bold", color: '#669999' }}>
        {t("about.title")}</h1>
          <div className="flexDiv">

            <div className="contentParagraphDiv">
              <p className="contentParagraph">
                {t("about.main")} <br />
                <br />
                {t("about.content1")}
                <br />
                <br />
                {t("about.content2")}
                <br />
                <br />
                {t("about.content3")}
                <br />
                <br />
              </p>
            </div>
            <img
              src={about1}
              alt="image1"
              className="aboutImage"
              style={{ maxWidth: "470px",  alignSelf:'center', maxHeight:"300px", width: "100%", height: "100%", marginLeft: "20px", paddingBottom:50}}
              />
          </div>
        </div>  */}
        {/* <div className="flexDiv">
          <div className="contentParagraphDiv"></div>
        </div>
        <div className="flexDiv">
          <div className="contentParagraphDiv"></div>
        </div> */}
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{ fontWeight: 400 }}>
            Call To Action Call To Action Call To Action
          </h1>
          <Button
            style={{
              borderRadius: "20px",
              width: "auto",
              height: "2rem",
              backgroundColor: "gray",
              color: "white",
            }}
          >
            Button
          </Button>
        </div> */}
      </Content>
      <FooterComponent />
    </Layout>
  );
};
export default About;
