import React, { useState, useEffect } from "react";
import { Button, Input, Row, Col, Form, Checkbox, Typography } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import RemitClickInfo from "../Components/RemitClickInfo";
import { CloseOutlined } from "@ant-design/icons";
import logo from "../Image/logo.png";
import { useTranslation } from "react-i18next";

import { confirmResetPassword, updatePassword } from "aws-amplify/auth";

const ResetPassword = ({ onLoginSuccess }) => {
  const { Text } = Typography;

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const isMobile = viewportWidth < 768;
  const [form] = Form.useForm();
  const location = useLocation();
  const username = location.state.username;
  const otps = location.state.otp;
  const [passwordInput, setPassword] = useState("");
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { t } = useTranslation();

  const handleResetPasswords = (async) => {
    // Get the form values when the "ForgetPassword" button is clicked
    form
      .validateFields()
      .then(async (values) => {
        const { password } = values;
        handleConfirmResetPassword({
          username: username,
          confirmationCode: otps,
          newPassword: password,
        });
        // Your logic for handling the reset password process with the email
      })
      .catch((error) => {
        console.error("Validation error:", error);
        setIsError(true);
        setErrMsg("Please enter a valid email");
      });
  };

  async function handleConfirmResetPassword({
    username,
    confirmationCode,
    newPassword,
  }) {
    try {
      await confirmResetPassword({ username, newPassword, confirmationCode });
      navigate("/signIn");
    } catch (error) {
      console.log(error);
      setIsError(true);
      const message = error.toString().split(":").pop().trim();
      setErrMsg(message);
    }
  }

  useEffect(() => {
    const handleWindowResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      {isMobile && (
        <Row
          style={{
            display: "flex",
            borderBottomStyle: "solid",
            borderBottomColor: "#F2F2F2",
            borderBottomWidth: "0.1px",
          }}
        >
          <Col
            span={20}
            style={{
              display: "flex",
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "1rem",
              }}
            >
              <img
                src={logo}
                alt="RemitClick"
                className="headerLogo"
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
              />
            </div>
          </Col>
          <Col
            span={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link to="/">
              <Button
                type="primary"
                icon={<CloseOutlined />}
                className="crossSign-mobile"
              />
            </Link>
          </Col>
        </Row>
      )}
      <Row style={{ height: "100vh" }}>
        <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 10 }}>
          <RemitClickInfo />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 14 }}>
          <div className="signUpRightSec">
            <div className="signbox">
              {!isMobile && (
                <Link
                  to="/"
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    type="primary"
                    icon={<CloseOutlined />}
                    className="crossSign"
                  />
                </Link>
              )}
              <h2 className="signupTitle"> {t("ChnagePassword.ChnagePassword")}</h2>
              <Form
                form={form}
                className="form"
                onValuesChange={(changedValues, allValues) => {
                  setPassword(allValues.password);
                }}
              >
                <label classNam e="formlabel" style={{ fontSize: "16px",fontFamily:'Gopher' }}>
                {t("ChnagePassword.EnterNewPassword")}
                </label>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    className="forminput"
                    placeholder= {t("ChnagePassword.EnterNewPassword")}
                  />
                </Form.Item>
                <label className="formlabel" style={{ fontSize: "16px" ,fontFamily:'Gopher'}}>
                {t("ChnagePassword.ConfirmPassword")}
                </label>
                <Form.Item
                  name="cpassword"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="forminput"
                    placeholder={t("ChnagePassword.ConfirmPassword")}
                  />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="signupbtn"
                  onClick={handleResetPasswords}
                >
                  {t("ChnagePassword.ChnagePassword")}{" "}
                </Button>
              </Form>
            </div>
          </div>
        </Col>
        {/* <Footerpage /> */}
      </Row>
    </>
  );
};
export default ResetPassword;
