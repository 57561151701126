import React, { useEffect, useState } from "react";
import { Divider, Card, List, Avatar, Button, Skeleton } from "antd";
import { Content } from "antd/es/layout/layout";
import canadaFlag from "../Image/ca-circle.png";
import logo from "../Image/logo.png";
import axios from "axios";
import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchUserAttributes } from "@aws-amplify/auth";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { SendOutlined, WalletOutlined } from "@ant-design/icons";
import {
  getTableID,
  getUserVerificationStatus,
} from "../Services/authServices";
import { generateClient } from "aws-amplify/api";
import { useTranslation } from "react-i18next";
import { colors } from "../styles/colors";

import { CookiesProvider, useCookies } from "react-cookie";
const Dashboard = () => {
  const API = generateClient();
  const navigate = useNavigate();
  const [wallet, setWallet] = useState({ availableBalance: 0 });
  const [loading, setLoading] = useState(true);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const { t, i18n } = useTranslation();

  const [verificationStatus, setVerificationStatus] = useState({
    identificationVerified: false,
    isOrganization: false,
    verificationOption: null,
  });

  useEffect(() => {
    const fetchVerificationStatus = async () => {
      const tableID = await getTableID();

      const status = await getUserVerificationStatus(tableID);

      console.log("verification status: ", status);
      if (status) {
        setVerificationStatus(status);
        if (status.identificationVerified) {
          console.log("User is already verified");
        } else {
          console.log("User is already verified");
        }
      }
    };

    fetchVerificationStatus();
  }, []);

  const handleSubmit = async () => {
    if (verificationStatus.isOrganization) {
      navigate("/kybuser");
    } else {
      navigate("/kycuser");
    }
  };

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const MyQuery = `
          query GetWalletByUser($id: ID!) {
            getUser(id: $id) {
                Wallets {
                    items {
                      availableBalance
                      id
                      userID
                    }
                }
            }
          }
        `;
        const tableID = await getTableID();

        if (!tableID) {
          console.error("Table ID is not available.");
          return;
        }
        const userData = await API.graphql({
          query: MyQuery,
          variables: { id: tableID },
        });
        const walletDetails = userData.data.getUser.Wallets.items[0] || {
          availableBalance: 0,
        };
        setWallet(walletDetails);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching wallet:", err);
      }
    };
    const fetchTransactionHistory = async () => {
      try {
        const tableID = await getTableID(); // Ensure you have this function implemented

        if (!tableID) {
          console.error("Table ID is not available.");
          return;
        }

        const MyQuery = `
            query GetTransactionsHistory($id: ID!) {
              getUser(id: $id) {
                Transactions(sortDirection: ASC) {
                  items {
                    ReceivedAmountCAD
                    RecievedAmountMethod
                    SendingAmountCFA
                    recipientFirstName
                    recipientLastName
                    transactionStatus
                    transactionDate
                    trackingID
                    deliveryMethod
                    transactionDate
                    completionDate
                  }
                }
              }
            }
          `;

        const userData = await API.graphql({
          query: MyQuery,
          variables: { id: tableID },
        });

        const transactionItems = userData.data.getUser.Transactions.items;
        console.log("transaction", transactionItems);
        setTransactionHistory(transactionItems);
      } catch (err) {
        console.error("Error fetching transaction history:", err);
      }
    };

    fetchWallet();
    fetchTransactionHistory();
  }, []);
  const fundWallet = () => {
    navigate("/fundWallet", { state: { wallet } });
  };
  function formatNumber(number, decimalPlaces = 3) {
    return number.toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  }
  //remove it to somewhere else
  async function createWallet(userId) {
    try {
      const input = {
        userID: userId,
        availableBalance: 0.0,
        onHoldBalance: 0.0,
      };
      const walletResponse = await API.graphql({
        query: mutations.createWallet,
        variables: { input },
      });

      console.log("Wallet created: ", walletResponse.data);
      // Handle any additional logic here, such as updating state or UI
    } catch (error) {
      console.error("Error creating wallet: ", error);
      // Handle errors, such as showing a message to the user
    }
  }
  // createWallet("08b7ed82-e87b-49ce-b0b4-25ca7cf97b23");
  return (
    <Content>
      <div>
        <Divider orientation="left">
          <span style={{ fontSize: "22px", fontFamily:'Gopher' }}>
           
            {t("dashboard.YourPersonalBalance")}
          </span>
        </Divider>
        <div className="card-container">
          {loading ? (
            <Skeleton active />
          ) : (
            <Card className="customCard">
              <div
                style={{
                  alignSelf: "flex-start",
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                }}
              >
                <img
                  src={canadaFlag}
                  alt="Canadian Flag"
                  style={{
                    width: "2.7rem", // Increased width to fill the circle
                    height: "2.5rem", // Increased height to fill the circle
                    borderRadius: "100%",
                    objectFit: "cover",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </div>
              <h1 style={{ marginTop: "-1rem" , fontFamily:'Gopher'}}>
                {t("dashboard.CurrentBalance")}
              </h1>
              <h1 style={{ margin: "0", fontFamily:'Gopher' }}>
                ${formatNumber(wallet.availableBalance || 0, 2)}
              </h1>
              <div
                style={{
                  alignSelf: "flex-start",
                  width: "100%",
                }}
              >
                {wallet.id ? (
                  <p
                    style={{
                      marginTop: "3rem",
                      fontSize: "0.75rem", // Smaller font size
                      fontWeight: "400", // Adjust font weight if needed
                      whiteSpace: "nowrap", // Prevents the text from wrapping
                      overflow: "hidden", // Hides overflowed text
                      textOverflow: "ellipsis", // Adds ellipsis to overflowed text
                      maxWidth: "90%", // Ensure the max width to prevent overflow, adjust as needed
                      fontFamily:'Gopher'
                    }}
                  >
                    {wallet.id}
                  </p>
                ) : (
                  <p
                    style={{
                      marginTop: "3rem",
                      fontSize: "1rem", // Adjusted font size
                      fontWeight: "bold", // Bold font weight
                      color: "yellow", // Red color for attention
                      textAlign: "center", // Centered text
                      fontFamily:'Gopher'
                    }}
                    onClick={handleSubmit}
                  >
                    {t("dashboard.completeKyc")}
                  </p>
                )}
              </div>
            </Card>
          )}
          {/* <div className="action-cards">
            <Card
              onClick={fundWallet}
              style={{
                width: "10rem",
                height: "10rem",
                display: "flex",
                flexDirection: "column",
                borderWidth: ".2rem",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "1.5rem",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "background-color 0.3s, transform 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#FFD200";
                e.currentTarget.querySelector(".icon").style.color = "#FFFFFF";
                e.currentTarget.querySelector(".text").style.color = "#FFFFFF"; // Change text color on hover
                e.currentTarget.querySelector(".icon").style.fontSize = "4rem"; // Increase icon size on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#FFFFFF";
                e.currentTarget.querySelector(".icon").style.color = "#000000";
                e.currentTarget.querySelector(".text").style.color = "#000000"; // Reset text color on hover out
                e.currentTarget.querySelector(".icon").style.fontSize = "3rem"; // Reset icon size on hover out
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <WalletOutlined
                  className="icon"
                  style={{ fontSize: "3rem", transition: "font-size 0.3s" }}
                />
                <span
                  className="text"
                  style={{ marginTop: "0.5rem", transition: "color 0.3s" }}
                >
                  Add Fund
                </span>
              </div>
            </Card>

            <Card
              onClick={() => navigate("/selectRecipient")}
              style={{
                width: "10rem",
                height: "10rem",
                display: "flex",
                borderWidth: ".2rem",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "1.5rem",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
                transition: "background-color 0.3s, transform 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#FFD200";
                e.currentTarget.querySelector(".icon").style.color = "#FFFFFF";
                e.currentTarget.querySelector(".text").style.color = "#FFFFFF"; // Change text color on hover
                e.currentTarget.querySelector(".icon").style.fontSize = "4rem"; // Increase icon size on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#FFFFFF";
                e.currentTarget.querySelector(".icon").style.color = "#000000";
                e.currentTarget.querySelector(".text").style.color = "#000000"; // Reset text color on hover out
                e.currentTarget.querySelector(".icon").style.fontSize = "3rem"; // Reset icon size on hover out
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SendOutlined
                  className="icon"
                  style={{ fontSize: "3rem", transition: "font-size 0.3s" }}
                />
                <span
                  className="text"
                  style={{ marginTop: "0.5rem", transition: "color 0.3s" }}
                >
                  Send Money
                </span>
              </div>
            </Card>
          </div> */}
        </div>
      </div>

      <div style={{ display: "block", flexDirection: "column" }}>
        <Divider orientation="left" style={{ marginTop: "2rem" }}>
          <span style={{ fontSize: "18px", fontFamily:'Gopher' }}>
            {t("dashboard.YourTransactions")}
          </span>
        </Divider>

        {/* <Button
          style={{
            width: "10rem",
            float: "right",
            color: "#3D49AB",
            fontWeight: "bold",
            fontSize: "18px",
          }}
          type="text"
        >
          <span style={{ fontSize: "18px" }}>{t("dashboard.ViewAll")}</span>
        </Button> */}
        <List
          itemLayout="horizontal"
          dataSource={transactionHistory}
          pagination={{
            style: { paddingBottom: "1rem" },
            onChange: (page) => {
              console.log(page);
              // Here you can fetch new data based on the page number if required
            },
            pageSize: 5, // Set the number of items per page
          }}
          style={{
            backgroundColor: "white",
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            borderRadius: "1rem",
          }}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={`https://api.dicebear.com/7.x/initials/svg?seed=${item.recipientFirstName} ${item.recipientLastName}`}
                  />
                }
                title={
                  <a href="https://ant.design">
                    {item.recipientFirstName} {item.recipientLastName}
                  </a>
                }
                description={new Date(item.transactionDate).toLocaleString()}
              />
              <div
                style={{
                  marginLeft: "auto",
                  textAlign: "right",
                  minWidth: "100px",
                }}
              >
                {/* remove 2 in below line to get three decimal points */}
                <p>{formatNumber(Number(item.ReceivedAmountCAD), 2)} CAD</p>
                <p>{formatNumber(Number(item.SendingAmountCFA), 2)} XOF</p>
              </div>
            </List.Item>
          )}
        />
      </div>
    </Content>
  );
};
export default Dashboard;
