import React, { useState, useEffect } from "react";
import { Button, Input, Row, Col, Form, Checkbox, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import RemitClickInfo from "../Components/RemitClickInfo";
import { CloseOutlined } from "@ant-design/icons";
import logo from "../Image/logo.png";
import { resetPassword } from "aws-amplify/auth";
import { useTranslation } from "react-i18next";

const ForgetPassword = ({ onLoginSuccess }) => {
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const isMobile = viewportWidth < 768;
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isOtpStage, setIsOtpStage] = useState(false);
  const [ispasswordStage, setIsPassword] = useState(false);
  const [otpError, setOtpError] = useState("");
  const { t } = useTranslation();

  const navigate = useNavigate();

  function handleOtpSubmit() {
    setIsPassword(true);
    setIsOtpStage(false);
    navigate("/resetPassword", { state: { username: email, otp: otp } });
  }
  useEffect(() => {
    const handleWindowResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleResetPasswords = () => {
    // Get the form values when the "ForgetPassword" button is clicked
    form
      .validateFields()
      .then((values) => {
        const { email } = values;
        console.log("Email value:", email);
        setEmail(email);
        handleResetPassword(email);
        // Your logic for handling the reset password process with the email
      })
      .catch((error) => {
        console.error("Validation error:", error);
        setIsError(true);
        setErrMsg("Please enter a valid email");
      });
  };

  const handleResetPassword = async (username) => {
    try {
      const output = await resetPassword({ username });
      handleResetPasswordNextSteps(output, username);
    } catch (error) {
      setIsError(true);
      const message = error.toString().split(":").pop().trim();
      console.log(message);
      if (message === "Username/client id combination not found.") {
        const messages = "User Not Found";
        setErrMsg(messages);
      } else if (
        message ===
        "Exceeded daily email limit for the operation or the account. If a higher limit is required, please configure your user pool to use your own Amazon SES configuration for sending email."
      ) {
        const messages =
          "Your daily limit exceed,Please try again after some time";
        setIsError(true);
        setErrMsg(messages);
      } else {
        console.log(message);
        setErrMsg(message);
      }
    }
  };

  function handleResetPasswordNextSteps(output, username) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(
          `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
        );
        setIsOtpStage(true);
        break;
      case "DONE":
        console.log("Successfully reset password.");
        break;
    }
  }

  return (
    <>
      {isMobile && (
        <Row
          style={{
            display: "flex",
            borderBottomStyle: "solid",
            borderBottomColor: "#F2F2F2",
            borderBottomWidth: "0.1px",
          }}
        >
          <Col
            span={20}
            style={{
              display: "flex",
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "1rem",
              }}
            >
              <img
                src={logo}
                alt="RemitClick"
                className="headerLogo"
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
              />
            </div>
          </Col>
          <Col
            span={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link to="/">
              <Button
                type="primary"
                icon={<CloseOutlined />}
                className="crossSign-mobile"
              />
            </Link>
          </Col>
        </Row>
      )}
      <Row style={{ height: "100vh" }}>
        <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 10 }}>
          <RemitClickInfo />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 14 }}>
          <div className="signUpRightSec">
            {!isOtpStage ? (
              <div className="signbox">
                {!isMobile && (
                  <Link
                    to="/"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="primary"
                      icon={<CloseOutlined />}
                      className="crossSign"
                    />
                  </Link>
                )}

                <h2 className="signupTitle">  {t("forgotPassword.forgotPassword")}</h2>
                <div className="form">
                  <Form
                    form={form}
                    onValuesChange={(changedValues, allValues) => {
                      // Handle changes in the form values
                      setEmail(allValues.email);
                    }}
                  >
                    <label className="formlabel" style={{ fontSize: "16px",fontFamily: 'Gopher' }}>
                    {t("forgotPassword.email")}
                    </label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Email",
                        },
                      ]}
                    >
                      <Input className="forminput" placeholder={t("forgotPassword.email")} />
                    </Form.Item>
                    {isError && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <Text style={{ color: "red" }}>{errMsg}</Text>
                      </div>
                    )}
                    <Button
                      className="signupbtn"
                      onClick={handleResetPasswords}>
                        {t("forgotPassword.forgotPasswords")}
                    </Button>
                  </Form>
                </div>
              </div>
            ) : (
              <div className="signbox">
                {!isMobile && (
                  <Link
                    to="/"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      type="primary"
                      icon={<CloseOutlined />}
                      className="crossSign"
                    />
                  </Link>
                )}
                <h2 className="signupTitle">      {t("OTP.VerifyOTP")}</h2>
                <Form
                  className="form"
                  onValuesChange={(changedValues, allValues) => {
                    // Handle changes in the form values
                    setOTP(allValues.otp);
                    console.log("Changed otp", otp);
                  }}
                >
                  <label className="formlabel" style={{ fontSize: "16px" }}>
                  {t("OTP.EnterOTP")}
                  </label>
                  <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the OTP sent to you",
                      },
                    ]}
                    help={otpError} // Display the OTP error message
                    validateStatus={otpError ? "error" : ""} // Show validation status based on the error state
                  >
                    <Input
                      className="forminput"
                      placeholder="6-digit OTP"
                      maxLength={6}
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="signupbtn"
                    onClick={handleOtpSubmit}>
                       {t("OTP.VerifyOTP")}
                  </Button>
                </Form>
              </div>
            )}
          </div>
        </Col>
        {/* <Footerpage /> */}
      </Row>
    </>
  );
};
export default ForgetPassword;
